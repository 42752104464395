import { Button, Icon, Table } from '@loomispay/vault';
import { Controller, useForm } from 'react-hook-form';
import {
  DefaultTransactionFeeRule,
  GetDefaultTransactionFeeRule,
  GetTransactionFeeRules,
  TransactionFeeRule,
} from 'routes/fees/DataTable';
import { useMerchant, useMerchantReportingDetails } from '../merchantReporting-hooks';
import {
  buildValidationSchema,
  CardNotPresentTransactionFeesAssignedRequestBody,
  ErrorLabel,
  findMatchingMerchantRule,
  Form,
  getMerchantFeesTableColumns,
  getRuleFixedFeeAmount,
  getRulePercentageValue,
  SaveButtonContainer,
  Tooltip,
} from './helper';

import { zodResolver } from '@hookform/resolvers/zod';
import { TableData } from '@loomispay/vault/build/components/Tables/Table/types';
import { ElasticMerchant } from 'api/types';
import { useTranslation } from 'react-i18next';
import { CountryCode } from 'routes/fees/AddNewRule';
import { fetchFromBackend } from 'utils/fetch';
import { createToast } from 'vercel-toast';
import { useMerchantIdFromUrl } from '../merchant-hooks';

/* eslint-disable @typescript-eslint/no-explicit-any */

const MerchantFeesTab = () => {
  const merchantId = useMerchantIdFromUrl();

  const { data } = useMerchantReportingDetails(merchantId);

  const countryCode = data?.address?.country as CountryCode;

  const countryRulesRanges = GetTransactionFeeRules(countryCode, false);
  const countryDefaultRange = GetDefaultTransactionFeeRule(countryCode, false);

  const merchant = useMerchant(merchantId).data;

  return (
    <div>
      {countryRulesRanges.transactionFeeRules && countryDefaultRange.defaultTransactionFeeRule && merchant && (
        <MerchantFeesTable
          countryCode={countryCode}
          merchantId={merchantId}
          countryRulesRanges={countryRulesRanges.transactionFeeRules}
          countryDefaultRange={countryDefaultRange.defaultTransactionFeeRule}
          merchant={merchant}
        />
      )}
    </div>
  );
};

const MerchantFeesTable = (props: {
  countryCode: CountryCode;
  merchantId: string;
  countryRulesRanges: TransactionFeeRule[];
  countryDefaultRange: DefaultTransactionFeeRule;
  merchant: ElasticMerchant;
}) => {
  const { t } = useTranslation();

  const columns = getMerchantFeesTableColumns(props.countryDefaultRange.currency, t);

  const merchant = useMerchant(props.merchantId);
  const merchantAssignedDefaultRule = merchant.data?.contract?.fees?.defaults?.cardNotPresent;

  const merchantAssignedRules = merchant.data?.contract?.fees?.rules?.cardNotPresent ?? [];

  const FormInputSchema = buildValidationSchema(props.countryDefaultRange, props.countryRulesRanges, t);

  const getDefaultValues = () => {
    const defaultValues: any = {
      defaultRule: {
        percentageFee:
          merchantAssignedDefaultRule?.feePercentage.toString() ??
          props.countryDefaultRange.percentage.default.toString(),
        fixedFeeAmount:
          merchantAssignedDefaultRule?.fixedFeeAmount.toString() ??
          props.countryDefaultRange.fixedFeeAmount?.default?.toString(),
      },
    };
    props.countryRulesRanges.forEach(rule => {
      defaultValues[rule.id] = {
        [`percentageFee`]: getRulePercentageValue(rule, merchantAssignedRules).toString(),
        [`fixedFeeAmount`]: getRuleFixedFeeAmount(rule, merchantAssignedRules)?.toString(),
      };
    });
    return defaultValues;
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    defaultValues: getDefaultValues(),
    resolver: zodResolver(FormInputSchema),
  });

  const createDataTable = (feeRules: TransactionFeeRule[], defaultFeeRule?: DefaultTransactionFeeRule): TableData[] => {
    const tableData = [];
    feeRules.forEach(it => {
      tableData.push({
        ...it,
        missing: findMatchingMerchantRule(it, merchantAssignedRules) ? null : <Icon name="warningCircled" size="m" />,
        percentageFee: (
          <Controller
            render={({ field }) => {
              return (
                <Tooltip
                  data-hover={t('merchant.fees.tooltip', {
                    min: it.percentage.min,
                    max: it.percentage.max,
                    default: it.percentage.default,
                  })}
                >
                  <input {...field} type="number" />
                  {errors[it.id]?.percentageFee?.message && (
                    <ErrorLabel>{errors[it.id]?.percentageFee?.message}</ErrorLabel>
                  )}
                </Tooltip>
              );
            }}
            name={`${it.id}.percentageFee`}
            control={control}
          />
        ),
        fixedFee: (
          <Controller
            render={({ field }) => (
              <Tooltip
                data-hover={t('merchant.fees.tooltip', {
                  min: it.fixedFeeAmount?.min,
                  max: it.fixedFeeAmount?.max,
                  default: it.fixedFeeAmount?.default,
                })}
              >
                <input {...field} type="number" />
                {errors[it.id]?.fixedFeeAmount?.message && (
                  <ErrorLabel>{errors[it.id]?.fixedFeeAmount?.message}</ErrorLabel>
                )}
              </Tooltip>
            )}
            name={`${it.id}.fixedFeeAmount`}
            control={control}
          />
        ),
      });
    });

    if (defaultFeeRule) {
      tableData.push({
        ...defaultFeeRule,
        missing: merchantAssignedDefaultRule ? null : <Icon name="warningCircled" size="m" />,
        percentageFee: (
          <Controller
            render={({ field }) => (
              <Tooltip
                data-hover={t('merchant.fees.tooltip', {
                  min: defaultFeeRule.percentage.min,
                  max: defaultFeeRule.percentage.max,
                  default: defaultFeeRule.percentage.default,
                })}
              >
                <input {...field} type="number" />
                {errors['defaultRule']?.percentageFee?.message && (
                  <ErrorLabel>{errors['defaultRule']?.percentageFee?.message}</ErrorLabel>
                )}
              </Tooltip>
            )}
            name="defaultRule.percentageFee"
            control={control}
          />
        ),
        fixedFee: (
          <Controller
            render={({ field }) => (
              <Tooltip
                data-hover={t('merchant.fees.tooltip', {
                  min: defaultFeeRule.fixedFeeAmount?.min,
                  max: defaultFeeRule.fixedFeeAmount?.max,
                  default: defaultFeeRule.fixedFeeAmount?.default,
                })}
              >
                <input {...field} type="number" />
                {errors['defaultRule']?.fixedFeeAmount?.message && (
                  <ErrorLabel>{errors['defaultRule']?.fixedFeeAmount?.message}</ErrorLabel>
                )}
              </Tooltip>
            )}
            name="defaultRule.fixedFeeAmount"
            control={control}
          />
        ),
        cardScheme: t('fees.table.default-fee'),
      });
    }
    return tableData;
  };

  const convertFormRuleToCardNotPresentTransactionFeesAndCriteria = (formRule: any) => {
    const countryRule = props.countryRulesRanges.find(rule => rule.id === formRule[0]);
    return {
      fees: {
        percentage: formRule[1].percentageFee,
        fixedFeeAmount: formRule[1].fixedFeeAmount,
        currency: countryRule?.currency,
      },
      criteria: {
        region: countryRule?.region,
        cardCustomerType: countryRule?.cardCustomerType,
        cardScheme: countryRule?.cardScheme,
        cardType: countryRule?.cardType,
      },
    };
  };

  const onSubmit = async (data: typeof FormInputSchema) => {
    const rules: any = Object.entries(data)
      .filter(entry => entry[0] !== 'defaultRule')
      .map(rule => convertFormRuleToCardNotPresentTransactionFeesAndCriteria(rule));
    const reqBody: CardNotPresentTransactionFeesAssignedRequestBody = {
      defaultTransactionFee: {
        currency: props.countryDefaultRange.currency,
        percentage: data.defaultRule.percentageFee,
        fixedFeeAmount: data.defaultRule.fixedFeeAmount,
      },
      criteriaBasedTransactionFeeRules: rules,
    };
    const response = await fetchFromBackend(`/merchants/${props.merchantId}/card-not-present-transaction-fees`, {
      method: 'POST',
      body: JSON.stringify(reqBody),
    });
    if (response.ok) {
      createToast(t('merchant.fees.edit.success'), {
        timeout: 6000,
        type: 'success',
      });
      merchant.mutate();
    } else if (response.status >= 500) {
      createToast(t('merchant.fees.edit.unexpected-error'), {
        timeout: 6000,
        type: 'error',
      });
    } else if (response.status >= 400) {
      createToast(t('merchant.fees.edit.updating-error'), {
        timeout: 6000,
        type: 'error',
      });
    }
  };

  const data: TableData[] = createDataTable(props.countryRulesRanges, props.countryDefaultRange);
  return (
    <Form onSubmit={handleSubmit(formData => onSubmit(formData))}>
      {<Table columns={columns} data={data} />}
      <SaveButtonContainer>
        <Button type="submit">{t('merchant.fees.save')}</Button>
      </SaveButtonContainer>
    </Form>
  );
};

export default MerchantFeesTab;
