import { Button, Icon, Text } from '@loomispay/vault';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ClipLoader from 'react-spinners/ClipLoader';
import { links } from '../../config';
import { Permission, useUserPermissions } from '../../permissions';
import { AmexFile, FileStatus } from './FileProcessing.service';

export const TriggerSettlementButton = (props: { file: AmexFile; refresh: () => void }) => {
  const { t } = useTranslation();
  const { permissions } = useUserPermissions();
  const [clicked, setClicked] = useState<boolean>(false);

  const triggerFile = async () => {
    const response = await fetch(links.backendUrl + '/file-processing/amex/' + props.file.id, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.ok;
  };

  const isAmountNegative = () => {
    return Number(props.file.paymentNetAmount) < 0;
  };

  const fileIsFailedOrInitiallyParsed = () => {
    return [FileStatus.INITIALLY_PARSED, FileStatus.FAILED].includes(props.file.status);
  };

  const processingIsNotYetTriggered = () => {
    return !props.file.triggered;
  };

  return (
    <div>
      {!props.file.isEmpty &&
        fileIsFailedOrInitiallyParsed() &&
        processingIsNotYetTriggered() &&
        isAmountNegative() &&
        !clicked &&
        permissions.includes(Permission.TRIGGER_AMEX_SETTLEMENT) && (
          <Button
            label={t('file-processing.trigger')}
            onClick={() => {
              setClicked(true);
              triggerFile().then(result => {
                if (result) {
                  props.refresh();
                  setClicked(false);
                }
              });
            }}
          />
        )}
      {clicked && <ClipLoader size={35} />}
      {props.file.triggered && props.file.status !== FileStatus.SUCCEEDED && (
        <Text color={'positive'}>
          <Icon color={'positive'} size={'m'} name={'checkmark'} />
          {t('file-processing.file-is-processing')}
        </Text>
      )}
    </div>
  );
};
