import styled from 'styled-components';

export const FlexDiv = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

export const FlexCol = styled.div`
  width: 50%;
  max-width: 350px;
  padding-right: 1.8%;
  display: flex;
  flex-direction: column;
  padding-bottom: 1%;
`;

export const LabelWrapper = styled.div`
  margin-bottom: 8px;
`;
