import { Grid, GridItem, Heading, Text } from '@loomispay/vault';
import { Badge } from 'components/Badge';
import { Currency } from 'components/invoices/InvoicesTable';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import useSWR from 'swr';
import { fetchByUrl } from '../../api';
import { MerchantStatusScreen } from './MerchantStatusScreen';

enum PayoutType {
  FINARO_SETTLEMENT_FILE_RECEIVED = 'FINARO_SETTLEMENT_FILE_RECEIVED',
  PAYOUTS_TOTAL = 'PAYOUTS_TOTAL',
  PAYOUTS_CREATED = 'PAYOUTS_CREATED',
  PAYOUTS_PENDING = 'PAYOUTS_PENDING',
  PAYOUTS_REJECTED = 'PAYOUTS_REJECTED',
  PAYOUTS_SETTLED = 'PAYOUTS_SETTLED',
}

enum PayoutStatus {
  NOT_STARTED = 'NOT_STARTED',
  WARNING = 'WARNING',
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
}

interface PayoutOverview {
  finaroSettlementFileReceived: PayoutOverviewEntry;
  amexSettlementFileReceived: PayoutOverviewEntry;
  today: PayoutOverviewReport;
  previousDays: PayoutOverviewReport;
  lastUpdateAt: string;
}

interface PayoutOverviewReport {
  total: PayoutOverviewEntryForCurrencies;
  created: PayoutOverviewEntryForCurrencies;
  pending: PayoutOverviewEntryForCurrencies;
  rejected: PayoutOverviewEntryForCurrencies;
  settled: PayoutOverviewEntryForCurrencies;
}

interface PayoutOverviewEntryForCurrencies {
  [Currency.SEK]: PayoutOverviewEntry;
  [Currency.DKK]: PayoutOverviewEntry;
  [Currency.EUR]: PayoutOverviewEntry;
  [Currency.NOK]: PayoutOverviewEntry;
}

interface PayoutOverviewEntry {
  count: number;
}

interface PayoutOverviewResponse {
  payoutOverview?: PayoutOverview;
  isError: boolean;
  refresh: () => void;
}

const usePayoutOverview = (): PayoutOverviewResponse => {
  const { error, data, mutate } = useSWR<PayoutOverview>(['/payout-overview/report'], url => fetchByUrl(url), {
    refreshInterval: 10000,
  });

  return {
    payoutOverview: data,
    isError: error,
    refresh: mutate,
  };
};

export const PayoutOverviewScreen = () => {
  const { t } = useTranslation();

  const { payoutOverview } = usePayoutOverview();

  return (
    <div>
      <Heading size="m">{t('payout-overview.title')}</Heading>
      <Container>
        <Grid noGutter>
          {payoutOverview && (
            <>
              <GridItem l={6} m={9} s={12}>
                {t('payout-overview.today')}
                <br />
                {t('payout-overview.last-update') +
                  ': ' +
                  (payoutOverview.lastUpdateAt !== null
                    ? DateTime.fromISO(payoutOverview.lastUpdateAt).toFormat('yyyy-MM-dd, HH:mm')
                    : '')}
                <StyledPayoutOverviewRow>
                  <Grid noGutter>
                    <GridItem l={6} m={4} s={3} alignItems="center" />
                    <GridItem l={1} m={1} s={1} alignItems="center">
                      Total
                    </GridItem>
                    {Object.values(Currency).map(currency => {
                      return (
                        <GridItem l={1} m={1} s={1} alignItems="center">
                          {currency}
                        </GridItem>
                      );
                    })}
                  </Grid>
                </StyledPayoutOverviewRow>
                <PayoutOverviewRow
                  payoutType={PayoutType.PAYOUTS_TOTAL}
                  payoutOverviewEntryForCurrencies={payoutOverview.today.total}
                />
                <PayoutOverviewRow
                  payoutType={PayoutType.PAYOUTS_CREATED}
                  payoutOverviewEntryForCurrencies={payoutOverview.today.created}
                />
                <PayoutOverviewRow
                  payoutType={PayoutType.PAYOUTS_PENDING}
                  payoutOverviewEntryForCurrencies={payoutOverview.today.pending}
                />
                <PayoutOverviewRow
                  payoutType={PayoutType.PAYOUTS_REJECTED}
                  payoutOverviewEntryForCurrencies={payoutOverview.today.rejected}
                />
                <PayoutOverviewRow
                  payoutType={PayoutType.PAYOUTS_SETTLED}
                  payoutOverviewEntryForCurrencies={payoutOverview.today.settled}
                />
                <StyledPayoutOverviewRow>
                  <Grid noGutter>
                    <GridItem l={6} m={4} s={3} alignItems="center">
                      <Text>{t('payout-overview.type.settlement-file-received')}</Text>
                    </GridItem>
                    <GridItem l={1} m={1} s={1} alignItems="center">
                      <Badge background={'tertiaryBg'}>{payoutOverview.finaroSettlementFileReceived.count}</Badge>
                    </GridItem>
                  </Grid>
                </StyledPayoutOverviewRow>
                <StyledPayoutOverviewRow>
                  <Grid noGutter>
                    <GridItem l={6} m={4} s={3} alignItems="center">
                      <Text>{t('payout-overview.type.amex-settlement-files-received')}</Text>
                    </GridItem>
                    <GridItem l={1} m={1} s={1} alignItems="center">
                      <Badge background={'tertiaryBg'}>{payoutOverview.amexSettlementFileReceived.count}</Badge>
                    </GridItem>
                  </Grid>
                </StyledPayoutOverviewRow>
              </GridItem>
              <GridItem l={6} m={9} s={12}>
                {t('payout-overview.previous-days')}
                <br />
                <br />
                <StyledPayoutOverviewRow>
                  <Grid noGutter>
                    <GridItem l={6} m={4} s={3} alignItems="center" />
                    <GridItem l={1} m={1} s={1} alignItems="center">
                      Total
                    </GridItem>
                    {Object.values(Currency).map(currency => {
                      return (
                        <GridItem l={1} m={1} s={1} alignItems="center">
                          {currency}
                        </GridItem>
                      );
                    })}
                  </Grid>
                </StyledPayoutOverviewRow>
                <PayoutOverviewRow
                  payoutType={PayoutType.PAYOUTS_TOTAL}
                  payoutOverviewEntryForCurrencies={payoutOverview.previousDays.total}
                />
                <PayoutOverviewRow
                  payoutType={PayoutType.PAYOUTS_CREATED}
                  payoutOverviewEntryForCurrencies={payoutOverview.previousDays.created}
                />
                <PayoutOverviewRow
                  payoutType={PayoutType.PAYOUTS_PENDING}
                  payoutOverviewEntryForCurrencies={payoutOverview.previousDays.pending}
                />
                <PayoutOverviewRow
                  payoutType={PayoutType.PAYOUTS_REJECTED}
                  payoutOverviewEntryForCurrencies={payoutOverview.previousDays.rejected}
                />
                <PayoutOverviewRow
                  payoutType={PayoutType.PAYOUTS_SETTLED}
                  payoutOverviewEntryForCurrencies={payoutOverview.previousDays.settled}
                />
              </GridItem>
            </>
          )}
        </Grid>
        <MerchantStatusScreen />
      </Container>
    </div>
  );
};

export const PayoutOverviewRow = (props: {
  payoutType: PayoutType;
  payoutOverviewEntryForCurrencies: PayoutOverviewEntryForCurrencies;
}) => {
  const { t } = useTranslation();

  const formatPayoutOverviewType = (type: PayoutType) => {
    switch (type) {
      case PayoutType.FINARO_SETTLEMENT_FILE_RECEIVED:
        return t('payout-overview.type.settlement-file-received');
      case PayoutType.PAYOUTS_TOTAL:
        return t('payout-overview.type.payouts-total');
      case PayoutType.PAYOUTS_CREATED:
        return t('payout-overview.type.payouts-created');
      case PayoutType.PAYOUTS_PENDING:
        return t('payout-overview.type.payouts-pending');
      case PayoutType.PAYOUTS_REJECTED:
        return t('payout-overview.type.payouts-rejected');
      case PayoutType.PAYOUTS_SETTLED:
        return t('payout-overview.type.payouts-settled');
    }
  };

  const backgroundFromStatus = (status: PayoutStatus) => {
    switch (status) {
      case PayoutStatus.NOT_STARTED:
        return 'tertiaryBg';
      case PayoutStatus.WARNING:
        return 'warningBg';
      case PayoutStatus.POSITIVE:
        return 'positiveBg';
      case PayoutStatus.NEGATIVE:
        return 'negativeBg';
    }
  };

  return (
    <StyledPayoutOverviewRow>
      <Grid noGutter>
        <GridItem l={6} m={4} s={3} alignItems="center">
          <Text>{formatPayoutOverviewType(props.payoutType)}</Text>
        </GridItem>
        <GridItem l={1} m={1} s={1} alignItems="center">
          <Badge background={backgroundFromStatus(PayoutStatus.NOT_STARTED)}>
            {Object.values(Currency)
              .map(currency => {
                return props.payoutOverviewEntryForCurrencies[currency].count || 0;
              })
              .reduce((sum, current) => sum + current, 0)}
          </Badge>
        </GridItem>
        {Object.values(Currency).map(currency => {
          const count = props.payoutOverviewEntryForCurrencies[currency].count || 0;
          return (
            <GridItem l={1} m={1} s={1} alignItems="center">
              <Badge background={backgroundFromStatus(PayoutStatus.NOT_STARTED)}>{count}</Badge>
            </GridItem>
          );
        })}
      </Grid>
    </StyledPayoutOverviewRow>
  );
};

const Container = styled.div`
  max-width: 1280px;
`;

const StyledPayoutOverviewRow = styled.div`
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
`;
