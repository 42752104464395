import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Permission } from 'permissions';
import { Button } from '@loomispay/vault';
import { ButtonVariants } from '@loomispay/vault/build/components/Button/types';
import { Modal } from 'components/Modal';
import { EnabledFeature, useConfiguration } from '../../../../configuration';
import { DeclineRequestModalContent } from './DeclineRequestModalContent';

interface Props {
  transactionId: string;
}

export const DeclineRequestButton: React.FunctionComponent<Props> = ({ transactionId }: Props) => {
  const { t } = useTranslation();
  const { data } = useConfiguration();

  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const [declineRequested, setDeclineRequested] = useState<boolean>(false);

  const [buttonLabel, setButtonLabel] = useState<string>(t('merchant.transactions.sendRequestToDecline'));
  const [buttonVariant, setButtonVariant] = useState<ButtonVariants>('destructive');

  const featureFlagEnabled = data?.enabledFeatures.includes(EnabledFeature.DECLINE_REFUNDS);
  const hasPermissions = data?.permissions.includes(Permission.DECLINE_REFUNDS);

  const newLabel = t('merchant.transactions.declineRequestAlreadySent');
  useEffect(() => {
    // we want to update the button after transaction is declined
    if (declineRequested) {
      setButtonLabel(newLabel);
      setButtonVariant('positive');
    }
  }, [newLabel, declineRequested]);

  if (featureFlagEnabled) {
    return (
      <>
        <Button
          label={buttonLabel}
          variant={buttonVariant}
          onClick={() => {
            setModalOpened(true);
          }}
          disabled={modalOpened || declineRequested || !hasPermissions}
        />
        <Modal
          closeButton
          isOpen={modalOpened}
          close={() => {
            setModalOpened(false);
          }}
        >
          <DeclineRequestModalContent
            transactionId={transactionId}
            setDeclineRequested={setDeclineRequested}
            setModalOpened={setModalOpened}
          />
        </Modal>
      </>
    );
  } else {
    return <></>;
  }
};
