import { Grid, GridItem } from '@loomispay/vault';
import { EnabledFeature, useConfiguration } from 'configuration';

import { useMerchantIdFromUrl } from '../merchant-hooks';
import { useMerchant } from '../merchantReporting-hooks';
import { ReportingDataForm } from './ReportingDataForm';

export const MerchantReportingDataTab = () => {
  const config = useConfiguration();

  const merchantId = useMerchantIdFromUrl();
  const { data } = useMerchant(merchantId);

  if (!config.data?.enabledFeatures.includes(EnabledFeature.EDIT_MERCHANT_REPORTING_DETAILS)) {
    return null;
  }
  return (
    <>
      <Grid gutter={0}>
        <GridItem l={12} m={8} s={4}>
          <Grid gutter={0}>{data && <ReportingDataForm merchant={data} />}</Grid>
        </GridItem>
      </Grid>
    </>
  );
};
