import styled from 'styled-components';

export const StyledTable = styled.table`
  font-family: 'Roobert';
  color: hsl(0, 0%, 35%);
  width: 100% !important;
`;

export const Th = styled.th`
  font-weight: 400;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid hsla(0, 0%, 0%, 0.1);
`;

export const Td = styled.td`
  color: hsl(0, 0%, 6%);
  font-size: 14px;
  border-bottom: 1px solid hsla(0, 0%, 0%, 0.1);
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  height: 3.2rem;
  > li {
    margin-bottom: 0;
  }
`;
