import { useTranslation } from 'react-i18next';
import { useMerchantIdFromUrl } from '../merchant-hooks';
import React, { useEffect, useState } from 'react';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { fetchFromBackend } from '../../../utils/fetch';
import { createToast } from 'vercel-toast';
import { formatISODateTime } from '../../../utils/formatters/dateFormatters';
import { formatAmount } from '../../../utils/formatters/amountFormatters';
import { FlexCol, FlexDiv, LabelWrapper } from '../../finance/styles';
import { Label, Table } from '@loomispay/vault';
import { MonthYearPicker } from '../../../components/DatePicker';

interface GetPayoutsResponse {
  hasMore: boolean;
  numberOfResults: number;
  nextCursor: string | null;
  payouts: Payout[];
}

interface Payout {
  payableBatchId: string;
  status: string;
  description: string;
  amount: number | null;
  settledCosts: number | null;
  currency: string;
  createdAt: string;
  settledAt: string | null;
}

const humanReadableStatus = (status: string): string => {
  if (!status) {
    return '';
  }
  return status[0].toUpperCase() + status.slice(1, status.length).toLowerCase();
};

export const MerchantPayoutsTable = () => {
  const { t } = useTranslation();
  const merchantId = useMerchantIdFromUrl();
  const [payouts, setPayouts] = useState<Payout[]>([]);
  const [monthYear, setMonthYear] = useState<Date>(new Date());

  const formSchema = z.object({
    payoutsMonth: z.date(),
  });

  type FormInputSchema = z.infer<typeof formSchema>;

  const { control, handleSubmit, register, getValues } = useForm<FormInputSchema>({
    defaultValues: {
      payoutsMonth: new Date(),
    },
    resolver: zodResolver(formSchema),
  });

  const fetchPayouts = async () => {
    const month = (monthYear.getMonth() + 1).toString().padStart(2, '0');
    const year = monthYear.getFullYear();

    const url = `/merchants/${merchantId}/payouts/${year}-${month}`;

    const response = await fetchFromBackend(url);

    if (response.ok) {
      const responseBody: GetPayoutsResponse = await response.json();
      const payouts = responseBody.payouts;

      if (payouts.length === 0) {
        createToast(`${t('payout.table.empty', { year: year, month: month })}`, {
          timeout: 6000,
          type: 'dark',
        });
      }

      setPayouts(payouts);
    } else {
      createToast(t('payout.table.error'), {
        timeout: 6000,
        type: 'error',
      });
    }
  };

  const onSubmit = async (data: FormInputSchema) => {
    setMonthYear(data.payoutsMonth);
  };

  const handleChange = () => {
    onSubmit(getValues());
  };

  useEffect(() => {
    fetchPayouts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthYear]);

  const columns = [
    { Header: t('payout.table.reference'), accessor: 'description', width: 80 },
    { Header: t('payout.table.status'), accessor: 'status', width: 80 },
    { Header: t('payout.table.batch-initiated'), accessor: 'createdAt' },
    { Header: t('payout.table.paid-at'), accessor: 'settledAt' },
    { Header: t('payout.table.paid-amount'), accessor: 'amount' },
  ];

  const tableData =
    payouts.map(it => ({
      ...it,
      createdAt: formatISODateTime(it.createdAt),
      settledAt: formatISODateTime(it.settledAt),
      status: humanReadableStatus(it.status),
      amount: formatAmount(it.amount, it.currency),
    })) || [];

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} onChange={handleChange}>
        <FlexDiv>
          <FlexCol>
            <LabelWrapper>
              <Label size={'s'}>{t('payout.table.month')}</Label>
            </LabelWrapper>
            <MonthYearPicker
              control={control}
              {...register('payoutsMonth', {
                onChange: () => {
                  handleChange();
                },
              })}
            />
          </FlexCol>
        </FlexDiv>
      </form>
      <Table columns={columns} data={tableData} />
    </div>
  );
};
