import { Display, Grid, GridItem, Spacing, Text } from '@loomispay/vault';
import { SupportBackofficeSelect } from 'components/Select';
import { useTranslation } from 'react-i18next';
import { usePersistentState } from 'utils/hooks/usePersistentState';
import { CustomReconciliationComponent } from './CustomReconciliationComponent';
import { ManualInvoicePayment } from './manual-invoice-payment/ManualInvoicePayment';

export enum ReconciliationType {
  MANUAL_INVOICE_PAYMENT = 'MANUAL_INVOICE_PAYMENT',
  CUSTOM = 'CUSTOM',
}

const ReconciliationScreen = () => {
  const { t } = useTranslation();
  const [reconciliationType, setReconciliationType] = usePersistentState<ReconciliationType>(
    'reconciliationType',
    ReconciliationType.MANUAL_INVOICE_PAYMENT
  );

  const reconciliationTypeLabel = {
    [ReconciliationType.MANUAL_INVOICE_PAYMENT]: t('reconciliation.manual-invoice-payment'),
    [ReconciliationType.CUSTOM]: t('reconciliation.custom-reconciliation'),
  };

  const typeOptions = Object.values(ReconciliationType).map(it => ({
    value: it,
    label: reconciliationTypeLabel[it],
  }));

  return (
    <div>
      <Display size="s">{t('reconciliation.title')}</Display>
      <Spacing bottom="1" />
      <Grid>
        <GridItem s={12} m={3} l={3}>
          <SupportBackofficeSelect
            options={typeOptions}
            defaultValue={{
              label: reconciliationTypeLabel[reconciliationType],
              value: reconciliationType,
            }}
            onSelectionChange={value => setReconciliationType(value as ReconciliationType)}
            label={t('reconciliation.type')}
          />
        </GridItem>
      </Grid>
      <Spacing bottom="4" />
      <Grid>
        <GridItem s={12} m={12} l={12}>
          {
            {
              MANUAL_INVOICE_PAYMENT: <ManualInvoicePayment />,
              CUSTOM: (
                <>
                  <Text size="xl" weight="semiBold">
                    {t('reconciliation.custom-reconciliation')}
                  </Text>
                  <Spacing top="2" />
                  <CustomReconciliationComponent />
                </>
              ),
            }[reconciliationType]
          }
        </GridItem>
      </Grid>
    </div>
  );
};

export default ReconciliationScreen;
