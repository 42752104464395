import { Button, Icon, Text } from '@loomispay/vault';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ClipLoader from 'react-spinners/ClipLoader';
import { links } from '../../config';
import { Permission, useUserPermissions } from '../../permissions';
import { File, FileStatus } from './FileProcessing.service';

export const RetryButton = (props: { file: File; refresh: () => void }) => {
  const { t } = useTranslation();
  const { permissions } = useUserPermissions();
  const [clicked, setClicked] = useState<boolean>(false);

  const retryFile = async () => {
    const response = await fetch(links.backendUrl + '/file-processing/' + props.file.id, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.ok;
  };

  return (
    <div>
      {(props.file.needsAttention || props.file.status === FileStatus.SUCCEEDED) &&
        !props.file.retry &&
        !clicked &&
        permissions.includes(Permission.WRITE_FILE_PROCESSING) && (
          <Button
            label={t('file-processing.retry')}
            onClick={() => {
              setClicked(true);
              retryFile().then(result => {
                if (result) {
                  props.refresh();
                  setClicked(false);
                }
              });
            }}
          />
        )}
      {clicked && <ClipLoader size={35} />}
      {props.file.needsAttention && props.file.retry && (
        <Text color={'positive'}>
          <Icon color={'positive'} size={'m'} name={'checkmark'} />
          {t('file-processing.file-is-processing')}
        </Text>
      )}
    </div>
  );
};
