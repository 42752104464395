import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { useMerchantIdFromUrl, useMerchantStatus, useMerchantStores } from '../merchant-hooks';
import { useConfiguration } from '../../../configuration';
import { Permission } from '../../../permissions';
import { fetchFromBackend } from '../../../utils/fetch';
import { createToast } from 'vercel-toast';
import { Button } from '@loomispay/vault';
import { ConfirmationDialog } from './common/ConfirmationDialog';
import { AmexOnboardingState } from '../../../api/types';

export const AmexOnboardingButton = () => {
  const { t } = useTranslation();
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState<boolean>(false);
  const { data, mutate: reloadMerchantStatus } = useMerchantStatus();
  const [inProgress, setInProgress] = useState<boolean>(false);
  const merchantId = useMerchantIdFromUrl();
  const { data: stores } = useMerchantStores();
  // NOTE: inProgress is a local component state.
  // If for some reason the re-rendering has been triggered this state will be reset,
  // although the onboarding is in progress. For now, this is acceptable.
  const permissions = useConfiguration().data?.permissions;

  const canNotOnboardInAmex =
    !permissions?.includes(Permission.ENABLE_AMEX) ||
    !data?.amex.onboardingPossibility ||
    data?.amex.feePercentage == null ||
    stores?.stores.filter(
      store => store.amexOnboardingState === null || store.amexOnboardingState === AmexOnboardingState.OFFBOARDED
    ).length === 0;

  const onboardInAmex = async () => {
    await fetchFromBackend(`/merchants/${merchantId}/enable-amex`, {
      method: 'POST',
    }).then(response => {
      if (response.ok) {
        reloadMerchantStatus();
        setInProgress(true);
        setConfirmationDialogOpen(false);
        createToast(t('merchant.details.status.amex-onboarding.tooltip'), {
          timeout: 6000,
          type: 'success',
        });
      } else {
        createToast(t('unexpected-error'), {
          timeout: 6000,
          type: 'error',
        });
      }
    });
  };

  return (
    <>
      <Button
        label={t('merchant.details.status.amex-onboarding')}
        size={'s'}
        onClick={() => {
          setConfirmationDialogOpen(true);
        }}
        disabled={canNotOnboardInAmex || inProgress}
        variant={'secondary'}
      />
      <ConfirmationDialog
        onConfirm={onboardInAmex}
        onCancel={() => setConfirmationDialogOpen(false)}
        isOpen={confirmationDialogOpen}
        text={t('merchant.details.status.amex-onboarding.warning')}
      />
    </>
  );
};
