import React, { useState } from 'react';
import { useMerchantIdFromUrl } from '../merchant-hooks';
import { fetchFromBackend } from '../../../utils/fetch';
import { createToast } from 'vercel-toast';
import { t } from 'i18next';
import { Button } from '@loomispay/vault';
import { ConfirmationDialog } from './common/ConfirmationDialog';

export const OffboardMerchantButton = () => {
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState<boolean>(false);
  const merchantId = useMerchantIdFromUrl();

  const offboardMerchant = async () => {
    const response = await fetchFromBackend(`/merchants/${merchantId}/offboard`, {
      method: 'POST',
    });
    if (response.ok) {
      createToast(t('merchant.details.status.offboard-initiated'), {
        timeout: 6000,
        type: 'success',
      });
    } else if (response.status > 500) {
      createToast(t('unexpected-error'), {
        timeout: 6000,
        type: 'error',
      });
    } else if (response.status >= 400) {
      createToast(t('bad-data-format'), {
        timeout: 6000,
        type: 'error',
      });
    }
    setConfirmationDialogOpen(false);
  };

  return (
    <>
      <Button
        label={t('merchant.details.status.offboard')}
        size={'s'}
        onClick={() => setConfirmationDialogOpen(true)}
        variant={'secondary'}
      />
      <ConfirmationDialog
        onConfirm={offboardMerchant}
        onCancel={() => setConfirmationDialogOpen(false)}
        isOpen={confirmationDialogOpen}
        text={t('merchant.details.status.offboard-warning')}
      />
    </>
  );
};
