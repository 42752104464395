import { Button, Label, Spacing } from '@loomispay/vault';
import { Modal } from 'components/Modal';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createToast } from 'vercel-toast';
import { useMerchantIdFromUrl } from '../merchant-hooks';
import { fetchFromBackend } from '../../../utils/fetch';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form } from '../fees/helper';
import { FormTextInput } from '../../../components/TextInput';

interface Props {
  storeId: string;
  storeName: string;
}

export const DankortOnboardingButton = (props: Props) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isOnboardingButtonDisabled, setIsOnboardingButtonDisabled] = useState<boolean>(false);

  const ModalContent = () => {
    const merchantId = useMerchantIdFromUrl();
    const [isModalButtonDisabled, setModalButtonDisabled] = useState<boolean>(false);

    const DankortOnboardingFormInputSchema = z.object({
      baxId: z.string().regex(/^[0-9]{6,7}$/, {
        message: t('stores.onboard-in-dankort.bax-id-label-validation-error'),
      }),
    });
    type DankortOnboardingFormInputSchemaType = z.infer<typeof DankortOnboardingFormInputSchema>;
    const { control, handleSubmit } = useForm<DankortOnboardingFormInputSchemaType>({
      defaultValues: {
        baxId: undefined,
      },
      resolver: zodResolver(DankortOnboardingFormInputSchema),
    });

    const onSubmit = async (data: DankortOnboardingFormInputSchemaType) => {
      setModalButtonDisabled(true);

      await fetchFromBackend(`/merchants/${merchantId}/stores/${props.storeId}/enable-dankort`, {
        method: 'POST',
        body: JSON.stringify({ baxId: data.baxId }),
      }).then(response => {
        if (response.ok) {
          setIsOnboardingButtonDisabled(true);
          setIsModalOpen(false);
          createToast(t('stores.onboard-in-dankort.successful.request'), {
            timeout: 6000,
            type: 'success',
          });
        } else {
          setIsOnboardingButtonDisabled(false);
          setModalButtonDisabled(false);
          createToast(t('stores.onboard-in-dankort.failed.request'), {
            timeout: 6000,
            type: 'error',
          });
        }
      });
    };

    return (
      <Modal isOpen={isModalOpen} close={() => setIsModalOpen(false)}>
        <Label>{t('stores.onboard-in-dankort.warning', { storeName: props.storeName })}</Label>
        <Spacing bottom="2" />
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormTextInput
            name="baxId"
            control={control}
            label={t('stores.onboard-in-dankort.bax-id-label')}
            placeholder={t('stores.onboard-in-dankort.bax-id-input-placeholder')}
          />
          <Spacing bottom="2" />
          <div style={{ display: 'flex' }}>
            <div style={{ marginLeft: 'auto' }}></div>
            <Button
              label={t('confirmation-dialog.cancel')}
              variant={'tertiary'}
              onClick={() => setIsModalOpen(false)}
            />
            <Spacing right="4" />
            <Button
              type={'submit'}
              label={t('confirmation-dialog.confirm')}
              variant={'destructive'}
              disabled={isModalButtonDisabled}
            />
          </div>
        </Form>
      </Modal>
    );
  };

  return (
    <>
      <Button
        label={t('stores.onboard-in-dankort.button')}
        variant={'primary'}
        onClick={() => setIsModalOpen(true)}
        disabled={isOnboardingButtonDisabled}
      />
      <Modal closeButton isOpen={isModalOpen} close={() => setIsModalOpen(false)}>
        <ModalContent />
      </Modal>
    </>
  );
};
