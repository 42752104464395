import { Permission } from '../../../permissions';
import React, { useState } from 'react';
import { Button, Heading } from '@loomispay/vault';
import { Modal } from '../../Modal';
import { useTranslation } from 'react-i18next';
import { fetchData, fetchFromBackend } from '../../../utils/fetch';
import ClipLoader from 'react-spinners/ClipLoader';
import { createToast } from 'vercel-toast';
import { LoomisLegalEntity, Store, StoresResponse, TerminalsResponse } from '../../../api/types';
import { toUrl } from '../../../utils/formatters/toUrl';
import { MerchantReportingDetails } from '../../../routes/merchants/merchantReporting-hooks';
import { Currency, Invoice } from '../InvoicesTable';
import { CreditInvoiceTableForm } from './CreditInvoiceTableForm';
import { EnabledFeature, useConfiguration } from '../../../configuration';

export interface CreditInvoiceRequest {
  originalInvoiceId: string;
  creditInvoiceDate: string;
  legalEntityLines: Map<LoomisLegalEntity | undefined, CreditInvoiceLine[]>;
  storeLines: Map<string, CreditInvoiceLine[]>;
}

export interface CreditInvoiceLine {
  type: InvoiceLineType | undefined;
  quantity: number;
  unitPrice: number;
  currency: Currency;
  vatPercentage: number;
  vatAmount: number;
  extraDescription: string | null;
  subLines: CreditInvoiceSubLine[];
}

export interface CreditInvoiceSubLine {
  type: InvoiceSubLineType | undefined;
  totalPrice: number;
}

export enum InvoiceLineType {
  TERMINAL_SUBSCRIPTION_SOFTWARE = 'TERMINAL_SUBSCRIPTION_SOFTWARE',
  TERMINAL_SUBSCRIPTION_HARDWARE = 'TERMINAL_SUBSCRIPTION_HARDWARE',
  TERMINAL_SUBSCRIPTION_SOFTWARE_DISCOUNT = 'TERMINAL_SUBSCRIPTION_SOFTWARE_DISCOUNT',
  TERMINAL_SUBSCRIPTION_HARDWARE_DISCOUNT = 'TERMINAL_SUBSCRIPTION_HARDWARE_DISCOUNT',
  CARD_PRESENT_TRANSACTION = 'CARD_PRESENT_TRANSACTION',
  CARD_NOT_PRESENT_TRANSACTION = 'CARD_NOT_PRESENT_TRANSACTION',
}

export enum InvoiceSubLineType {
  FIXED_FEE = 'FIXED_FEE',
  PERCENTAGE_FEE = 'PERCENTAGE_FEE',
}

export const CreditInvoiceComponent = (props: { invoice: Invoice }) => {
  const { data } = useConfiguration();

  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [stores, setStores] = useState<Store[]>([]);
  const [terminalModels, setTerminalModels] = useState<string[]>([]);
  const [merchantDetails, setMerchantDetails] = useState<MerchantReportingDetails | undefined>(undefined);

  const fetchStores = () => {
    return fetchData<StoresResponse>(
      `/merchants/${props.invoice.merchantId}/stores`,
      t('credit.invoices.modal.store.fetch.failure'),
      data => {
        setStores(data.stores);
      }
    );
  };

  const fetchTerminals = () => {
    return fetchData<TerminalsResponse>(
      toUrl('/terminals', [['merchantId', props.invoice.merchantId]]),
      t('credit.invoices.modal.terminals.fetch.failure'),
      data => {
        const terminalModels = new Set(
          data.terminals.map(t => {
            return t.model;
          })
        );
        setTerminalModels(Array.from(terminalModels));
      }
    );
  };

  const fetchMerchantDetails = () => {
    return fetchData<MerchantReportingDetails>(
      `/merchants/search/${props.invoice.merchantId}`,
      t('credit.invoices.modal.merchant.fetch.failure'),
      data => {
        setMerchantDetails(data);
      }
    );
  };

  const isPositiveAmount = () => {
    return props.invoice.grossInvoiceSumAmount >= 0;
  };

  const disabled = () => {
    if (data !== undefined) {
      return !(
        data.permissions.includes(Permission.WRITE_FINANCE) &&
        data.enabledFeatures.includes(EnabledFeature.CREDIT_INVOICES_CREATION)
      );
    } else {
      return true;
    }
  };

  return (
    <>
      {!loading && isPositiveAmount() && (
        <Button
          size={'s'}
          label={'Credit Invoice'}
          disabled={disabled()}
          onClick={() => {
            setLoading(true);
            Promise.all([fetchMerchantDetails(), fetchStores(), fetchTerminals()])
              .then(() => {
                setIsOpen(true);
              })
              .catch((e: Error) => {
                createToast(e.message, {
                  timeout: 6000,
                  type: 'error',
                });
              })
              .finally(() => {
                setLoading(false);
              });
          }}
        />
      )}
      {loading && <ClipLoader size={35} />}
      <Modal isOpen={isOpen} close={() => setIsOpen(false)} closeButton scrollable={true}>
        <ModalContent
          stores={stores}
          terminalModels={terminalModels}
          merchant={merchantDetails}
          originalInvoice={props.invoice}
          setIsOpen={setIsOpen}
        />
      </Modal>
    </>
  );
};

const ModalContent = (props: {
  stores: Store[];
  terminalModels: string[];
  merchant?: MerchantReportingDetails;
  originalInvoice: Invoice;
  setIsOpen: (value: boolean) => void;
}) => {
  const { t } = useTranslation();

  const post = (request: CreditInvoiceRequest, callback: () => void) => {
    const toJson = (request: CreditInvoiceRequest) => {
      return JSON.stringify({
        creditInvoiceDate: request.creditInvoiceDate,
        originalInvoiceId: request.originalInvoiceId,
        legalEntityLines: Object.fromEntries(request.legalEntityLines),
        storeLines: Object.fromEntries(request.storeLines),
      });
    };

    fetchFromBackend('/credit-invoices', {
      method: 'POST',
      body: toJson(request),
    }).then(response => {
      if (response.ok) {
        props.setIsOpen(false);
        createToast(t('credit.invoices.modal.message.success'), {
          timeout: 6000,
          type: 'success',
        });
      } else {
        createToast(t('credit.invoices.modal.message.error'), {
          timeout: 6000,
          type: 'error',
        });
      }
      callback();
    });
  };

  return (
    <>
      <Heading size={'m'}>
        {t('credit.invoices.modal.title')} {props.merchant?.companyName}
      </Heading>
      <CreditInvoiceTableForm
        terminalModels={props.terminalModels}
        stores={props.stores}
        post={post}
        originalInvoice={props.originalInvoice}
      />
    </>
  );
};
