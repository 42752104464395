import { Text } from '@loomispay/vault';
import { ReactNode, useState } from 'react';
import { TabsWrapper, TabWrapper } from './FileProcessing.styles';

export type Tab = {
  label: ReactNode;
  key: string;
};

export type TabsProps = {
  tabs: Tab[];
  initialActiveTab: string;
  onClick?: (tab: Tab) => void;
};

export const Tabs = (props: { tabs: Tab[]; initialActiveTab: string; onClick?: (tab: Tab) => void }) => {
  const [activeTab, setActiveTab] = useState(props.initialActiveTab || props.tabs[0]?.key || '');
  const onTabClick = (tab: Tab) => {
    if (props.onClick) props.onClick(tab);
    setActiveTab(tab.key);
  };

  return (
    <TabsWrapper>
      {props.tabs.map(tab => {
        const isActive = tab.key === activeTab;
        return (
          <TabWrapper isActive={isActive} onClick={() => onTabClick(tab)} key={tab.key}>
            <Text color={isActive ? 'primary' : 'tertiary'}>{tab.label}</Text>
          </TabWrapper>
        );
      })}
    </TabsWrapper>
  );
};
