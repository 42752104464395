import { Button, Toggle } from '@loomispay/vault';
import { z, ZodIssueCode } from 'zod';
import { FlexDiv, FlexLeftCol, FlexRightCol, StyledForm, ToggleContainer } from './ReportingDataForm.styles';

import { zodResolver } from '@hookform/resolvers/zod';
import { ElasticMerchant } from 'api/types';
import { FormTextInput } from 'components/TextInput';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { fetchFromBackend } from 'utils/fetch';
import { createToast } from 'vercel-toast';
import { useMerchantIdFromUrl } from '../merchant-hooks';

export interface UpdateReportingDataRequest {
  salesPersonEmail: string;
  salesPersonFullName: string;
  annualTurnover: number;
  averageOrderValue: number;
  transactionsPercentageCash: number;
  transactionsPercentageCards: number;
  transactionsPercentageOther: number;
  markAsTestMerchant: boolean;
}

export const ReportingDataForm = (props: { merchant: ElasticMerchant }) => {
  const merchantId = useMerchantIdFromUrl();
  const currency = props.merchant?.merchant.bankAccount?.currency;

  const [isTestMerchant, setIsTestMerchant] = useState<boolean>(props.merchant?.merchant.isTestMerchant || false);

  const { t } = useTranslation();

  const maxTwoDecimalsRegex = new RegExp(/^\d+(\.\d{0,2})?$/);
  const reportingDataNumberValidation = z
    .string()
    .regex(maxTwoDecimalsRegex, t('merchant.reporting-data.validation.decimals'))
    .min(1);

  const FormInputSchema = z
    .object({
      salesPersonEmail: z.string().min(1).max(255),
      salesPersonFullName: z.string().min(1).max(255),
      annualTurnover: reportingDataNumberValidation,
      averageOrderValue: reportingDataNumberValidation,
      transactionsPercentageCash: reportingDataNumberValidation,
      transactionsPercentageCards: reportingDataNumberValidation,
      transactionsPercentageOther: reportingDataNumberValidation,
    })
    .superRefine((val, ctx) => {
      if (Number(val.annualTurnover) < 0.01) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          message: t('merchant.reporting-data.validation.positive-amount'),
          path: ['annualTurnover'],
        });
      }
      if (Number(val.averageOrderValue) < 0.01) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          message: t('merchant.reporting-data.validation.positive-amount'),
          path: ['averageOrderValue'],
        });
      }
      if (Number(val.transactionsPercentageCards) < 0 || Number(val.transactionsPercentageCards) > 100) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          message: t('merchant.reporting-data.validation.percentage-range'),
          path: ['transactionsPercentageCards'],
        });
      }
      if (Number(val.transactionsPercentageCash) < 0 || Number(val.transactionsPercentageCash) > 100) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          message: t('merchant.reporting-data.validation.percentage-range'),
          path: ['transactionsPercentageCash'],
        });
      }
      if (Number(val.transactionsPercentageOther) < 0 || Number(val.transactionsPercentageOther) > 100) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          message: t('merchant.reporting-data.validation.percentage-range'),
          path: ['transactionsPercentageOther'],
        });
      }
      if (
        Number(val.transactionsPercentageCash) +
          Number(val.transactionsPercentageCards) +
          Number(val.transactionsPercentageOther) !==
        100
      ) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          message: t('merchant.reporting-data.validation.total'),
          path: ['transactionsPercentageCash'],
        });
      }
    });

  type FormInputSchemaType = z.infer<typeof FormInputSchema>;

  const { control, handleSubmit } = useForm<FormInputSchemaType>({
    defaultValues: {
      salesPersonEmail: props.merchant?.sales?.contactPersonEmail || undefined,
      salesPersonFullName: props.merchant?.sales?.contactPersonFullName || undefined,
      annualTurnover: props.merchant?.forecast?.annualTurnover?.toString() || undefined,
      averageOrderValue: props.merchant?.forecast?.averageOrderValue?.toString() || undefined,
      transactionsPercentageCash: props.merchant?.forecast?.transactionsPercentageCash?.toString() || undefined,
      transactionsPercentageCards: props.merchant?.forecast?.transactionsPercentageCards?.toString() || undefined,
      transactionsPercentageOther: props.merchant?.forecast?.transactionsPercentageOther?.toString() || undefined,
    },
    resolver: zodResolver(FormInputSchema),
  });

  const onSubmit = async (formData: FormInputSchemaType) => {
    const reqBody: UpdateReportingDataRequest = {
      salesPersonEmail: formData.salesPersonEmail,
      salesPersonFullName: formData.salesPersonFullName,
      annualTurnover: Number(formData.annualTurnover),
      averageOrderValue: Number(formData.averageOrderValue),
      transactionsPercentageCash: Number(formData.transactionsPercentageCash),
      transactionsPercentageCards: Number(formData.transactionsPercentageCards),
      transactionsPercentageOther: Number(formData.transactionsPercentageOther),
      markAsTestMerchant: isTestMerchant,
    };
    const response = await fetchFromBackend(`/merchants/${merchantId}/update-reporting-data`, {
      method: 'PUT',
      body: JSON.stringify(reqBody),
    });
    if (response.ok) {
      createToast(t('merchant.reporting-data.update-success'), {
        timeout: 6000,
        type: 'success',
      });
    } else if (response.status >= 400)
      createToast(t('merchant.reporting-data.update-error'), {
        timeout: 6000,
        type: 'error',
      });
  };
  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <FlexDiv>
        <FlexLeftCol>
          <FormTextInput
            name="salesPersonEmail"
            control={control}
            label={t('merchant.reporting-data.salesPersonEmail')}
            type="email"
            required={true}
          />
        </FlexLeftCol>
        <FlexRightCol>
          <FormTextInput
            name="salesPersonFullName"
            control={control}
            label={t('merchant.reporting-data.salesPersonFullName')}
            required={true}
          />
        </FlexRightCol>
      </FlexDiv>
      <FlexDiv>
        <FlexLeftCol>
          <FormTextInput
            name="annualTurnover"
            control={control}
            label={t('merchant.reporting-data.annualTurnover', { currency: currency })}
            required={true}
            type="number"
          />
        </FlexLeftCol>
        <FlexRightCol>
          <FormTextInput
            name="averageOrderValue"
            control={control}
            label={t('merchant.reporting-data.averageOrderValue', { currency: currency })}
            required={true}
            type="number"
          />
        </FlexRightCol>
      </FlexDiv>
      <FlexDiv>
        <FlexLeftCol>
          <FormTextInput
            name="transactionsPercentageCards"
            control={control}
            label={t('merchant.reporting-data.transactionsPercentageCards')}
            required={true}
            type="number"
          />
        </FlexLeftCol>
        <FlexRightCol>
          <FormTextInput
            name="transactionsPercentageCash"
            control={control}
            label={t('merchant.reporting-data.transactionsPercentageCash')}
            required={true}
            type="number"
          />
        </FlexRightCol>
      </FlexDiv>
      <FlexDiv>
        <FlexLeftCol>
          <FormTextInput
            name="transactionsPercentageOther"
            control={control}
            label={t('merchant.reporting-data.transactionsPercentageOther')}
            required={true}
            type="number"
          />
        </FlexLeftCol>
        <FlexRightCol></FlexRightCol>
      </FlexDiv>
      <ToggleContainer>
        <Toggle
          inputProps={{ value: `` + isTestMerchant }}
          label={t('merchant.reporting-data.isTestMerchant')}
          onChange={value => {
            setIsTestMerchant(value);
          }}
        ></Toggle>
      </ToggleContainer>
      <FlexDiv>
        <Button label={t('merchant.reporting-data.update')} type="submit" />
      </FlexDiv>
    </StyledForm>
  );
};
