import { Link, Table, Text } from '@loomispay/vault';
import { AmexFile, FileStatus } from './FileProcessing.service';
import { useTranslation } from 'react-i18next';
import { formatISODateTime } from 'utils/formatters/dateFormatters';
import { toTitleCase } from 'utils/formatters/toTitleCase';
import { DownloadButton } from './DownloadButton';
import { TriggerSettlementButton } from './TriggerSettlementButton';

export const AmexFileDataTable = (props: { data: AmexFile[]; refresh: () => void }) => {
  const { t } = useTranslation();

  const columns = [
    { Header: t('file-processing.table.name'), accessor: 'name' },
    { Header: t('file-processing.table.received-at'), accessor: 'receivedAt' },
    { Header: t('file-processing.table.processed-at'), accessor: 'processedAt' },
    { Header: t('file-processing.table.status'), accessor: 'status' },
    { Header: t('file-processing.table.kibana-url'), accessor: 'kibana' },
    { Header: t('file-processing.table.unique-payment-reference-number'), accessor: 'uniquePaymentReferenceNumber' },
    { Header: t('file-processing.table.amount'), accessor: 'amount' },
    { Header: t('file-processing.table.file-content'), accessor: 'fileContent' },
    { accessor: 'trigger' },
    { accessor: 'download' },
  ];

  const formatData =
    props.data.map(it => ({
      ...it,
      receivedAt: formatISODateTime(it.receivedAt),
      processedAt: formatISODateTime(it.processedAt),
      status: formatFileStatus(it.status),
      kibana: (
        <Link href={it.kibanaUrl} target="_blank">
          <Text>ElasticSearch</Text>
        </Link>
      ),
      amount: `${it.paymentNetAmount != null ? it.paymentNetAmount : ''} 
      ${it.paymentCurrency != null ? it.paymentCurrency : ''}`,
      fileContent: getFileContentDescription(it.isEmpty),
      trigger: <TriggerSettlementButton file={it} refresh={props.refresh} />,
      download: <DownloadButton file={it} />,
    })) ?? [];

  function formatFileStatus(status: FileStatus) {
    if (status != null) {
      if ([FileStatus.FAILED, FileStatus.SKIPPED].includes(status)) {
        return <Text color={'negative'}>{toTitleCase(status)}</Text>;
      }
      return <Text>{toTitleCase(status)}</Text>;
    }
    return null;
  }

  function getFileContentDescription(isEmpty: boolean | null) {
    switch (isEmpty) {
      case true:
        return t('file-processing.file-content.no-transactions');
      case false:
        return t('file-processing.file-content.with-transactions');
      default:
        return '';
    }
  }

  return <Table columns={columns} data={formatData} loading={!props.data} />;
};
