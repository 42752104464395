import React, { useState } from 'react';
import { useMerchantIdFromUrl } from '../merchant-hooks';
import { useMerchant } from '../merchantReporting-hooks';
import { Permission, useUserPermissions } from '../../../permissions';
import { fetchFromBackend } from '../../../utils/fetch';
import { createToast } from 'vercel-toast';
import { t } from 'i18next';
import { Button } from '@loomispay/vault';
import { StatusCode } from '../../../api/types';
import { ConfirmationDialog } from './common/ConfirmationDialog';

export const RetrySalesforceOnboardingButton = () => {
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState<boolean>(false);
  const merchantId = useMerchantIdFromUrl();
  const { data: merchant, mutate: reloadMerchant } = useMerchant(merchantId);
  const { permissions } = useUserPermissions();

  const retryOnboarding = async () => {
    const response = await fetchFromBackend(`/merchants/${merchantId}/retry-salesforce-onboarding`, {
      method: 'POST',
    });
    if (response.ok) {
      reloadMerchant();
      createToast(t('merchant.details.status.salesforce-onboarding.initiated'), {
        timeout: 6000,
        type: 'success',
      });
    } else if (response.status > 500) {
      createToast(t('unexpected-error'), {
        timeout: 6000,
        type: 'error',
      });
    } else if (response.status >= 400) {
      createToast(t('bad-data-format'), {
        timeout: 6000,
        type: 'error',
      });
    }
    setConfirmationDialogOpen(false);
  };

  return (
    <>
      <Button
        label="Retry Salesforce Onboarding"
        size={'s'}
        onClick={() => {
          setConfirmationDialogOpen(true);
        }}
        disabled={
          !permissions.includes(Permission.RETRY_MERCHANT_SALESFORCE_ONBOARDING) ||
          !merchant?.onboarding?.contractSignedAt ||
          !merchant?.merchant?.status?.code ||
          [
            StatusCode.DEACTIVATED,
            StatusCode.OFFBOARDING_REQUESTED,
            StatusCode.OFFBOARDED,
            StatusCode.ANNULLED,
          ].includes(merchant?.merchant?.status?.code)
        }
        variant={'secondary'}
      />
      <ConfirmationDialog
        onConfirm={retryOnboarding}
        onCancel={() => setConfirmationDialogOpen(false)}
        isOpen={confirmationDialogOpen}
        text={t('merchant.details.status.salesforce-onboarding.warning')}
      />
    </>
  );
};
