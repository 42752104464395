import { Icon, Text } from '@loomispay/vault';
import { ReactNode, useState } from 'react';

import styled from 'styled-components';

export type CollapsableProps = {
  title: string;
  startExpanded: boolean;
  children: ReactNode;
};

export default function Collapsable({ title, startExpanded, children }: CollapsableProps) {
  const [isExpanded, setIsExpanded] = useState(startExpanded);
  return (
    <CollapsableWrapper isExpanded={isExpanded}>
      <CollapsableHeader onClick={() => setIsExpanded(!isExpanded)}>
        <CollapsableTitle>
          <StyledIcon>
            <Icon name={isExpanded ? 'chevronUp' : 'chevronDown'} size="s" />
          </StyledIcon>
          {title}
        </CollapsableTitle>
      </CollapsableHeader>
      {isExpanded && <article>{children}</article>}
    </CollapsableWrapper>
  );
}

const CollapsableWrapper = styled.div<{ isExpanded: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings[2]};
  border-width: ${({ isExpanded }) => (isExpanded ? '1px 0 0 0' : '1px 0 1px 0')};
  border-style: solid;
  border-color: ${({ theme }) => theme.color.border.disabledBorder};
  margin-bottom: ${({ isExpanded }) => (isExpanded ? '0' : '1rem')};
`;

const CollapsableHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacings[3]};
  height: 40px;
`;

const CollapsableTitle = styled(Text)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const StyledIcon = styled.span`
  margin-right: 0.5rem;
`;
