import { Display, Tabs, Text } from '@loomispay/vault';
import { TFunction, useTranslation } from 'react-i18next';
import { DataTable, GetAmexTransactionFeeRule, GetTransactionFeeRules } from './DataTable';

import { Tab } from '@loomispay/vault/build/components/Tabs/types';
import { ContentWrapper } from 'components/ContentWrapper';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { AddNewRule } from './AddNewRule';
import { AddNewAmexRule } from './AddNewAmexRule';

export enum CountryName {
  DK = 'Denmark',
  SE = 'Sweden',
  ES = 'Spain',
  NO = 'Norway',
}

export enum CountryCode {
  DK = 'DK',
  SE = 'SE',
  ES = 'ES',
  NO = 'NO',
}

export const getCountryFromCountryCode = (countryCode: CountryCode, t: TFunction<'translation', undefined>) => {
  switch (countryCode) {
    case CountryCode.DK:
      return t('countries.denmark');
    case CountryCode.SE:
      return t('countries.sweden');
    case CountryCode.ES:
      return t('countries.spain');
    case CountryCode.NO:
      return t('countries.norway');
  }
};

export enum Channel {
  CARD_PRESENT = 'CARD_PRESENT',
  CARD_NOT_PRESENT = 'CARD_NOT_PRESENT',
}

export const channelOptions = [
  { value: Channel.CARD_PRESENT, label: 'Card present' },
  { value: Channel.CARD_NOT_PRESENT, label: 'Card not present' },
];

export const TabWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 1%;
`;

export const FeeButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 30%;
  padding-bottom: 1%;
`;

export const channelTabs = [
  {
    label: 'Card present',
    key: Channel.CARD_PRESENT,
  },
  {
    label: 'Card not present',
    key: Channel.CARD_NOT_PRESENT,
  },
];

const FeesScreen = () => {
  const { t } = useTranslation();
  const [activeTab, setTab] = useState<Channel>(channelTabs[0].key);
  const isCardPresent = activeTab === Channel.CARD_PRESENT;
  const { pathname } = useLocation();

  const countryCode = pathname.split('/')[2] as CountryCode;

  const { refresh } = GetTransactionFeeRules(countryCode, isCardPresent);
  const { refreshAmex } = GetAmexTransactionFeeRule(countryCode);

  return (
    <div>
      <Display size="s">{`${t('fees.title')} ${getCountryFromCountryCode(countryCode, t)}`}</Display>
      <Text>{t('fees.sub-title')}</Text>
      <ContentWrapper>
        <TabWrapper>
          <Tabs
            tabs={channelTabs}
            initialActiveTab={channelTabs[0].key}
            onClick={(tab: Tab) => {
              setTab(tab.key as Channel);
            }}
          />
          <FeeButtonWrapper>
            <AddNewRule refresh={refresh} cardPresent={isCardPresent} countryCode={countryCode} />
            <AddNewAmexRule refresh={refreshAmex} countryCode={countryCode} cardPresent={isCardPresent} />
          </FeeButtonWrapper>
        </TabWrapper>
        <DataTable country={countryCode} cardPresent={isCardPresent} />
      </ContentWrapper>
    </div>
  );
};

export default FeesScreen;
