import { Button, Table } from '@loomispay/vault';
import { saveAs } from 'file-saver';
import { fetchFromBackend } from 'utils/fetch';
import { formatAmount } from 'utils/formatters/amountFormatters';
import { formatISODate, formatISODateTime } from 'utils/formatters/dateFormatters';
import { toUrl } from 'utils/formatters/toUrl';
import { createToast } from 'vercel-toast';
import { CreditInvoiceComponent } from './credit/CreditInvoiceComponent';

export enum Currency {
  SEK = 'SEK',
  DKK = 'DKK',
  EUR = 'EUR',
  NOK = 'NOK',
}

export const currencyToCountry = {
  [Currency.SEK]: 'Sweden',
  [Currency.DKK]: 'Denmark',
  [Currency.EUR]: 'Spain',
  [Currency.NOK]: 'Norway',
};

export enum PaymentStatus {
  UNPAID = 'UNPAID',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  FULLY_PAID = 'FULLY_PAID',
  CLOSED = 'CLOSED',
  PARTIALLY_CREDITED = 'PARTIALLY_CREDITED',
  FULLY_CREDITED = 'FULLY_CREDITED',
}

export const paymentStatusLabel = {
  [PaymentStatus.UNPAID]: 'Unpaid',
  [PaymentStatus.PARTIALLY_PAID]: 'Partially paid',
  [PaymentStatus.FULLY_PAID]: 'Paid',
  [PaymentStatus.CLOSED]: 'Closed',
  [PaymentStatus.PARTIALLY_CREDITED]: 'Partially credited',
  [PaymentStatus.FULLY_CREDITED]: 'Fully credited',
};

export interface Invoice extends Record<string, unknown> {
  id: string;
  merchantId: string;
  currency: Currency;
  grossInvoiceSumAmount: number;
  serialNumber: string;
  serialNumberLegalEntity: string;
  status: string;
  paymentTerm: number | null;
  period: string;
  invoiceDate: Date | null;
  occurredAt: Date;
  dueDate: Date | null;
  createdAt: Date;
  paidAmount: number;
  openAmount: number;
  paymentStatus: PaymentStatus;
  fullyPaidDate: Date | null;
}

type TableData = Invoice;

const TableCell = (props: { value: string }) => {
  return props.value != null ? <div>{props.value}</div> : null;
};

const CountryCell = (props: { value: Currency }) => {
  return props.value != null ? <div>{currencyToCountry[props.value]}</div> : null;
};

const PaymentStatusCell = (props: { value: PaymentStatus }) => {
  return props.value != null ? <div>{paymentStatusLabel[props.value]}</div> : null;
};

const AmountCurrencyCell = (props: { value: string }) => {
  return props.value != null ? <div style={{ float: 'right' }}>{props.value}</div> : null;
};

const DateCell = (props: { value: string }) => {
  return props.value != null ? <div>{formatISODate(props.value)}</div> : null;
};

const DateTimeCell = (props: { value: string }) => {
  return props.value != null ? <div>{formatISODateTime(props.value)}</div> : null;
};

const CreditInvoiceCell = (props: { value: Invoice }) => {
  return props.value != null ? <CreditInvoiceComponent invoice={props.value} /> : null;
};

const DownloadCell = (props: { value: string }) => {
  return props.value != null ? (
    <Button
      label={'Download PDF'}
      onClick={() => {
        fetchFromBackend(toUrl('/invoices/' + props.value + '/pdf', [])).then(async response => {
          if (response.ok) {
            const blob = await response.blob();
            saveAs(blob, 'invoice-' + props.value);
          } else {
            createToast('There was an unexpected error while downloading the PDF', {
              timeout: 6000,
              type: 'error',
            });
          }
        });
      }}
    />
  ) : null;
};

const commonColumns = [
  {
    Header: 'Country',
    accessor: 'currency',
    Cell: CountryCell,
  },
  {
    Header: 'Invoice #',
    accessor: 'serialNumber',
    Cell: TableCell,
  },
  {
    Header: 'Status',
    accessor: 'paymentStatus',
    Cell: PaymentStatusCell,
  },
  {
    Header: 'Period',
    accessor: 'period',
    Cell: TableCell,
  },
  {
    Header: 'Invoice date',
    accessor: 'invoiceDate',
    Cell: DateCell,
  },
  {
    Header: 'Due date',
    accessor: 'dueDate',
    Cell: DateTimeCell,
  },
  {
    Header: 'Paid date',
    accessor: 'fullyPaidDate',
    Cell: DateTimeCell,
  },
  {
    Header: 'Total',
    accessor: 'grossInvoiceSumAmountWithCurrency',
    Cell: AmountCurrencyCell,
  },
  {
    Header: 'Outstanding',
    accessor: 'openAmountWithCurrency',
    Cell: AmountCurrencyCell,
  },
  {
    Header: '',
    accessor: 'invoice',
    Cell: CreditInvoiceCell,
  },
  {
    Header: '',
    accessor: 'id',
    Cell: DownloadCell,
  },
];

export const InvoicesTable = (props: { invoices: TableData[]; isSpecificMerchant: boolean }) => {
  const allColumns = [
    {
      Header: 'Merchant',
      accessor: 'merchantId',
      Cell: TableCell,
    },
    ...commonColumns,
  ];

  const getColumns = (isSpecificMerchant: boolean) => {
    if (isSpecificMerchant) {
      return commonColumns;
    }
    return allColumns;
  };

  const tableData: Invoice[] = [];

  props.invoices.forEach(it => {
    tableData.push({
      ...it,
      grossInvoiceSumAmountWithCurrency: formatAmount(it.grossInvoiceSumAmount, it.currency),
      openAmountWithCurrency: formatAmount(it.openAmount, it.currency),
      invoice: it,
    });
  });

  return (
    <div>
      <Table columns={getColumns(props.isSpecificMerchant)} data={tableData} />
    </div>
  );
};
