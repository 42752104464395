import { NAVIGATION_BAR_WIDTH_COLLAPSED, NAVIGATION_BAR_WIDTH_EXPANDED } from '@loomispay/vault';
import { useLanguage } from 'components/LanguageDropdown';
import DevHeader from 'DevHeader';
import { useState } from 'react';
import { NavigationSideBar } from 'routes/NavigationBar';
import styled from 'styled-components';
import { fetchByUrl } from './api';
import { links } from './config';
import { AppRouter } from './routes/AppRouter';

const Page = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-content: center;
  width: 100%;
  min-height: 100vh;
`;

type ContentProps = {
  expanded: boolean;
};

const Content = styled.div<ContentProps>`
  width: 100%;
  transition: width 0.2s ease-in-out;
  margin-left: ${({ expanded }) => (expanded ? NAVIGATION_BAR_WIDTH_EXPANDED : NAVIGATION_BAR_WIDTH_COLLAPSED)}px;
  overflow: auto;
`;

export function logOut() {
  fetchByUrl('/logout').then(_ => window.location.replace(links.loginForm));
}

function App() {
  // Load translations
  useLanguage();
  const [expanded, setExpanded] = useState(true);

  return (
    <div data-testid="container-app">
      <Page>
        <NavigationSideBar expanded={expanded} setExpanded={setExpanded} />
        <Content expanded={expanded}>
          <DevHeader />
          <AppRouter />
        </Content>
      </Page>
    </div>
  );
}

export default App;
