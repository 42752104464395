import { BackgroundColors, ForegroundColors } from '@loomispay/vault/build/theme/colors';
import { ReactNode } from 'react';
import styled from 'styled-components';

import { Strong } from '@loomispay/vault';

const Styled = styled.span<BadgeProps>`
  color: ${({ theme, color }) => (color ? theme.color[color] : theme.color.primary)};
  background-color: ${({ theme, background }) =>
    background ? theme.color.background[background] : theme.color.background.primaryBg};
  font-size: initial;
  border: 1px solid
    ${({ theme, background }) => (background ? theme.color.background[background] : theme.color.background.primaryBg)};
  border-radius: 20%;
  margin: 0px 5px;
`;

export interface BadgeProps {
  /**
   * Content of the badge.
   */
  children: ReactNode;
  /**
   * Set html id of the element
   */
  id?: string;
  /**
   * Text color
   */
  color?: ForegroundColors;
  /**
   * Background color
   */
  background?: BackgroundColors;
}

export const Badge = ({ color, background, children, ...props }: BadgeProps) => (
  <Styled background={background}>
    <span style={{ margin: '0px 5px' }}>
      <Strong align={'center'} color={color} {...props}>
        {children}
      </Strong>
    </span>
  </Styled>
);
