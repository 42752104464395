import styled from 'styled-components';

export const FlexDiv = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  margin-bottom: 0.8%;
`;

export const FlexLeftCol = styled.div`
  width: 50%;
  max-width: 20rem;
  padding-right: 1%;
  display: flex;
  flex-direction: column;
  padding-bottom: 1%;
  > div {
    display: flex;
    flex-direction: column;
  }
  label {
    width: 8rem;
  }
`;

export const FlexRightCol = styled.div`
  width: 50%;
  max-width: 20rem;
  padding-left: 1%;
  display: flex;
  padding-bottom: 1%;
  flex-direction: column;
  > div {
    display: flex;
    flex-direction: column;
  }
  label {
    width: 8rem;
  }
`;

export const LabelWrapper = styled.div`
  margin-bottom: 8px;
`;

export const SelectCol = styled.div`
  width: 50%;
  max-width: 20rem;
  padding-right: 1%;
  display: flex;
  padding-bottom: 1%;
  > div:last-child {
    width: 100%;
    margin-left: 2rem;
  }
  label {
    width: 8rem;
  }
`;
