import { Button, Heading, Select, Text } from '@loomispay/vault';
import { Permission, useUserPermissions } from 'permissions';
import { FunctionComponent, useState } from 'react';
import {
  constructFormFixedFeeAmountsInputSchema,
  constructFormPercentagesInputSchema,
  countryToFlag,
  TransactionFeeRuleRequestBody,
} from './AddNewRule';
import { Channel, channelOptions, CountryName } from './FeesScreen';
import { CloseButton, FeesRow, FlexDiv, FlexLeftCol, FlexRightCol, Form, SubHeading } from './helper';

import { zodResolver } from '@hookform/resolvers/zod';
import { Modal } from 'components/Modal';
import { SupportBackofficeSelect } from 'components/Select';
import { FormTextInput } from 'components/TextInput';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { fetchFromBackend } from 'utils/fetch';
import { toTitleCase } from 'utils/formatters/toTitleCase';
import { z } from 'zod';
import { TransactionFeeRule } from './DataTable';
import { DeleteRule } from './DeleteRule';
import { ReactComponent as Mastercard } from './Icons/Mastercard.svg';
import { ReactComponent as Visa } from './Icons/Visa.svg';

interface EditRuleProps {
  editFeeRule: TransactionFeeRule;
  refresh: () => void;
  cardPresent: boolean;
}

export const EditRule: FunctionComponent<EditRuleProps> = ({ refresh, editFeeRule, cardPresent }) => {
  const { permissions } = useUserPermissions();
  const [loadModal, setLoadModal] = useState<boolean>(false);

  return (
    <div>
      <Button
        size={'s'}
        variant={'tertiary'}
        disabled={!permissions.includes(Permission.WRITE_FEES)}
        onClick={() => {
          setLoadModal(true);
        }}
        icon={'edit'}
      />
      <Modal isOpen={loadModal} close={() => setLoadModal(false)}>
        <ModalContent
          editFeeRule={editFeeRule}
          cardPresent={cardPresent}
          refresh={refresh}
          setLoadModal={setLoadModal}
        />
      </Modal>
    </div>
  );
};

interface ModalContentProps {
  editFeeRule: TransactionFeeRule;
  cardPresent: boolean;
  refresh: () => void;
  setLoadModal: (value: boolean) => void;
}

const ModalContent: FunctionComponent<ModalContentProps> = ({ editFeeRule, cardPresent, refresh, setLoadModal }) => {
  const { t } = useTranslation();

  const FormInputSchema = z.object({
    percentage: constructFormPercentagesInputSchema(t),
    fixedFee: constructFormFixedFeeAmountsInputSchema(t),
  });

  type FormInputSchemaType = z.infer<typeof FormInputSchema>;

  const onSubmit = async (data: FormInputSchemaType) => {
    const reqBody: TransactionFeeRuleRequestBody = {
      countryCode: editFeeRule.countryCode,
      cardPresent: cardPresent,
      region: editFeeRule.region,
      cardCustomerType: editFeeRule.cardCustomerType,
      cardScheme: editFeeRule.cardScheme,
      cardType: editFeeRule.cardType,
      percentage: {
        default: Number(data.percentage.default),
        min: Number(data.percentage.min),
        max: Number(data.percentage.max),
      },
      fixedFeeAmount: {
        default: Number(data.fixedFee?.default ?? 0),
        min: Number(data.fixedFee?.min ?? 0),
        max: Number(data.fixedFee?.max ?? 0),
      },
    };
    const response = await fetchFromBackend('/transaction-fee-rules/' + editFeeRule.id, {
      method: 'PUT',
      body: JSON.stringify(reqBody),
    });
    if (response.ok) {
      setLoadModal(false);
      refresh();
    } else if (response.status >= 500) alert(t('fees.edit-rule.unexpected-error'));
    else if (response.status >= 400) alert(t('fees.edit-rule.updating-error'));
  };

  const { control, handleSubmit } = useForm<FormInputSchemaType>({
    defaultValues: {
      percentage: {
        min: editFeeRule.percentage.min.toString(),
        max: editFeeRule.percentage.max.toString(),
        default: editFeeRule.percentage.default.toString(),
      },
      fixedFee: {
        min: editFeeRule.fixedFeeAmount?.min?.toString(),
        max: editFeeRule.fixedFeeAmount?.max?.toString(),
        default: editFeeRule.fixedFeeAmount?.default?.toString(),
      },
    },
    resolver: zodResolver(FormInputSchema),
  });

  return (
    <>
      <CloseButton>
        <Button
          icon={'close'}
          variant={'tertiary'}
          onClick={() => {
            setLoadModal(false);
          }}
        />
      </CloseButton>
      <Heading size={'m'}>{t('fees.edit-rule.fee-range')}</Heading>
      <SubHeading>
        <Text>{t('fees.edit-rule.set-fees')}</Text>
      </SubHeading>

      <Form onSubmit={handleSubmit(x => onSubmit(x))}>
        <FlexDiv>
          <FlexLeftCol>
            <SupportBackofficeSelect
              isDisabled={true}
              defaultValue={{
                label: (
                  <div style={{ display: 'flex' }}>
                    {countryToFlag[editFeeRule.countryCode]}{' '}
                    <div style={{ alignSelf: 'center' }}>{CountryName[editFeeRule.countryCode]}</div>
                  </div>
                ),
              }}
              label={t('fees.edit-rule.market')}
            />
          </FlexLeftCol>
          <FlexRightCol>
            <Select
              label={t('fees.edit-default.channel')}
              isDisabled={true}
              name="cardPresent"
              defaultValue={channelOptions.find(e =>
                cardPresent ? e.value === Channel.CARD_PRESENT : e.value === Channel.CARD_NOT_PRESENT
              )}
              options={channelOptions}
            ></Select>
          </FlexRightCol>
        </FlexDiv>
        <FlexDiv>
          <FlexLeftCol>
            <SupportBackofficeSelect
              isDisabled={true}
              defaultValue={{
                label: (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {editFeeRule.cardScheme === 'MASTERCARD' ? (
                      <Mastercard style={{ paddingRight: '8px' }} />
                    ) : (
                      <Visa style={{ paddingRight: '8px' }} />
                    )}
                    {toTitleCase(editFeeRule.cardScheme)}
                  </div>
                ),
              }}
              label={t('fees.edit-rule.card-scheme')}
            />
          </FlexLeftCol>
          <FlexRightCol>
            <SupportBackofficeSelect
              isDisabled={true}
              defaultValue={{
                label: <div>{toTitleCase(editFeeRule.region)}</div>,
              }}
              label={t('fees.edit-rule.region')}
            />
          </FlexRightCol>
          <FlexLeftCol>
            <SupportBackofficeSelect
              isDisabled={true}
              defaultValue={{
                label: <div>{toTitleCase(editFeeRule.cardCustomerType)}</div>,
              }}
              label={t('fees.edit-rule.card-customer-type')}
            />
          </FlexLeftCol>
          <FlexRightCol>
            <SupportBackofficeSelect
              isDisabled={true}
              defaultValue={{
                label: <div>{toTitleCase(editFeeRule.cardType)}</div>,
              }}
              label={t('fees.edit-rule.card-type')}
            />
          </FlexRightCol>
          <FeesRow>
            <FormTextInput
              name="percentage.min"
              control={control}
              label={t('fees.edit-rule.minimum')}
              required={true}
            />
            <FormTextInput
              name="percentage.max"
              control={control}
              label={t('fees.edit-rule.maximum')}
              required={true}
            />
            <FormTextInput
              name="percentage.default"
              control={control}
              label={t('fees.edit-rule.default')}
              required={true}
            />
          </FeesRow>
          <FeesRow>
            <FormTextInput
              name="fixedFee.min"
              control={control}
              label={t('fees.edit-rule.fixed-minimum')}
              required={!cardPresent}
            />
            <FormTextInput
              name="fixedFee.max"
              control={control}
              label={t('fees.edit-rule.fixed-maximum')}
              required={!cardPresent}
            />
            <FormTextInput
              name="fixedFee.default"
              control={control}
              label={t('fees.edit-rule.fixed-default')}
              required={!cardPresent}
            />
          </FeesRow>
        </FlexDiv>
        <div style={{ float: 'right' }}>
          <Button type={'submit'} label={t('fees.edit-rule.save-changes')} size={'m'} />
        </div>
      </Form>
      <DeleteRule id={editFeeRule.id} refresh={refresh} openModals={setLoadModal} />
    </>
  );
};
