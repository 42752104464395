import { Modal } from '../../../components/Modal';
import { useMerchantIdFromUrl, useMerchantStatus } from '../merchant-hooks';
import { Button, Heading, Spacing, Text } from '@loomispay/vault';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from '../fees/helper';
import { FlexDiv, FlexLeftCol } from '../../fees/helper';
import { FormTextInput } from '../../../components/TextInput';
import { useForm } from 'react-hook-form';
import ClipLoader from 'react-spinners/ClipLoader';
import { fetchFromBackend } from '../../../utils/fetch';
import { createToast } from 'vercel-toast';
import { useMerchant } from '../merchantReporting-hooks';

interface FormInputSchema {
  fee: number;
}

export const AmexFeeComponent = (props: {
  isOpen: boolean;
  setOpen: (open: boolean) => void;
  setButtonDisabled: (disabled: boolean) => void;
}) => {
  const [submitting, setSubmitting] = useState(false);

  const merchant = useMerchant(useMerchantIdFromUrl()).data?.merchant;

  const merchantAmexFee = useMerchantStatus().data?.amex?.feePercentage;

  const { t } = useTranslation();

  const { control, handleSubmit } = useForm<FormInputSchema>();

  const onSubmit = (formData: FormInputSchema) => {
    setSubmitting(true);
    fetchFromBackend(`/merchants/${merchant?.id}/fees/set-amex-transaction-fee`, {
      method: 'POST',
      body: JSON.stringify(formData),
    })
      .then(response => {
        if (response.ok) {
          createToast(t('merchant.details.status.amex-fee.form.form.sent'), {
            timeout: 6000,
            type: 'success',
          });
          props.setOpen(false);
          props.setButtonDisabled(true);
        } else {
          createToast(t('unexpected-error'), {
            timeout: 6000,
            type: 'error',
          });
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Modal isOpen={props.isOpen} close={() => props.setOpen(false)} closeButton>
      <Heading size={'m'}>
        {t('merchant.details.status.amex-fee.form.title', { companyName: merchant?.companyName })}
      </Heading>
      <Form onSubmit={handleSubmit(data => onSubmit(data))}>
        <FlexDiv>
          <FlexLeftCol>
            <FormTextInput
              type={'number'}
              name="fee"
              control={control}
              label={t('merchant.details.status.amex-fee.form.fee')}
              required={true}
              defaultValue={merchantAmexFee?.toString()}
              disabled={merchantAmexFee !== null}
            />
          </FlexLeftCol>
        </FlexDiv>
        <FlexDiv>
          <Text weight={'light'}>{t('merchant.details.status.amex-fee.form.disclaimer')}</Text>
        </FlexDiv>
        <div style={{ float: 'right' }}>
          <Spacing top="2" />
          {!submitting && <Button type={'submit'} label={'Submit'} disabled={merchantAmexFee !== null} />}
          {submitting && <ClipLoader size={35} />}
          <Spacing top="2" />
        </div>
      </Form>
    </Modal>
  );
};
