import { Modal } from '../../../components/Modal';
import { Form } from '../fees/helper';
import { Button, Heading, Label, Spacing } from '@loomispay/vault';
import React, { useState } from 'react';
import { FlexDiv, FlexLeftCol, FlexRightCol } from '../../fees/helper';
import { FormTextInput } from '../../../components/TextInput';
import { SingleDatePicker } from '../../../components/DatePicker';
import { DatePickerWrapper } from '../../file-processing/FileProcessing.styles';
import { LabelWrapper } from '../../../components/invoices/styles';
import { useForm } from 'react-hook-form';
import ClipLoader from 'react-spinners/ClipLoader';
import { useMerchant } from '../merchantReporting-hooks';
import { useMerchantIdFromUrl } from '../merchant-hooks';
import { fetchFromBackend } from '../../../utils/fetch';
import { createToast } from 'vercel-toast';
import { useTranslation } from 'react-i18next';

export const SignerDetailsComponent = (props: { isOpen: boolean; setIsOpen: (value: boolean) => void }) => {
  const merchantId = useMerchantIdFromUrl();

  const { data } = useMerchant(merchantId);

  const [submitting, setSubmitting] = useState(false);

  const { t } = useTranslation();

  interface FormInputSchema {
    firstName: string;
    lastName: string;
    dateOfBirth: Date;
    personalNumber: string;
  }

  const { control, handleSubmit } = useForm<FormInputSchema>();

  const onSubmit = (data: FormInputSchema) => {
    // to avoid shifting to previous day because of UTC vs Stockholm time
    data.dateOfBirth.setHours(12);
    const request = {
      ...data,
      dateOfBirth: data.dateOfBirth.toISOString().substring(0, 10),
    };
    setSubmitting(true);
    fetchFromBackend(`/merchants/${merchantId}/signer-details`, {
      method: 'POST',
      body: JSON.stringify(request),
    })
      .then(response => {
        if (response.ok) {
          createToast(t('merchant.details.status.signer-details.form.sent'), {
            timeout: 6000,
            type: 'success',
          });
          props.setIsOpen(false);
        } else {
          createToast(t('unexpected-error'), {
            timeout: 6000,
            type: 'error',
          });
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Modal isOpen={props.isOpen} close={() => props.setIsOpen(false)} closeButton>
      <Heading size={'m'}>
        {t('merchant.details.status.signer-details.form.title', { companyName: data?.merchant.companyName })}
      </Heading>
      <Form onSubmit={handleSubmit(d => onSubmit(d))}>
        <FlexDiv>
          <FlexLeftCol>
            <FormTextInput
              name="firstName"
              control={control}
              label={t('merchant.details.status.signer-details.form.first-name')}
              required={true}
            />
          </FlexLeftCol>
          <FlexRightCol>
            <FormTextInput
              label={t('merchant.details.status.signer-details.form.last-name')}
              name="lastName"
              control={control}
              required={true}
            />
          </FlexRightCol>
        </FlexDiv>
        <FlexDiv>
          <FlexLeftCol>
            <FormTextInput
              name="personalNumber"
              control={control}
              label={t('merchant.details.status.signer-details.form.personal-number')}
              required={true}
            />
          </FlexLeftCol>
          <FlexRightCol>
            <LabelWrapper>
              <Label size={'s'}>{t('merchant.details.status.signer-details.form.date-of-birth')}</Label>
            </LabelWrapper>
            <DatePickerWrapper>
              <SingleDatePicker
                name="dateOfBirth"
                control={control}
                minDate={new Date(1900, 1)}
                maxDate={new Date()}
                required={true}
                monthYearPicker={true}
              />
            </DatePickerWrapper>
          </FlexRightCol>
        </FlexDiv>
        <div style={{ float: 'right' }}>
          <Spacing top="2" />
          {!submitting && <Button type={'submit'} label={'Submit'} />}
          {submitting && <ClipLoader size={35} />}
          <Spacing top="2" />
        </div>
      </Form>
    </Modal>
  );
};
