import { Button } from '@loomispay/vault';
import { FunctionComponent } from 'react';
import ReactModal from 'react-modal';

interface ModalProps {
  isOpen: boolean;
  close?: () => void;
  closeButton?: boolean;
  scrollable?: boolean;
}

export const Modal: FunctionComponent<ModalProps> = ({
  children,
  isOpen,
  close,
  closeButton = false,
  scrollable = false,
}) => {
  return (
    <ReactModal
      onRequestClose={close}
      style={{
        overlay: {
          background: 'rgba(18, 18, 18, 0.8)',
          overflowX: 'scroll',
          overflowY: 'scroll',
          maxHeight: '100%',
          zIndex: 99,
        },
        content: {
          background: 'white',
          maxWidth: '1000px',
          maxHeight: scrollable ? 'none' : 'fit-content',
          margin: 'auto',
          alignItems: 'center',
          borderRadius: 0,
        },
      }}
      isOpen={isOpen}
      ariaHideApp={false}
    >
      <>
        {closeButton && (
          <div style={{ float: 'right' }}>
            <Button icon={'close'} variant={'tertiary'} onClick={close} />
          </div>
        )}
        {children}
      </>
    </ReactModal>
  );
};
