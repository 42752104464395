import { Button, Display, Label, Text } from '@loomispay/vault';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'routes/fees/helper';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { fetchFromBackend } from '../../utils/fetch';
import { createToast } from 'vercel-toast';
import { SingleDatePicker } from '../../components/DatePicker';
import { DatePickerWrapper } from '../file-processing/FileProcessing.styles';
import { LabelWrapper } from '../../components/invoices/styles';
import { FormSelect } from '../../components/Select';

export const Wrapper = styled.div`
  width: 80%;
  padding-right: 1.5%;
  padding-bottom: 20px;
`;

export const HorizontalInputWrapper = styled.div`
  width: 15%;
  padding-right: 1.5%;
  padding-bottom: 20px;
  display: inline-table;
`;

interface VismaAccountsResyncInputSchema {
  date: Date;
  currency: string;
}

export const JournalingScreen = () => {
  const { t } = useTranslation();
  // Today minus 1 day in milliseconds
  const yesterdaysDate = new Date(Date.now() - 1000 * 60 * 60 * 24);

  const { control, handleSubmit } = useForm<VismaAccountsResyncInputSchema>({
    defaultValues: {
      date: yesterdaysDate,
      currency: 'SEK',
    },
  });

  const onSubmit = ({ date, currency }: VismaAccountsResyncInputSchema) => {
    fetchFromBackend(
      `/journaling/visma/resync-daily-totals?date=${date.toISOString().slice(0, 10)}&currency=${currency}`,
      {
        method: 'POST',
      }
    ).then(response => {
      if (response.ok) {
        createToast(t('journaling.visma.resync.submit.success'), {
          timeout: 6000,
          type: 'success',
        });
      } else {
        createToast(t('unexpected-error'), {
          timeout: 6000,
          type: 'error',
        });
      }
    });
  };

  return (
    <>
      <Wrapper>
        <Display size="s">{t('journaling.title')}</Display>
        <Text size="xl" weight="semiBold">
          {t('journaling.visma.resync.title')}
        </Text>
      </Wrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <HorizontalInputWrapper>
          <DatePickerWrapper>
            <LabelWrapper>
              <Label size={'s'}>{`${t('journaling.visma.resync.date')}*`}</Label>
            </LabelWrapper>
            <SingleDatePicker
              name={'date'}
              control={control}
              minDate={new Date(2021, 1)}
              maxDate={yesterdaysDate}
              required={true}
              monthYearPicker={false}
              defaultValue={yesterdaysDate}
            />
          </DatePickerWrapper>
        </HorizontalInputWrapper>
        <HorizontalInputWrapper>
          <FormSelect
            label={`${t('journaling.visma.resync.currency')}*`}
            name={'currency'}
            options={Object.values(['SEK', 'DKK', 'NOK', 'EUR']).map(it => ({ value: it, label: it }))}
            control={control}
            required={true}
          />
        </HorizontalInputWrapper>
        <Button type={'submit'}>{t('journaling.visma.resync.submit')}</Button>
      </Form>
    </>
  );
};
