import { CardCustomerType, CardNotPresentRule, CardScheme, CardType, Region } from 'api/types';
import { DefaultTransactionFeeRule, TransactionFeeRule } from 'routes/fees/DataTable';
import { z, ZodIssueCode } from 'zod';

import { Currency } from 'components/invoices/InvoicesTable';
import { TFunction } from 'react-i18next';
import { ReactComponent as Mastercard } from 'routes/fees/Icons/Mastercard.svg';
import { ReactComponent as Visa } from 'routes/fees/Icons/Visa.svg';
import styled from 'styled-components';
import { toTitleCase } from 'utils/formatters/toTitleCase';

export interface CardNotPresentTransactionFeesAssignedRequestBody {
  defaultTransactionFee: CardNotPresentTransactionFees;
  criteriaBasedTransactionFeeRules: CardNotPresentTransactionFeesAndCriteria[];
}

export interface CardNotPresentTransactionFeesAndCriteria {
  fees: CardNotPresentTransactionFees;
  criteria: CardNotPresentTransactionFeeCriteria;
}

export interface CardNotPresentTransactionFees {
  percentage: string;
  fixedFeeAmount: string;
  currency: Currency;
}

interface CardNotPresentTransactionFeeCriteria {
  region: Region;
  cardCustomerType: CardCustomerType;
  cardScheme: CardScheme;
  cardType: CardType;
}

export const getMerchantFeesTableColumns = (currency: Currency, t: TFunction<'translation', undefined>) => {
  return [
    {
      Header: t('fees.table.card-scheme'),
      accessor: 'cardScheme',
      width: 140,
      Cell: (props: { value: string }) => {
        if (props.value != null)
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {props.value === 'MASTERCARD' ? (
                <Mastercard style={{ paddingRight: '8px' }} />
              ) : props.value === 'VISA' ? (
                <Visa style={{ paddingRight: '8px' }} />
              ) : null}
              {toTitleCase(props.value)}
            </div>
          );
        else return null;
      },
    },
    {
      Header: t('fees.table.region'),
      accessor: 'region',
      width: 120,
      Cell: (props: { value: string }) => {
        return props.value != null ? <div>{toTitleCase(props.value)}</div> : null;
      },
    },

    {
      Header: t('fees.table.card-type'),
      accessor: 'cardType',
      width: 100,
      Cell: (props: { value: string }) => {
        return props.value != null ? <div>{toTitleCase(props.value)}</div> : null;
      },
    },
    {
      Header: t('fees.table.card-customer-type'),
      accessor: 'cardCustomerType',
      Cell: (props: { value: string }) => {
        return props.value != null ? <div>{toTitleCase(props.value)}</div> : null;
      },
    },
    {
      Header: t('merchant.fees.percentage-fee'),
      accessor: 'percentageFee',
      width: 180,
    },
    {
      Header: `${t('merchant.fees.fixed-fee-amount', { currency: currency })}`,
      accessor: 'fixedFee',
      width: 180,
    },
    {
      accessor: 'missing',
      width: 50,
    },
  ];
};

export const buildValidationSchema = (
  countryDefaultRange: DefaultTransactionFeeRule,
  countryRulesRanges: TransactionFeeRule[],
  t: TFunction<'translation', undefined>
) => {
  const validationSchema = z.object({
    defaultRule: z.object({
      percentageFee: z.string().superRefine((val, ctx) => {
        if (Number(val) > Number(countryDefaultRange.percentage.max)) {
          ctx.addIssue({
            code: ZodIssueCode.custom,
            message: t('merchant.fees.validation.fee-too-high'),
          });
        }
        if (Number(val) < Number(countryDefaultRange.percentage.min)) {
          ctx.addIssue({
            code: ZodIssueCode.custom,
            message: t('merchant.fees.validation.fee-too-low'),
          });
        }
        if (Number(val) > 100) {
          ctx.addIssue({
            code: ZodIssueCode.custom,
            message: t('merchant.fees.validation.percentage-fee-exceed'),
          });
        }
        if (Number(val) < 0) {
          ctx.addIssue({
            code: ZodIssueCode.custom,
            message: t('merchant.fees.validation.fee-min-zero'),
          });
        }
      }),
      fixedFeeAmount: z.string().superRefine((val, ctx) => {
        if (Number(val) > Number(countryDefaultRange.fixedFeeAmount?.max)) {
          ctx.addIssue({
            code: ZodIssueCode.custom,
            message: t('merchant.fees.validation.fee-too-high'),
          });
        }
        if (Number(val) < Number(countryDefaultRange.fixedFeeAmount?.min)) {
          ctx.addIssue({
            code: ZodIssueCode.custom,
            message: t('merchant.fees.validation.fee-too-low'),
          });
        }
        if (Number(val) < 0) {
          ctx.addIssue({
            code: ZodIssueCode.custom,
            message: t('merchant.fees.validation.fee-min-zero'),
          });
        }
      }),
    }),
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let updatedSchema: any = validationSchema;
  countryRulesRanges.forEach((rule: TransactionFeeRule) => {
    const schemaWithAddedRule = updatedSchema.extend({
      [rule.id]: z.object({
        [`percentageFee`]: z.string().superRefine((val, ctx) => {
          if (Number(val) > Number(rule.percentage.max)) {
            ctx.addIssue({
              code: ZodIssueCode.custom,
              message: t('merchant.fees.validation.fee-too-high'),
            });
          }
          if (Number(val) < Number(rule.percentage.min)) {
            ctx.addIssue({
              code: ZodIssueCode.custom,
              message: t('merchant.fees.validation.fee-too-low'),
            });
          }
          if (Number(val) > 100) {
            ctx.addIssue({
              code: ZodIssueCode.custom,
              message: t('merchant.fees.validation.percentage-fee-exceed'),
            });
          }
          if (Number(val) < 0) {
            ctx.addIssue({
              code: ZodIssueCode.custom,
              message: t('merchant.fees.validation.fee-min-zero'),
            });
          }
        }),
        [`fixedFeeAmount`]: z.string().superRefine((val, ctx) => {
          if (Number(val) > Number(rule.fixedFeeAmount?.max)) {
            ctx.addIssue({
              code: ZodIssueCode.custom,
              message: t('merchant.fees.validation.fee-too-high'),
            });
          }
          if (Number(val) < Number(rule.fixedFeeAmount?.min)) {
            ctx.addIssue({
              code: ZodIssueCode.custom,
              message: t('merchant.fees.validation.fee-too-low'),
            });
          }
          if (Number(val) < 0) {
            ctx.addIssue({
              code: ZodIssueCode.custom,
              message: t('merchant.fees.validation.fee-negative'),
            });
          }
        }),
      }),
    });
    updatedSchema = schemaWithAddedRule;
  });
  return updatedSchema;
};

export const findMatchingMerchantRule = (
  countryRuleRange: TransactionFeeRule,
  merchantAssignedRules: CardNotPresentRule[]
) => {
  return merchantAssignedRules.find(
    rule =>
      rule.criteria.cardCustomerType === countryRuleRange.cardCustomerType &&
      rule.criteria.cardScheme === countryRuleRange.cardScheme &&
      rule.criteria.cardType === countryRuleRange.cardType &&
      rule.criteria.region === countryRuleRange.region
  );
};

export const getRulePercentageValue = (
  countryRuleRange: TransactionFeeRule,
  merchantAssignedRules: CardNotPresentRule[]
) => {
  const merchantRule = findMatchingMerchantRule(countryRuleRange, merchantAssignedRules);
  if (merchantRule) {
    return merchantRule.fees.feePercentage.toString();
  } else {
    return countryRuleRange.percentage.default;
  }
};

export const getRuleFixedFeeAmount = (
  countryRuleRange: TransactionFeeRule,
  merchantAssignedRules: CardNotPresentRule[]
) => {
  const merchantRule = findMatchingMerchantRule(countryRuleRange, merchantAssignedRules);
  if (merchantRule) {
    return merchantRule.fees.fixedFeeAmount.toString();
  } else {
    return countryRuleRange.fixedFeeAmount?.default;
  }
};

export const Form = styled.form`
  flex-direction: column;
`;

export const ErrorLabel = styled.p`
  font-size: 10px;
  color: red;
  white-space: pre-wrap;
  line-height: initial;
`;

export const SaveButtonContainer = styled.div`
  margin-top: 1rem;
  float: right;
  > button {
    width: 120px;
  }
`;

export const Tooltip = styled.span`
  position: relative;

  &:before {
    content: attr(data-hover);
    visibility: hidden;
    opacity: 0;
    background-color: lightgray;
    text-align: center;
    border-radius: 5px;
    padding: 3px;
    transition: opacity 1s ease-in-out;
    font-family: 'Roobert';
    position: absolute;
    z-index: 1;
    left: 0;
    top: 110%;
    font-size: 10px;
  }

  &:hover:before {
    opacity: 1;
    visibility: visible;
  }
`;
