import { Display, Heading, Paragraph, Spacing, Table, TextInput, useInput } from '@loomispay/vault';
import { fetchByUrl } from 'api';
import InfiniteScroll from 'components/InfiniteScroll';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { SEARCH_PATH } from 'routes/AppRouter';
import { SearchMerchantsResponse } from 'routes/merchants/merchantReporting-hooks';
import styled from 'styled-components';
import useSWRInfinite from 'swr/infinite';
import useDebounce from 'utils/hooks/useDebounce';
import { useQuery } from 'utils/hooks/useQuery';
import { ElasticMerchant, StatusFilter } from '../../api/types';
import { SupportBackofficeSelect } from '../../components/Select';
import { formatISODate } from '../../utils/formatters/dateFormatters';
import { toTitleCase } from '../../utils/formatters/toTitleCase';

// Search debounce delay in ms
const SEARCH_DEBOUNCE_DELAY = 250;
const SEARCH_RESPONSE_LIMIT = 10;

const SearchInfoWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacings['16']};
  width: 300px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`;

type SearchInfoProps = {
  title: string;
  body: string;
};
const SearchInfo = ({ title, body }: SearchInfoProps) => {
  return (
    <SearchInfoWrapper>
      <Heading size={'m'} noGutter>
        {title}
      </Heading>
      <Spacing top={'1'}>
        <Paragraph size={'s'}>{body}</Paragraph>
      </Spacing>
    </SearchInfoWrapper>
  );
};

const TableCell = (props: { value: string }) => {
  if (props.value) {
    return <div>{props.value}</div>;
  }

  return null;
};

const useMerchantSearch = (searchValue: string, status: string) => {
  const getKey = (pageIndex: number | null, lastResponse: SearchMerchantsResponse | null) => {
    if (!searchValue) return null;
    const trimmedSearchValue = searchValue.trim();
    const pageNumber = pageIndex === 0 || lastResponse === null ? 1 : lastResponse.pagination.pageNumber + 1;
    return `/merchants/search?query=${trimmedSearchValue}&pageNumber=${pageNumber}&pageSize=${SEARCH_RESPONSE_LIMIT}&status=${status}`;
  };

  const { data, size, setSize, isValidating } = useSWRInfinite<SearchMerchantsResponse>(getKey, fetchByUrl);

  const toTableData = (response: SearchMerchantsResponse) => response.merchants.map(toMerchantsTableData);
  const buildTableData = () => data?.flatMap(toTableData) ?? [];
  const merchants = useMemo<MerchantsTableData[]>(buildTableData, [data]);

  let hasMore = true;
  if (data !== undefined) {
    const { pageSize, pageNumber, totalCount } = data[data.length - 1].pagination;
    hasMore = pageNumber * pageSize < totalCount;
  }

  return {
    numberOfPages: size,
    hasNextPage: hasMore,
    noResultsFound: merchants.length === 0,
    merchants,
    setPageSize: setSize,
    isLoading: isValidating,
  };
};

const SearchInputWrapper = styled.div`
  width: 600px;
  display: flex;
  gap: 20px;
`;

export const SESSION_STORAGE_QUERY_KEY = 'search-query';

export const SESSION_STORAGE_STATUS_KEY = 'search-status';

const getQueryFromSession = (): string | null => {
  try {
    return sessionStorage.getItem(SESSION_STORAGE_QUERY_KEY);
  } catch (e) {
    console.error(e);
    return null;
  }
};

const storeQueryInSession = (query: string) => {
  try {
    sessionStorage.setItem(SESSION_STORAGE_QUERY_KEY, query);
  } catch (e) {
    console.error(e);
  }
};

const getStatusFromSession = (): string | null => {
  try {
    return sessionStorage.getItem(SESSION_STORAGE_STATUS_KEY);
  } catch (e) {
    console.error(e);
    return null;
  }
};

const storeStatusInSession = (status: string) => {
  try {
    sessionStorage.setItem(SESSION_STORAGE_STATUS_KEY, status);
  } catch (e) {
    console.error(e);
  }
};

export const SearchScreen = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const query = useQuery();

  const DateCell = (props: { value: Date | null }) => {
    return props.value != null ? <div>{formatISODate(props.value.toString())}</div> : null;
  };

  const columns = useMemo(
    () => [
      {
        Header: t('search.company-name'),
        accessor: 'companyName',
        Cell: TableCell,
      },
      {
        Header: t('search.id'),
        accessor: 'id',
        Cell: TableCell,
      },
      {
        Header: t('search.organisation-number'),
        accessor: 'registrationIdentifier',
        Cell: TableCell,
      },
      {
        Header: t('search.status'),
        accessor: 'status',
        Cell: TableCell,
      },
      {
        Header: t('search.contract-signed-on'),
        accessor: 'contractSignedAt',
        Cell: DateCell,
      },
    ],
    [t]
  );
  const { value: searchValue, bind: searchBinding } = useInput(query.get('q') || getQueryFromSession() || '');
  const debounceQuerySearch = useDebounce(searchValue, SEARCH_DEBOUNCE_DELAY);
  const [statusValue, setStatusValue] = useState(query.get('status') || getStatusFromSession() || StatusFilter.ACTIVE);
  const debounceStatusSearch = useDebounce(statusValue, SEARCH_DEBOUNCE_DELAY);

  useEffect(() => {
    history.push({
      pathname: SEARCH_PATH,
      search: '?q=' + debounceQuerySearch + '&status=' + debounceStatusSearch,
    });
    storeQueryInSession(debounceQuerySearch);
    storeStatusInSession(debounceStatusSearch);
  }, [debounceQuerySearch, debounceStatusSearch, history]);

  const { numberOfPages, hasNextPage, setPageSize, isLoading, merchants, noResultsFound } = useMerchantSearch(
    searchValue,
    statusValue
  );

  const loadMore = async (page: number) => {
    if (hasNextPage) setPageSize(page + 1);
  };

  return (
    <div>
      <Display size="s">{t('search.title')}</Display>
      <SearchInputWrapper>
        <TextInput
          label={t('search')}
          name={'search'}
          placeholder={t('search.placeholder')}
          id={'search'}
          {...searchBinding}
          style={{ width: '400px' }}
        />
        <SupportBackofficeSelect
          options={Object.values(StatusFilter).map(it => ({
            value: it,
            label: toTitleCase(it),
          }))}
          label={t('search.filter.status')}
          defaultValue={{ value: statusValue, label: toTitleCase(statusValue) }}
          onSelectionChange={status => setStatusValue(status as string)}
        />
      </SearchInputWrapper>
      <Spacing top={'3'} bottom={'3'}>
        <InfiniteScroll
          loading={isLoading}
          hasNextPage={hasNextPage}
          nextPage={numberOfPages + 1}
          pageSize={SEARCH_RESPONSE_LIMIT}
          loadMore={loadMore}
        >
          <>
            <Table
              data={merchants || []}
              columns={columns}
              getRowProps={row => ({
                onClick: () => {
                  history.push(`/merchant/${row.original.id}`);
                },
              })}
            />
          </>
        </InfiniteScroll>
      </Spacing>
      {searchValue === '' && !isLoading && <SearchInfo title={t('search.info.title')} body={t('search.info.body')} />}
      {searchValue !== '' && !isLoading && noResultsFound && (
        <SearchInfo title={t('search.info.no-result.title')} body={t('search.info.no-result.body')} />
      )}
    </div>
  );
};

interface MerchantsTableData extends Record<string, unknown> {
  companyName: string | null;
  id: string | null;
  registrationIdentifier: string | null;
  contractSignedAt: Date | null;
  status: string | null;
}

const toMerchantsTableData = (doc: ElasticMerchant): MerchantsTableData => ({
  companyName: doc.merchant.companyName,
  id: doc.merchant.id,
  registrationIdentifier: doc.merchant.registrationIdentifier,
  contractSignedAt: doc.onboarding?.contractSignedAt ?? null,
  status: doc.merchant.status?.code ? toTitleCase(doc.merchant.status?.code) : null,
});
