import { Spacing, Tabs } from '@loomispay/vault';
import { EnabledFeature, useConfiguration } from 'configuration';
import { Permission, useUserPermissions } from 'permissions';
import { generatePath, matchPath, Route, RouteProps, Switch, useLocation } from 'react-router-dom';

import { Tab } from '@loomispay/vault/build/components/Tabs/types';
import InvoicesSearch from 'components/invoices/InvoicesSearch';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { AuthorizedRoute } from '../AppRouter';
import { MerchantDetails } from './details/MerchantDetails';
import MerchantFeesTab from './fees/MerchantFeesTab';
import { useMerchantIdFromUrl } from './merchant-hooks';
import { TabsWrapper } from './MerchantScreen.styles';
import { MerchantScreenHeader } from './MerchantScreenHeader';
import MerchantPayoutScreen from './payout/MerchantPayoutScreen';
import { MerchantReportingDataTab } from './reporting-data/MerchantReportingDataTab';
import { MerchantBoardingTab } from './status/MerchantBoardingTab';
import { MerchantStoresTab } from './stores/MerchantStoresTab';
import { MerchantTerminalsTab } from './terminals/MerchantTerminalsTab';
import { MerchantTransactionsTab } from './transactions/MerchantTransactionsTab';

const MERCHANT: RouteProps = {
  path: '/merchant/:id',
  exact: true,
};

const MERCHANT_PAYOUTS: RouteProps = {
  path: '/merchant/:id/payouts',
  exact: true,
};

const MERCHANT_STATUS: RouteProps = {
  path: '/merchant/:id/status',
  exact: true,
};

const MERCHANT_INVOICES: RouteProps = {
  path: '/merchant/:id/invoices',
  exact: true,
};

const MERCHANT_TERMINALS: RouteProps = {
  path: '/merchant/:id/terminals',
  exact: true,
};

const MERCHANT_STORES: RouteProps = {
  path: '/merchant/:id/stores',
  exact: true,
};

const MERCHANT_TRANSACTIONS: RouteProps = {
  path: '/merchant/:id/transactions',
  exact: true,
};

const MERCHANT_FEES: RouteProps = {
  path: '/merchant/:id/fees',
  exact: true,
};

const MERCHANT_REPORTING_DATA: RouteProps = {
  path: '/merchant/:id/reporting-data',
  exact: true,
};

const resolvePath = (props: RouteProps, id: string): string => generatePath(String(props.path), { id: id });

const constructTabs = (id: string, permissions: Permission[], enabledFeatures: EnabledFeature[], t: TFunction) => {
  const tabs = [
    {
      label: t('merchant.tabs.details'),
      key: 'details',
      to: resolvePath(MERCHANT, id),
    },
    {
      label: t('merchant.tabs.status'),
      key: 'status',
      to: resolvePath(MERCHANT_STATUS, id),
    },
    {
      label: t('merchant.tabs.terminals'),
      key: 'terminals',
      to: resolvePath(MERCHANT_TERMINALS, id),
    },
    {
      label: t('merchant.tabs.stores'),
      key: 'stores',
      to: resolvePath(MERCHANT_STORES, id),
    },
    {
      label: t('merchant.tabs.transactions'),
      key: 'transactions',
      to: resolvePath(MERCHANT_TRANSACTIONS, id),
    },
    {
      label: t('merchant.tabs.payouts'),
      key: 'payouts',
      to: resolvePath(MERCHANT_PAYOUTS, id),
    },
  ];

  if (permissions.includes(Permission.READ_INVOICE)) {
    tabs.push({
      label: t('merchant.tabs.invoices'),
      key: 'invoices',
      to: resolvePath(MERCHANT_INVOICES, id),
    });
  }

  if (permissions.includes(Permission.WRITE_FEES)) {
    tabs.push({
      label: t('merchant.tabs.fees'),
      key: 'fees',
      to: resolvePath(MERCHANT_FEES, id),
    });
  }

  if (
    enabledFeatures.includes(EnabledFeature.EDIT_MERCHANT_REPORTING_DETAILS) &&
    permissions.includes(Permission.WRITE_MERCHANT_REPORTING_DATA)
  ) {
    tabs.push({
      label: 'Reporting data',
      key: 'reporting-data',
      to: resolvePath(MERCHANT_REPORTING_DATA, id),
    });
  }

  return tabs;
};

export const MerchantScreen = () => {
  const { pathname } = useLocation<{ from?: string }>();
  const { t } = useTranslation();
  const merchantId = useMerchantIdFromUrl();
  const { permissions } = useUserPermissions();
  const enabledFeatures = useConfiguration().data?.enabledFeatures ?? [];

  const tabs: Tab[] = constructTabs(merchantId, permissions, enabledFeatures, t);
  const selectedTab = tabs.find(tab => matchPath(tab.to ?? '', { path: pathname, exact: false }))?.key;

  return (
    <div>
      <MerchantScreenHeader />
      <Spacing bottom={'4'}>
        <TabsWrapper>{selectedTab && <Tabs tabs={tabs} initialActiveTab={selectedTab} />}</TabsWrapper>
      </Spacing>
      <Switch>
        <Route {...MERCHANT}>
          <MerchantDetails />
        </Route>
        <Route {...MERCHANT_STATUS}>
          <MerchantBoardingTab />
        </Route>
        <Route {...MERCHANT_PAYOUTS}>
          <MerchantPayoutScreen />
        </Route>
        <Route {...MERCHANT_STORES}>
          <MerchantStoresTab />
        </Route>
        <AuthorizedRoute exact path="/merchant/:id/invoices" requiresAnyOf={[Permission.READ_INVOICE]}>
          <InvoicesSearch merchantId={merchantId} />
        </AuthorizedRoute>
        <AuthorizedRoute exact path="/merchant/:id/terminals" requiresAnyOf={[Permission.READ_TERMINAL]}>
          <MerchantTerminalsTab />
        </AuthorizedRoute>
        <Route {...MERCHANT_TRANSACTIONS}>
          <MerchantTransactionsTab />
        </Route>
        <AuthorizedRoute exact path="/merchant/:id/fees" requiresAnyOf={[Permission.WRITE_FEES]}>
          <MerchantFeesTab />
        </AuthorizedRoute>
        <AuthorizedRoute
          exact
          path="/merchant/:id/reporting-data"
          requiresAnyOf={[Permission.WRITE_MERCHANT_REPORTING_DATA]}
        >
          <MerchantReportingDataTab />
        </AuthorizedRoute>
      </Switch>
    </div>
  );
};
