import { Button, Heading, Label, Spacing, Text } from '@loomispay/vault';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchFromBackend } from 'utils/fetch';
import { createToast } from 'vercel-toast';
import { Currency } from '../../components/invoices/InvoicesTable';
import { z, ZodIssueCode } from 'zod';
import { JournalAccount } from '../../api/types';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { CloseButton, FlexDiv, FlexLeftCol, FlexRightCol, Form, SubHeading } from '../fees/helper';
import { SingleDatePicker } from '../../components/DatePicker';
import { LabelWrapper } from '../../components/invoices/styles';
import { ModalSectionHeader, ModalSectionSubHeader } from './helper';
import { DatePickerWrapper } from '../file-processing/FileProcessing.styles';
import { Permission, useUserPermissions } from '../../permissions';
import { Modal } from '../../components/Modal';
import { FormSelect } from '../../components/Select';
import { FormTextInput } from '../../components/TextInput';

interface BookingCreationRequest {
  amount: number;
  currency: undefined | Currency;
  debitAccount: undefined | JournalAccount;
  creditAccount: undefined | JournalAccount;
  triggerEventId: undefined | string;
  financialDate: Date;
  transactionId: undefined | string;
  merchantId: undefined | string;
  payableBatchId: undefined | string;
  acquirerPaymentId: undefined | string;
  bookingDescription: undefined | string;
}

interface Error {
  code: string;
  message: string;
}

interface BookingCreationErrorResponse {
  errors: Error[];
}

export const CustomReconciliationComponent = () => {
  const { t } = useTranslation();
  const { permissions } = useUserPermissions();

  const [loadModal, setLoadModal] = useState<boolean>(false);

  return (
    <>
      <Button
        iconPosition={'left'}
        size={'s'}
        label={t('reconciliation.make-custom-reconciliation')}
        disabled={!permissions.includes(Permission.WRITE_FINANCE)}
        onClick={() => {
          setLoadModal(true);
        }}
      />

      <Modal isOpen={loadModal} close={() => setLoadModal(false)}>
        <CustomReconciliationModal setLoadModal={setLoadModal} />
      </Modal>
    </>
  );
};

const CustomReconciliationModal = (props: { setLoadModal: (value: boolean) => void }) => {
  const { t } = useTranslation();

  const todayFinancialDate = new Date();
  todayFinancialDate.setHours(12, 0, 0, 0);

  const maxFiveDecimalsRegex = new RegExp(/^\d+(\.\d{0,5})?$/);
  const reportingDataNumberValidation = z
    .string()
    .regex(maxFiveDecimalsRegex, t('reconciliation.amount.validation.decimals'))
    .min(1);

  const FormInputSchema = z
    .object({
      amount: reportingDataNumberValidation,
      currency: z.nativeEnum(Currency),
      debitAccount: z.nativeEnum(JournalAccount),
      creditAccount: z.nativeEnum(JournalAccount),
      triggerEventId: z.string().optional(),
      financialDate: z.date(),
      transactionId: z.string().max(32).optional(),
      merchantId: z.string().optional(),
      payableBatchId: z.string().optional(),
      acquirerPaymentId: z.string().max(18).optional(),
      bookingDescription: z.string().max(300).optional(),
    })
    .superRefine((val, ctx) => {
      if (val.amount === undefined) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          message: 'Please enter amount',
          path: ['amount'],
        });
      }

      if (val.merchantId !== undefined) {
        if (
          !/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/.test(val.merchantId) &&
          val.merchantId.length !== 0
        )
          ctx.addIssue({
            code: ZodIssueCode.custom,
            message: 'Invalid format. Must be a UUID',
            path: ['merchantId'],
          });
      }

      if (val.payableBatchId !== undefined) {
        if (
          !/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/.test(val.payableBatchId) &&
          val.payableBatchId.length !== 0
        )
          ctx.addIssue({
            code: ZodIssueCode.custom,
            message: 'Invalid format. Must be a UUID',
            path: ['payableBatchId'],
          });
      }

      if (val.debitAccount === undefined) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          message: 'Please select a debit account',
          path: ['debitAccount'],
        });
      }

      if (val.creditAccount === undefined) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          message: 'Please select a credit account',
          path: ['creditAccount'],
        });
      }

      if (val.debitAccount === val.creditAccount) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          message: 'Debit and credit accounts must differ',
          path: ['debitAccount'],
        });
      }

      if (val.financialDate > todayFinancialDate) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          message: 'Financial date has to be in the past',
          path: ['financialDate'],
        });
      }
    });

  type FormInputSchemaType = z.infer<typeof FormInputSchema>;

  const { control, handleSubmit } = useForm<FormInputSchemaType>({
    defaultValues: {
      amount: undefined,
      currency: undefined,
      debitAccount: undefined,
      creditAccount: undefined,
      triggerEventId: undefined,
      financialDate: todayFinancialDate,
    },
    resolver: zodResolver(FormInputSchema),
  });

  const journalAccountOptions = Object.values(JournalAccount).map(it => ({
    value: it,
    label: it.toString(),
  }));

  const currencyOptions = Object.values(Currency).map(it => ({
    value: it,
    label: it.toString(),
  }));

  const maxFinancialDate = new Date();
  const minFinancialDate = new Date();
  minFinancialDate.setMonth(maxFinancialDate.getMonth() - 2);

  const onSubmit = async (data: FormInputSchemaType) => {
    const requestBody: BookingCreationRequest = {
      amount: Number(data.amount),
      currency: data.currency,
      debitAccount: data.debitAccount,
      creditAccount: data.creditAccount,
      triggerEventId: data.triggerEventId,
      financialDate: data.financialDate,
      transactionId: data.transactionId,
      merchantId: data.merchantId,
      payableBatchId: data.payableBatchId,
      acquirerPaymentId: data.acquirerPaymentId,
      bookingDescription: data.bookingDescription,
    };

    const response = await fetchFromBackend('/reconciliation/bookings/custom-reconciliation', {
      method: 'POST',
      body: JSON.stringify(requestBody),
    });

    if (response.ok) {
      createToast(t('reconciliation.custom-reconciliation.success'), {
        timeout: 6000,
        type: 'success',
      });
    } else if (response.status === 400) {
      const responseBody: BookingCreationErrorResponse = await response.json();
      createToast(responseBody.errors[0].message, {
        timeout: 6000,
        type: 'error',
      });
    } else {
      createToast(t('unexpected-error'), {
        timeout: 6000,
        type: 'error',
      });
    }
  };

  return (
    <>
      <CloseButton>
        <Button
          icon={'close'}
          variant={'tertiary'}
          onClick={() => {
            props.setLoadModal(false);
          }}
        />
      </CloseButton>

      <Heading size={'m'}>{t('reconciliation.make-custom-reconciliation')}</Heading>
      <SubHeading>
        <Text>{t('reconciliation.make-custom-reconciliation-desc')}</Text>
      </SubHeading>

      <Form onSubmit={handleSubmit(x => onSubmit(x))}>
        <FlexDiv>
          <FlexLeftCol>
            <FormTextInput
              name="amount"
              control={control}
              label={t('reconciliation.amount') + '*'}
              placeholder={'e.g. 1.99'}
              required={true}
            />
          </FlexLeftCol>
          <FlexRightCol>
            <FormSelect
              label={t('reconciliation.currency') + '*'}
              name={'currency'}
              options={currencyOptions}
              control={control}
              required={true}
            />
          </FlexRightCol>
        </FlexDiv>
        <FlexDiv>
          <FlexLeftCol>
            <FormSelect
              label={t('reconciliation.debit-account') + '*'}
              name={'debitAccount'}
              options={journalAccountOptions}
              control={control}
              required={true}
            />
          </FlexLeftCol>
          <FlexRightCol>
            <FormSelect
              label={t('reconciliation.credit-account') + '*'}
              name={'creditAccount'}
              options={journalAccountOptions}
              control={control}
              required={true}
            />
          </FlexRightCol>
        </FlexDiv>
        <FlexDiv>
          <FlexLeftCol>
            <FormTextInput
              name="triggerEventId"
              control={control}
              label={t('reconciliation.trigger-event-id')}
              placeholder={'e.g. FK12VC02RK1B000000373-DK4630000013232288-318-1'}
            />
            <Spacing top="1" />
            <Text size="s">{t('reconciliation.make-custom-trigger-event-id-desc')}</Text>
          </FlexLeftCol>
          <FlexRightCol>
            <LabelWrapper>
              <Label size={'s'}>{t('reconciliation.financial-date') + '*'}</Label>
            </LabelWrapper>
            <DatePickerWrapper>
              <SingleDatePicker
                name={'financialDate'}
                control={control}
                minDate={minFinancialDate}
                maxDate={maxFinancialDate}
                defaultValue={todayFinancialDate}
                required={true}
              />
            </DatePickerWrapper>
            <Spacing top="1" />
            <Text size="s">{t('reconciliation.make-custom-trigger-financial-date-desc')}</Text>
          </FlexRightCol>
        </FlexDiv>
        <div>
          <ModalSectionHeader>{t('reconciliation.booking-metadata')}</ModalSectionHeader>
          <ModalSectionSubHeader>{t('reconciliation.booking-metadata-description')}</ModalSectionSubHeader>
        </div>
        <Spacing top="4" />
        <FlexDiv>
          <FlexLeftCol>
            <FormTextInput
              name="transactionId"
              control={control}
              label={t('reconciliation.transaction-id')}
              placeholder={'e.g. 85d1698e2bf94cf580c98d0eefd463d0'}
            />
          </FlexLeftCol>
          <FlexRightCol>
            <FormTextInput
              name="merchantId"
              control={control}
              label={t('reconciliation.merchant-id')}
              placeholder={'e.g. 3f0fd2b4-4786-44f4-8d09-949fc6641536'}
            />
          </FlexRightCol>
        </FlexDiv>
        <FlexDiv>
          <FlexLeftCol>
            <FormTextInput
              name="payableBatchId"
              control={control}
              label={t('reconciliation.payable-batch-id')}
              placeholder={'e.g. 3f0fd2b4-4786-44f4-8d09-949fc6641536'}
            />
          </FlexLeftCol>
          <FlexRightCol>
            <FormTextInput
              name="acquirerPaymentId"
              control={control}
              label={t('reconciliation.acquirer-payment-id')}
              placeholder={'e.g. 18342748610'}
            />
          </FlexRightCol>
        </FlexDiv>
        <FlexDiv>
          <FormTextInput name="bookingDescription" control={control} label={t('reconciliation.booking-description')} />
          <Text size="s">
            <Spacing top="1" />
            {t('reconciliation.booking-description-desc')}
          </Text>
        </FlexDiv>

        <div style={{ float: 'right' }}>
          <Spacing top="2" />
          <Button type={'submit'} label={t('reconciliation.book')} />
          <Spacing top="4" />
        </div>
      </Form>
    </>
  );
};
