import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Display, Label } from '@loomispay/vault';
import { FormTextInput } from 'components/TextInput';
import { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { createToast } from 'vercel-toast';
import { z, ZodIssueCode } from 'zod';
import { fetchFromBackend } from '../../utils/fetch';
import { FormDatePicker } from '../DatePicker';
import { FormSelect } from '../Select';
import {
  Currency,
  currencyToCountry,
  Invoice,
  InvoicesTable,
  PaymentStatus,
  paymentStatusLabel,
} from './InvoicesTable';
import { FlexDiv, FlexLeftCol, LabelWrapper } from './styles';

const dateRangeSchema = z.object({
  start: z.date().optional(),
  end: z.date().optional(),
});

const formSchema = z
  .object({
    currency: z.nativeEnum(Currency).optional(),
    paymentStatus: z.string().optional(),
    periodDate: dateRangeSchema,
    invoiceDate: dateRangeSchema,
    dueDate: dateRangeSchema,
    fullyPaidDate: dateRangeSchema,
    serialNumber: z.string().optional(),
    merchantId: z.string().optional(),
  })
  .superRefine((val, ctx) => {
    if (val.serialNumber !== undefined) {
      if (!/^[A-Z0-9-]{14}$/.test(val.serialNumber) && val.serialNumber.length !== 0)
        ctx.addIssue({
          code: ZodIssueCode.custom,
          message: 'Invalid',
          path: ['serialNumber'],
        });
    }
    if (val.merchantId !== undefined) {
      if (
        !/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/.test(val.merchantId) &&
        val.merchantId.length !== 0
      )
        ctx.addIssue({
          code: ZodIssueCode.custom,
          message: 'Invalid UUID',
          path: ['merchantId'],
        });
    }
  });

type FormInputSchema = z.infer<typeof formSchema>;

const filterFields = (obj: { [key: string]: string | undefined }) => {
  return Object.keys(obj).reduce((acc, key) => {
    const _acc: { [key: string]: string | undefined } = acc;
    if (obj[key] !== undefined && obj[key] !== '') _acc[key] = obj[key];
    return _acc;
  }, {});
};

const constructUrl = (formData: FormInputSchema): string => {
  const optionalQueryParams = {
    currency: formData.currency,
    paymentStatus: formData.paymentStatus,
    serialNumber: formData.serialNumber,
    merchantId: formData.merchantId,
    dueDateStartAt: formData.dueDate.start?.toISOString(),
    dueDateEndAt: formData.dueDate.end?.toISOString(),
    periodStartAt: formData.periodDate.start?.toISOString(),
    periodEndAt: formData.periodDate.end?.toISOString(),
    invoiceDateStartAt: formData.invoiceDate.start?.toISOString(),
    invoiceDateEndAt: formData.invoiceDate.end?.toISOString(),
    fullyPaidDateStartAt: formData.fullyPaidDate.start?.toISOString(),
    fullyPaidDateEndAt: formData.fullyPaidDate.end?.toISOString(),
  };

  return '/invoices?' + new URLSearchParams(filterFields(optionalQueryParams)).toString();
};

const InvoicesSearch = (props: { merchantId: string | null }) => {
  const { t } = useTranslation();
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  const { control, handleSubmit, getValues } = useForm<FormInputSchema>({
    defaultValues: {
      fullyPaidDate: { start: undefined, end: undefined },
      dueDate: { start: undefined, end: undefined },
      periodDate: { start: undefined, end: undefined },
      invoiceDate: { start: undefined, end: undefined },
      currency: undefined,
      paymentStatus: undefined,
      serialNumber: undefined,
      merchantId: props.merchantId ?? undefined,
    },
    resolver: zodResolver(formSchema),
  });

  const onSubmit = async (data: FormInputSchema) => {
    const url = constructUrl(data);
    setIsFetching(true);
    const response = await fetchFromBackend(url);

    if (response.ok) {
      const responseBody: { invoices: Invoice[] } = await response.json();

      setInvoices(responseBody.invoices);
    } else {
      createToast(t('invoices.search.unexpected-error'), {
        timeout: 6000,
        type: 'error',
      });
    }

    setIsFetching(false);
  };

  useEffect(() => {
    if (props.merchantId) {
      onSubmit(getValues());
    }
    // this hook should only be triggered on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {!props.merchantId && <Display size="s">{t('invoices.search.title')}</Display>}
      <form onSubmit={handleSubmit(onSubmit)}>
        <FlexDiv>
          {!props.merchantId && (
            <FlexLeftCol>
              <FormSelect
                options={Object.values(Currency).map(it => ({
                  value: it,
                  label: <div>{currencyToCountry[it]}</div>,
                }))}
                label={t('invoices.search.country')}
                name="currency"
                control={control}
              />
            </FlexLeftCol>
          )}
          <FlexLeftCol>
            <FormSelect
              options={Object.values(PaymentStatus).map(it => ({
                value: it,
                label: <div>{paymentStatusLabel[it]}</div>,
              }))}
              label={t('invoices.search.status')}
              name="paymentStatus"
              control={control}
            />
          </FlexLeftCol>
          <FlexLeftCol>
            <LabelWrapper>
              <Label size={'s'}>{t('invoices.search.period')}</Label>
            </LabelWrapper>
            <FormDatePicker control={control} name="periodDate" />
          </FlexLeftCol>
          <FlexLeftCol>
            <LabelWrapper>
              <Label size={'s'}>{t('invoices.search.invoice-date')}</Label>
            </LabelWrapper>
            <FormDatePicker control={control} name="invoiceDate" />
          </FlexLeftCol>
        </FlexDiv>
        <FlexDiv>
          {!props.merchantId && (
            <FlexLeftCol>
              <FormTextInput
                label={t('invoices.search.merchant')}
                placeholder="0f14d0ab-9605-4a62-a9e4-5ed26688389a"
                name={'merchantId'}
                control={control}
              />
            </FlexLeftCol>
          )}
          <FlexLeftCol>
            <FormTextInput
              label={t('invoices.search.invoice-number')}
              placeholder="XX-LS-10000000"
              name={'serialNumber'}
              control={control}
            />
          </FlexLeftCol>
          <FlexLeftCol>
            <LabelWrapper>
              <Label size={'s'}>{t('invoices.search.due-date')}</Label>
            </LabelWrapper>
            <FormDatePicker control={control} name="dueDate" />
          </FlexLeftCol>
          <FlexLeftCol>
            <LabelWrapper>
              <Label size={'s'}>{t('invoices.search.paid-date')}</Label>
            </LabelWrapper>
            <FormDatePicker control={control} name="fullyPaidDate" />
          </FlexLeftCol>
        </FlexDiv>
        <div>
          <Button type={'submit'} label={t('invoices.search.search')} disabled={isFetching} />
        </div>
      </form>
      <InvoicesTable invoices={invoices} isSpecificMerchant={!!props.merchantId} />
    </div>
  );
};

export default InvoicesSearch;
