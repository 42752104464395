import styled from 'styled-components';

export const ModalSectionHeader = styled.p`
  font-family: 'Roobert';
  font-size: 20px;
  font-weight: 500;
`;

export const ModalSectionSubHeader = styled.div`
  font-family: 'Roobert';
  font-size: 16px;
`;
