import { links } from 'config';
import { useParams } from 'react-router-dom';

const DashboardsScreen = () => {
  const { dashboard } = useParams<{ dashboard?: string }>();

  return dashboard ? <Dasboard selectedDashboard={dashboard} /> : <></>;
};

const Dasboard = (props: { selectedDashboard: string }) => {
  return (
    <iframe
      src={
        links.backendUrl +
        `/s/business-kpis/app/dashboards#/view/${props.selectedDashboard}?embed=true&_g=(refreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3Anow-0d%2Fd%2Cto%3Anow))`
      }
      width="100%"
      title={props.selectedDashboard + ' dashboard'}
      height={window.innerHeight?.toString()}
      allowFullScreen
    />
  );
};

export default DashboardsScreen;
