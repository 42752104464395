import { hasAnyPermission, Permission, useUserPermissions } from 'permissions';
import { MenuItem, SubMenuItem } from 'routes/NavigationBar';
import { useTranslation } from 'react-i18next';

export const useFinanceMenuItems = (): MenuItem[] => {
  const { permissions } = useUserPermissions();
  const { t } = useTranslation();

  if (
    !hasAnyPermission(permissions, [
      Permission.READ_FINANCE,
      Permission.WRITE_FINANCE,
      Permission.READ_INVOICE,
      Permission.DEVELOPMENT,
    ])
  ) {
    return [];
  }
  const subMenuItems: SubMenuItem[] = [];

  if (hasAnyPermission(permissions, [Permission.READ_FINANCE, Permission.WRITE_FINANCE])) {
    subMenuItems.push({
      id: 'reconciliation',
      href: '/reconciliation',
      title: t('reconciliation.title'),
    });
  }
  if (hasAnyPermission(permissions, [Permission.READ_FINANCE])) {
    subMenuItems.push({
      id: 'reports',
      href: '/reports',
      title: t('navigation.finance.reports'),
    });
  }

  if (hasAnyPermission(permissions, [Permission.READ_INVOICE])) {
    subMenuItems.push({
      id: 'invoices',
      href: '/invoices',
      title: t('navigation.invoices'),
    });
  }

  if (hasAnyPermission(permissions, [Permission.WRITE_FINANCE])) {
    subMenuItems.push({
      id: 'journaling',
      href: '/journaling',
      title: t('navigation.journaling'),
    });
  }

  return [
    {
      id: 'finance',
      href: undefined,
      title: t('navigation.finance'),
      icon: 'bank',
      subMenuItems,
    },
  ];
};
