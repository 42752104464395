import { Icon, Label, MediumIcons, Spacing, Text } from '@loomispay/vault';
import React, { FunctionComponent } from 'react';

import styled from 'styled-components';
import american_express from './icons/american_express.png';
import bank_axept from './icons/bank_axept.png';
import dankort from './icons/dankort.png';
import { ReactComponent as Cash } from './icons/cash.svg';
import maestro from './icons/maestro.png';
import mastercard from './icons/mastercard.png';
import { ReactComponent as Swish } from './icons/swish.svg';
import visa from './icons/visa.png';

export const ContactPersonSection = styled.div`
  padding: ${({ theme }) => theme.spacings['4']};
  border: 1px solid ${({ theme }) => theme.color.border.defaultNormalBorder};
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.spacings['1']};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Line = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const LineText = styled(Text)`
  line-height: ${({ theme }) => theme.spacings['4']};
`;

interface SpacedProps {
  title: string;
  value: string | undefined | null;
}

export const Spaced: FunctionComponent<SpacedProps> = ({ title, value }) => {
  return (
    <Line>
      <LineText size={'l'} color={'secondary'}>
        {title}
      </LineText>
      <LineText size={'l'}>{value ?? '-'}</LineText>
    </Line>
  );
};

export type TitledListProps = {
  header: string;
  icon: MediumIcons;
};

export const TitledList: React.FC<TitledListProps> = ({ header, icon, children }) => {
  return (
    <Wrapper>
      <Spacing bottom={'4'}>
        <Spacing bottom={'2'}>
          <Label size={'l'}>
            <Spacing right={'1'} inline>
              <Icon size={'m'} name={icon} />
            </Spacing>
            {header}
          </Label>
        </Spacing>
        {children}
      </Spacing>
    </Wrapper>
  );
};

const PaymentMethodListItemWrapper = styled.li`
  box-sizing: border-box;
  height: 32px;
  margin-bottom: ${({ theme }) => theme.spacings['1.5']};
  display: flex;
  align-items: center;
`;

type PaymentMethod =
  | 'mastercard'
  | 'visa'
  | 'cash'
  | 'swish'
  | 'maestro'
  | 'american_express'
  | 'bank_axept'
  | 'dankort';

const paymentMethodTitle = {
  mastercard: 'Mastercard',
  visa: 'VISA',
  cash: 'Cash',
  swish: 'Swish',
  maestro: 'Maestro',
  american_express: 'American Express',
  bank_axept: 'Bank Axept',
  dankort: 'Dankort',
};

type PaymentMethodProps = {
  paymentMethod: PaymentMethod;
};

export const PaymentMethodListItem = ({ paymentMethod }: PaymentMethodProps) => {
  const title = paymentMethodTitle[paymentMethod];
  return (
    <PaymentMethodListItemWrapper>
      <PaymentMethodIcon paymentMethod={paymentMethod} />
      <Spacing left={'1.5'}>
        <Text size={'l'}>{title}</Text>
      </Spacing>
    </PaymentMethodListItemWrapper>
  );
};

export const PIcon = styled.img`
  width: 32px;
  height: 24px;
`;

const PaymentMethodIcon = ({ paymentMethod }: PaymentMethodProps) => {
  switch (paymentMethod) {
    case 'mastercard':
      return <PIcon src={mastercard} alt={'Mastercard'} />;
    case 'maestro':
      return <PIcon src={maestro} alt={'Maestro'} />;
    case 'visa':
      return <PIcon src={visa} alt={'Visa'} />;
    case 'swish':
      return <Swish />;
    case 'cash':
      return <Cash />;
    case 'american_express':
      return <PIcon src={american_express} alt={'American Express'} />;
    case 'bank_axept':
      return <PIcon src={bank_axept} alt={'Bank Axept'} />;
    case 'dankort':
      return <PIcon src={dankort} alt={'Dankort'} />;
  }
};

export default PaymentMethodIcon;
