import { Display, Heading, Icon } from '@loomispay/vault';
import { Badge } from 'components/Badge';
import { ContentWrapper } from 'components/ContentWrapper';
import { DatePicker } from 'components/DatePicker';
import { Description } from 'components/Description';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDateRange } from 'utils/hooks/useDataRange';
import { FileDataTable } from './FileDataTable';
import {
  File,
  FileDownloadTabs,
  FileType,
  GetAllAmexFilesResponse,
  GetAllFilesResponse,
  GetNeedsAttentionResponse,
  SourceParty,
} from './FileProcessing.service';
import { DatePickerWrapper } from './FileProcessing.styles';
import { Tab, Tabs } from './Tabs';
import { AmexFileDataTable } from './AmexFileDataTable';

export const FileProcessingScreen = () => {
  const { t } = useTranslation();
  const [fileTab, setFileTab] = useState<FileDownloadTabs>(FileDownloadTabs.SHIFT4_SETTLEMENTS);

  const tabDescriptions = {
    [FileDownloadTabs.SHIFT4_SETTLEMENTS]: {
      label: t('file-processing.file-type.merchant-settlement-statement.description.label'),
      content: t('file-processing.file-type.merchant-settlement-statement.description.content'),
    },
    [FileDownloadTabs.AMEX_SETTLEMENTS]: {
      label: t('file-processing.file-type.amex-settlement-statement.description.label'),
      content: t('file-processing.file-type.amex-settlement-statement.description.content'),
    },
  };

  const fileTabMap = {
    [FileDownloadTabs.SHIFT4_SETTLEMENTS]: {
      sourceParty: [SourceParty.CREDORAX],
      fileType: FileType.MERCHANT_SETTLEMENT_STATEMENT,
    },
    [FileDownloadTabs.AMEX_SETTLEMENTS]: {
      sourceParty: [SourceParty.AMEX_INITIAL_PARSING, SourceParty.AMEX_SETTLEMENT_TRIGGERED],
      fileType: FileType.MERCHANT_SETTLEMENT_STATEMENT,
    },
  };

  const { startDate, endDate, onDateRangeChange } = useDateRange();
  const needsAttention = GetNeedsAttentionResponse();
  const needsAttentionFiles = needsAttention.files;
  const allFilesResponse = GetAllFilesResponse(fileTabMap[fileTab].fileType, startDate, endDate);
  const allAmexFilesResponse = GetAllAmexFilesResponse(startDate, endDate);
  const files = allFilesResponse.files;
  const amexFiles = allAmexFilesResponse.files;

  const tabs = Object.values(FileDownloadTabs).map(fileTab => ({
    key: fileTab,
    label: (
      <span>
        {formatFileType(fileTab)}
        {getNeedsAttentionBadge(fileTab, needsAttentionFiles)}
      </span>
    ),
  }));

  function formatFileType(fileTab: FileDownloadTabs) {
    switch (fileTab) {
      case FileDownloadTabs.SHIFT4_SETTLEMENTS:
        return t('file-processing.file-type.merchant-settlement-statement');
      case FileDownloadTabs.AMEX_SETTLEMENTS:
        return t('file-processing.file-type.amex-merchant-settlement-statement');
      default:
        return '';
    }
  }

  function getNeedsAttentionBadge(fileTab: FileDownloadTabs, needsAttentionFiles: File[] | undefined) {
    const needsAttentionFilesFiltered = needsAttentionFiles?.filter(
      file => fileTabMap[fileTab].sourceParty.includes(file.sourceParty) && file.type === fileTabMap[fileTab].fileType
    );
    if (needsAttentionFilesFiltered !== undefined && needsAttentionFilesFiltered?.length > 0) {
      return (
        <Badge color={'negative'} background={'negativeBg'}>
          {needsAttentionFilesFiltered.length}
        </Badge>
      );
    }
  }

  const refresh = () => {
    needsAttention.refresh();
    allFilesResponse.refresh();
  };

  return (
    <div>
      <Display size="s">{t('file-processing.title')}</Display>
      <div>
        {needsAttentionFiles && needsAttentionFiles.length > 0 && (
          <ContentWrapper>
            <Heading size="m" color={'negative'}>
              Needs attention
              <Badge color={'negative'} background={'negativeBg'}>
                {needsAttentionFiles && needsAttentionFiles.length}
              </Badge>
            </Heading>
            <FileDataTable data={needsAttentionFiles} refresh={refresh} />
          </ContentWrapper>
        )}
        {needsAttentionFiles && needsAttentionFiles.length === 0 && (
          <Heading size="xs" color={'positive'}>
            <Icon size={'m'} name={'checkmark'} color={'positive'} />
            All files are being processed correctly
          </Heading>
        )}
      </div>
      <div>
        <ContentWrapper>
          {needsAttentionFiles && needsAttentionFiles.length > 0 && <Heading size="m">All files</Heading>}
          <Tabs
            tabs={tabs}
            initialActiveTab={tabs[0].key}
            onClick={(tab: Tab) => {
              setFileTab(tab.key as FileDownloadTabs);
            }}
          />
          <div
            style={{
              paddingTop: '1rem',
              paddingBottom: '1rem',
              cursor: 'default',
            }}
          >
            <Description label={tabDescriptions[fileTab].label} content={tabDescriptions[fileTab].content} />
          </div>
          <DatePickerWrapper>
            <DatePicker onChange={onDateRangeChange} startDate={startDate} endDate={endDate} />
          </DatePickerWrapper>
          <div>
            {(fileTab !== 'AMEX_SETTLEMENTS' && files && <FileDataTable data={files} refresh={refresh} />) ||
              (fileTab === 'AMEX_SETTLEMENTS' && amexFiles && (
                <AmexFileDataTable data={amexFiles} refresh={allAmexFilesResponse.refresh} />
              ))}
          </div>
        </ContentWrapper>
      </div>
    </div>
  );
};
