import { Spacing, Text } from '@loomispay/vault';
import { PaymentMethodListItem, PIcon } from '../details/MerchantScreen.styles';

import { createColumnHelper } from '@tanstack/react-table';
import { TFunction } from 'react-i18next';
import { formatAmount } from 'utils/formatters/amountFormatters';
import { formatISODateTime } from 'utils/formatters/dateFormatters';
import { toTitleCase } from 'utils/formatters/toTitleCase';
import qrCode from '../../fees/Icons/qr-code.svg';
import { Transaction } from './TransactionModel';

const columnHelper = createColumnHelper<Transaction>();

export const getTransactionColumns = (t: TFunction<'translation', undefined>) => [
  columnHelper.accessor('timestamp', {
    header: () => <span>{t('merchant.transactions.dateTime')}</span>,
    cell: info => formatISODateTime(info.getValue()),
  }),
  columnHelper.accessor(row => row.orderId, {
    id: 'orderId',
    cell: info => <span>{info.getValue() ?? '-'}</span>,
    header: () => <span>{t('merchant.transactions.orderId')}</span>,
  }),
  columnHelper.accessor('type', {
    header: () => t('merchant.transactions.transactionType'),
    cell: props => (props.row.original.cardPresent === false ? qrCodeIcon(props.getValue()) : props.getValue()),
    minSize: 400,
  }),
  columnHelper.accessor('cardScheme', {
    header: () => <span>{t('merchant.transactions.cardScheme')}</span>,
    cell: props => getCardSchemeIcon(props.getValue()),
    enableSorting: false,
  }),
  columnHelper.accessor('amount', {
    header: () => <span>{t('merchant.transactions.amount')}</span>,
    cell: props => formatAmount(props.getValue(), props.row.original.currency),
  }),
  columnHelper.accessor('status', {
    header: () => <span>{t('merchant.transactions.transactionStatus')}</span>,
    cell: info => toTitleCase(info.getValue()),
  }),
];

const getCardSchemeIcon = (cardScheme: string) => {
  switch (cardScheme) {
    case 'MASTERCARD':
      return <PaymentMethodListItem paymentMethod={'mastercard'} />;
    case 'VISA':
      return <PaymentMethodListItem paymentMethod={'visa'} />;
    case 'MAESTRO':
      return <PaymentMethodListItem paymentMethod={'maestro'} />;
    case 'AMERICAN_EXPRESS':
      return <PaymentMethodListItem paymentMethod={'american_express'} />;
    case 'DANKORT':
      return <PaymentMethodListItem paymentMethod={'dankort'} />;
    default:
      return null;
  }
};

const qrCodeIcon = (transactionType: string) => {
  return (
    <div style={{ display: 'flex' }}>
      <PIcon src={qrCode} alt={'QR code'} />
      <Spacing left={'1.5'}>
        <Text size={'l'}>{transactionType}</Text>
      </Spacing>
    </div>
  );
};

export const getSortColumnString = (column: string) => {
  switch (column) {
    case 'id':
      return 'transaction.id';
    case 'type':
      return 'transaction.type';
    case 'amount':
      return 'transaction.amount';
    case 'status':
      return 'transaction.status';
    case 'orderId':
      return 'order.id';
    case 'timestamp':
    default:
      return 'transaction.timestamp';
  }
};
