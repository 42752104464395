import { Button, Flag, Heading, Spacing, Text } from '@loomispay/vault';
import { NorwegianFlag } from '../../components/NorwegianFlag';
import { Modal } from 'components/Modal';
import { FormTextInput } from 'components/TextInput';
import { hasAnyPermission, Permission, useUserPermissions } from 'permissions';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { createToast } from 'vercel-toast';
import { z } from 'zod';
import { StatusCode } from '../../api/types';
import { useMerchantIdFromUrl, useMerchantStatus } from './merchant-hooks';
import { useMerchantReportingDetails } from './merchantReporting-hooks';
import { fetchFromBackend } from '../../utils/fetch';
import { Form } from './fees/helper';

const Flex = styled.div`
  display: flex;
`;

export const MerchantScreenHeader = () => {
  const { t } = useTranslation();
  const { permissions } = useUserPermissions();

  const merchantId = useMerchantIdFromUrl();
  const { data } = useMerchantReportingDetails(merchantId);

  const { data: merchantStatusData } = useMerchantStatus();
  const [editCompanyNameModalOpen, setEditCompanyNameModalOpen] = useState(false);
  const merchantNotActive = [
    StatusCode.DEACTIVATED,
    StatusCode.OFFBOARDED,
    StatusCode.OFFBOARDING_REQUESTED,
    StatusCode.ANNULLED,
  ].some(status => status === merchantStatusData?.status);

  let countryFlag;
  switch (data?.address?.country) {
    case 'SE':
      countryFlag = <Flag country={'sweden'} />;
      break;
    case 'DK':
      countryFlag = <Flag country={'denmark'} />;
      break;
    case 'ES':
      countryFlag = <Flag country={'spain'} />;
      break;
    case 'NO':
      countryFlag = <NorwegianFlag />;
      break;
    default:
      countryFlag = <Flag country={'placeholder'} />;
  }

  let status;
  switch (merchantStatusData?.status) {
    case StatusCode.DEACTIVATED:
      status = t('merchant.details.status.deactivated');
      break;
    case StatusCode.OFFBOARDED:
      status = t('merchant.details.status.offboarded');
      break;
    case StatusCode.PAUSED:
      status = t('merchant.details.status.paused');
      break;
    case StatusCode.ANNULLED:
      status = t('merchant.details.status.annulled');
      break;
    default:
      status = merchantStatusData?.status ?? 'UNKNOWN';
  }

  const ChangeNameModalContent = ({ companyName }: { companyName: string | null }) => {
    const formSchema = z.object({
      companyName: z.string().min(1).max(255),
    });
    type FormSchema = z.infer<typeof formSchema>;

    const { control, handleSubmit } = useForm<FormSchema>({
      defaultValues: { companyName: companyName || '' },
    });
    const merchantId = useMerchantIdFromUrl();

    const onSubmit = async (formValues: FormSchema) => {
      const response = await fetchFromBackend(`/merchants/${merchantId}/company-name`, {
        method: 'PUT',
        body: JSON.stringify({ companyName: formValues.companyName }),
      });

      if (response.ok) {
        createToast('Request to update company name was sent. It will be changed in a few seconds', {
          type: 'success',
          timeout: 7000,
        });
      } else {
        createToast('Failed to update company name', {
          type: 'error',
          timeout: 7000,
        });
      }

      setEditCompanyNameModalOpen(false);
    };

    return (
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Heading>Update company name</Heading>
        <FormTextInput name="companyName" label="Company Name" control={control} />
        <Spacing bottom="2" />
        <Button type="submit" label="Save" />
      </Form>
    );
  };

  return (
    <>
      <Spacing bottom={'2'}>
        <Flex>
          <Heading size={'xl'} noGutter color={merchantNotActive ? 'disabled' : undefined}>
            {data?.companyName || 'unknown'}
          </Heading>
          <div style={{ display: 'inline', marginLeft: '10px', marginTop: '17px' }}>{countryFlag}</div>
          {hasAnyPermission(permissions, [Permission.MANAGE_MERCHANT]) && (
            <Button size={'s'} variant={'tertiary'} icon={'edit'} onClick={() => setEditCompanyNameModalOpen(true)} />
          )}
        </Flex>
      </Spacing>
      <Spacing bottom={'4'}>
        {merchantNotActive && (
          <>
            <Text size={'xl'} color="negative">
              {status}
            </Text>
            <span style={{ padding: '0.5rem' }} />
          </>
        )}
        <Modal isOpen={editCompanyNameModalOpen} close={() => setEditCompanyNameModalOpen(false)} closeButton>
          <ChangeNameModalContent companyName={data?.companyName || null} />
        </Modal>
      </Spacing>
    </>
  );
};
