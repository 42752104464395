import { links } from 'config';

export const fetchByUrl = async (url: RequestInfo, init?: RequestInit) => {
  return internalFetch(url, links.backendUrl, init);
};

const internalFetch = async (url: RequestInfo, baseUrl: string, init?: RequestInit) => {
  const response = await fetch(baseUrl + url, {
    ...init,
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      ...init?.headers,
    },
  });

  if (!response.ok) {
    throw new FetchError('An error occurred while fetching the data.', await response.json(), response.status);
  }

  if (response.redirected) {
    window.location.href = response.url;
  }

  if (response.status === 204) {
    return null;
  } else {
    return await response.json();
  }
};

class FetchError extends Error {
  info: string;
  status?: number;

  constructor(message: string, info: string, status?: number) {
    super(message);
    this.info = info;
    this.status = status;
  }
}
