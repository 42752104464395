import { Button, Icon, Text } from '@loomispay/vault';
import { Permission, useUserPermissions } from '../../permissions';

import saveAs from 'file-saver';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ClipLoader from 'react-spinners/ClipLoader';
import { createToast } from 'vercel-toast';
import { links } from '../../config';
import { File } from './FileProcessing.service';

export const DownloadButton = (props: { file: File }) => {
  const { t } = useTranslation();
  const { permissions } = useUserPermissions();
  const [clicked, setClicked] = useState<boolean>(false);

  const downloadFile = async () => {
    const response = await fetch(`${links.backendUrl}/file-processing/${props.file.id}/download`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.ok) {
      const blob = await response.blob();
      saveAs(blob, props.file.name);
    } else {
      createToast(t('unexpected-error'), {
        timeout: 6000,
        type: 'error',
      });
    }
    setClicked(false);
  };

  return (
    <div>
      {permissions.includes(Permission.READ_FILE_PROCESSING) && props.file.isAvailable && (
        <Button
          label={(t('file-processing.download'), 'Download')}
          onClick={() => {
            setClicked(true);
            downloadFile();
          }}
        />
      )}
      {clicked && <ClipLoader size={35} />}
      {props.file.needsAttention && props.file.retry && (
        <Text color={'positive'}>
          <Icon color={'positive'} size={'m'} name={'checkmark'} />
          {t('file-processing.file-is-processing')}
        </Text>
      )}
    </div>
  );
};
