import { fetchByUrl } from 'api';
import { ElasticMerchant } from 'api/types';
import useSWR from 'swr';

export const useMerchantReportingDetails = (id: string) => {
  return useSWR<MerchantReportingDetails>(['/merchants/search/' + id], url => fetchByUrl(url), {
    refreshInterval: 10000,
  });
};

export const useMerchant = (id: string) => {
  return useSWR<ElasticMerchant>([`/merchants/${id}/internal`], url => fetchByUrl(url), {
    refreshInterval: 10000,
  });
};

export interface MerchantReportingDetails extends Record<string, unknown> {
  id: string;
  acquirer: Acquirer | null;
  RegistrationIdentifier: string | null;
  sales: Sales | null;
  companyName: string;
  businessCategory: string | null;
  businessSubCategory: string | null;
  createdAt: Date | null;
  address: Address | null;
  offerReference: string | null;
  contract: Contract | null;
  onboarding: Onboarding;
}

interface Acquirer {
  SubMerchantId: string;
  name: string;
}

interface Sales {
  country: string;
  representative: Representative;
}

interface Representative {
  email: string;
  name: string;
}

interface Address {
  city: string;
  country: string;
  zipCode: string;
}

interface Contract {
  startDate: Date | null;
  endDate: Date | null;
  freeTerminalSubscriptionMonths: number | null;
  license: Date | null;
  riskClassification: string | null;
  riskScore: number | null;
  terminalTransactionFeePercentage: number | null;
}

interface Onboarding {
  contractSigned: Date | null;
  formSubmitted: Date | null;
  offerAccepted: Date | null;
  offerSent: Date | null;
  startMonth: string | null;
  step: string;
  stepName: string;
  transactionDone: Date | null;
  transactionsEnabled: Date | null;
}

export interface SearchMerchantsResponse {
  merchants: ElasticMerchant[];
  pagination: ResponsePagination;
}

interface ResponsePagination {
  pageCount: number;
  pageSize: number;
  pageNumber: number;
  totalCount: number;
}
