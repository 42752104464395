import { useState } from 'react';

type DateRange = [Date | null, Date | null];

export function useDateRange(initialDates: DateRange = [null, null]) {
  const [start, end] = initialDates;
  const [startDate, setStartDate] = useState<Date | null>(start || null);
  const [endDate, setEndDate] = useState<Date | null>(end || null);

  const onDateRangeChange = (dateRange: DateRange) => {
    const [start, end] = dateRange;
    setStartDate(start);
    setEndDate(end);
  };

  return { startDate, endDate, onDateRangeChange };
}
