import { Button, Flag, Heading, Select, Text } from '@loomispay/vault';
import { NorwegianFlag } from '../../components/NorwegianFlag';
import { CardCustomerType, CardScheme, CardType, Region } from 'api/types';
import { Permission, useUserPermissions } from 'permissions';
import React, { useState } from 'react';
import { z, ZodIssueCode } from 'zod';
import { Channel, channelOptions, CountryName } from './FeesScreen';
import { CloseButton, FeesHeader, FeesRow, FlexDiv, FlexLeftCol, FlexRightCol, Form, SubHeading } from './helper';

import { zodResolver } from '@hookform/resolvers/zod';
import { Modal } from 'components/Modal';
import { FormSelect } from 'components/Select';
import { FormTextInput } from 'components/TextInput';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toTitleCase } from 'utils/formatters/toTitleCase';
import { fetchFromBackend } from '../../utils/fetch';
import { ReactComponent as Mastercard } from './Icons/Mastercard.svg';
import { ReactComponent as Visa } from './Icons/Visa.svg';

export enum CountryCode {
  DK = 'DK',
  SE = 'SE',
  ES = 'ES',
  NO = 'NO',
}

export const countryToFlag = {
  [CountryCode.SE]: <Flag country={'sweden'} />,
  [CountryCode.DK]: <Flag country={'denmark'} />,
  [CountryCode.ES]: <Flag country={'spain'} />,
  [CountryCode.NO]: <NorwegianFlag />,
};

export interface TransactionFeeRuleRequestBody {
  countryCode: CountryCode;
  cardPresent: boolean;
  region: Region;
  cardCustomerType: CardCustomerType;
  cardScheme: CardScheme;
  cardType: CardType;
  percentage: {
    default: number;
    min: number;
    max: number;
  };
  fixedFeeAmount?: {
    default: number;
    min: number;
    max: number;
  };
}

export const AddNewRule = (props: { refresh: () => void; cardPresent: boolean; countryCode: CountryCode }) => {
  const { t } = useTranslation();
  const { permissions } = useUserPermissions();

  const [loadModal, setLoadModal] = useState<boolean>(false);
  const [loadErrorModal, setLoadErrorModal] = useState<boolean>(false);

  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <Button
        iconPosition={'left'}
        icon={'plus'}
        size={'m'}
        label={t('fees.new-rule.add-new')}
        disabled={!permissions.includes(Permission.WRITE_FEES)}
        onClick={() => {
          setLoadModal(true);
        }}
      />

      <Modal isOpen={loadModal} close={() => setLoadModal(false)}>
        <ModalContent
          refresh={props.refresh}
          setLoadModal={setLoadModal}
          setLoadErrorModal={setLoadErrorModal}
          cardPresent={props.cardPresent}
          countryCode={props.countryCode}
        />
      </Modal>
      <ExistingCombinationModal displayModal={loadErrorModal} close={() => setLoadErrorModal(false)} />
    </div>
  );
};

const ExistingCombinationModal: React.FC<{ displayModal: boolean; close: () => void }> = ({ displayModal, close }) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={displayModal} close={close}>
      <Heading size={'m'}>{t('fees.new-rule.existing-combination')}</Heading>
      <SubHeading>
        <Text>{t('fees.new-rule.combination-card-details-already-added')}</Text>
      </SubHeading>
      <Button label={t('fees.new-rule.go-back')} onClick={close} />
    </Modal>
  );
};

export const constructFormPercentagesInputSchema = (t: (message: string) => string) => {
  return z
    .object({
      min: z.string().min(1),
      max: z.string().min(1),
      default: z.string().min(1),
    })
    .superRefine((val, ctx) => {
      if (Number(val.min) > Number(val.max)) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          message: t('fees.validation.invalid-range-min'),
          path: ['min'],
        });
      }
      if (Number(val.max) < Number(val.default) || Number(val.min) > Number(val.default)) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          message: t('fees.validation.invalid-range-default'),
          path: ['default'],
        });
      }
      if (Number(val.max) > 100) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          message: t('fees.validation.invalid-range-exceed'),
          path: ['max'],
        });
      }
      if (Number(val.min) < 0) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          message: t('fees.validation.invalid-range-min-zero'),
          path: ['min'],
        });
      }
    });
};

export const constructFormFixedFeeAmountsInputSchema = (t: (message: string) => string) => {
  return z
    .object({
      min: z.string().optional(),
      max: z.string().optional(),
      default: z.string().optional(),
    })
    .optional()
    .superRefine((val, ctx) => {
      if (Number(val?.min) > Number(val?.max)) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          message: t('fees.validation.invalid-fixed-fee-min'),
          path: ['min'],
        });
      }
      if (Number(val?.max) < Number(val?.default) || Number(val?.min) > Number(val?.default)) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          message: t('fees.validation.invalid-fixed-fee-range-default'),
          path: ['default'],
        });
      }
      if (Number(val?.min) < 0) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          message: t('fees.validation.invalid-fixed-fee-min-zero'),
          path: ['min'],
        });
      }
    });
};

const ModalContent = (props: {
  refresh: () => void;
  setLoadModal: (value: boolean) => void;
  setLoadErrorModal: (value: boolean) => void;
  cardPresent: boolean;
  countryCode: CountryCode;
}) => {
  const { t } = useTranslation();

  const FormInputSchema = z.object({
    region: z.nativeEnum(Region),
    cardCustomerType: z.nativeEnum(CardCustomerType),
    cardScheme: z.nativeEnum(CardScheme),
    cardType: z.nativeEnum(CardType),
    percentage: constructFormPercentagesInputSchema(t),
    fixedFee: constructFormFixedFeeAmountsInputSchema(t),
  });

  type FormInputSchemaType = z.infer<typeof FormInputSchema>;

  const { control, handleSubmit } = useForm<FormInputSchemaType>({
    defaultValues: {
      percentage: {
        min: '',
        max: '',
        default: '',
      },
      fixedFee: {
        min: '',
        max: '',
        default: '',
      },
    },
    resolver: zodResolver(FormInputSchema),
  });

  const countryOptions = Object.values(CountryCode).map(it => ({
    value: it,
    label: (
      <div style={{ display: 'flex' }}>
        {countryToFlag[it]}
        <div style={{ paddingLeft: '8px', alignSelf: 'center' }}>{CountryName[it]}</div>
      </div>
    ),
  }));

  const onSubmit = async (data: FormInputSchemaType) => {
    const reqData: TransactionFeeRuleRequestBody = {
      countryCode: props.countryCode,
      cardPresent: props.cardPresent,
      region: data.region,
      cardCustomerType: data.cardCustomerType,
      cardScheme: data.cardScheme,
      cardType: data.cardType,
      percentage: {
        default: Number(data.percentage.default),
        min: Number(data.percentage.min),
        max: Number(data.percentage.max),
      },
      fixedFeeAmount: {
        default: Number(data.fixedFee?.default),
        min: Number(data.fixedFee?.min),
        max: Number(data.fixedFee?.max),
      },
    };
    const response = await fetchFromBackend('/transaction-fee-rules', {
      method: 'POST',
      body: JSON.stringify(reqData),
    });
    if (response.ok) {
      props.setLoadModal(false);
      props.refresh();
    } else if (response.status >= 500)
      alert('There was an unexpected error while inserting the new transaction fee rule. Please try again later');
    else if (response.status >= 400) props.setLoadErrorModal(true);
  };

  return (
    <>
      <CloseButton>
        <Button
          icon={'close'}
          variant={'tertiary'}
          onClick={() => {
            props.setLoadModal(false);
          }}
        />
      </CloseButton>
      <Heading size={'m'}>{t('fees.new-rule.new-fee-range')}</Heading>
      <SubHeading>
        <Text>{t('fees.new-rule.select-card-details')}</Text>
      </SubHeading>

      <Form onSubmit={handleSubmit(x => onSubmit(x))}>
        <FlexDiv>
          <FlexLeftCol>
            <Select
              label={t('fees.new-rule.market')}
              isDisabled={true}
              name={'countryCode'}
              defaultValue={countryOptions.find(e => e.value === props.countryCode)}
              options={countryOptions}
            ></Select>
          </FlexLeftCol>
          <FlexRightCol>
            <Select
              label={t('fees.new-rule.channel')}
              isDisabled={true}
              name="cardPresent"
              defaultValue={channelOptions.find(e =>
                props.cardPresent ? e.value === Channel.CARD_PRESENT : e.value === Channel.CARD_NOT_PRESENT
              )}
              options={channelOptions}
            ></Select>
          </FlexRightCol>
        </FlexDiv>
        <FlexDiv>
          <FlexLeftCol>
            <FormSelect
              label={t('fees.new-rule.card-schema')}
              name={'cardScheme'}
              options={Object.values(CardScheme).map(it => ({
                value: it,
                label: (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {it === CardScheme.MASTERCARD ? (
                      <Mastercard style={{ paddingRight: '8px' }} />
                    ) : (
                      <Visa style={{ paddingRight: '8px' }} />
                    )}
                    {toTitleCase(it)}
                  </div>
                ),
              }))}
              control={control}
            />
          </FlexLeftCol>
          <FlexRightCol>
            <FormSelect
              label={t('fees.new-rule.region')}
              name={'region'}
              options={Object.values(Region).map(it => ({
                value: it,
                label: toTitleCase(it),
              }))}
              control={control}
            />
          </FlexRightCol>
          <FlexLeftCol>
            <FormSelect
              label={t('fees.new-rule.card-customer-type')}
              name={'cardCustomerType'}
              options={Object.values(CardCustomerType).map(it => ({
                value: it,
                label: toTitleCase(it),
              }))}
              control={control}
            />
          </FlexLeftCol>
          <FlexRightCol>
            <FormSelect
              label={t('fees.new-rule.card-type')}
              name={'cardType'}
              options={Object.values(CardType).map(it => ({
                value: it,
                label: toTitleCase(it),
              }))}
              control={control}
            />
          </FlexRightCol>
          <FeesHeader>{t('fees.table.percentageFee')}</FeesHeader>
          <FeesRow>
            <FormTextInput name="percentage.min" control={control} label={t('fees.new-rule.minimum')} required={true} />
            <FormTextInput name="percentage.max" control={control} label={t('fees.new-rule.maximum')} required={true} />
            <FormTextInput
              name="percentage.default"
              control={control}
              label={t('fees.new-rule.default')}
              required={true}
            />
          </FeesRow>
          <FeesHeader>{t('fees.table.fixedFee')}</FeesHeader>
          <FeesRow>
            <FormTextInput
              name="fixedFee.min"
              control={control}
              label={t('fees.edit-rule.fixed-minimum')}
              required={!props.cardPresent}
            />
            <FormTextInput
              name="fixedFee.max"
              control={control}
              label={t('fees.edit-rule.fixed-maximum')}
              required={!props.cardPresent}
            />
            <FormTextInput
              name="fixedFee.default"
              control={control}
              label={t('fees.edit-rule.fixed-default')}
              required={!props.cardPresent}
            />
          </FeesRow>
        </FlexDiv>
        <div style={{ float: 'right' }}>
          <Button type={'submit'} label={'Add'} />
        </div>
      </Form>
    </>
  );
};
