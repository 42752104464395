import { Flag, Heading, Label } from '@loomispay/vault';
import { NorwegianFlag } from './NorwegianFlag';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { persistItem, retrieveItem } from 'utils/persistentStorage';
import { EnabledFeature, useConfiguration } from '../configuration';
import i18n from '../i18n';
import { SupportBackofficeSelect } from './Select';

const StyledDropdown = styled(SupportBackofficeSelect)`
  width: 150px;
`;

const changeLanguage = (lng: string) => {
  if (lng) i18n.changeLanguage(lng);
};

type LanguageOption = { value: string; label: JSX.Element; flag: JSX.Element };

// Language labels should not be translated - should be language name in the language
const options: LanguageOption[] = [
  {
    value: 'en',
    label: (
      <Label size={'s'}>
        <Flag country={'united_states'} title={'English'} /> {'English'}
      </Label>
    ),
    flag: (
      <div>
        <Flag country={'united_states'} title={'English'} />
      </div>
    ),
  },
  {
    value: 'da',
    label: (
      <Label size={'s'}>
        <Flag country={'denmark'} title={'Dansk'} /> {'Dansk'}
      </Label>
    ),
    flag: (
      <div>
        <Flag country={'denmark'} title={'Dansk'} />
      </div>
    ),
  },
  {
    value: 'es',
    label: (
      <Label size={'s'}>
        <Flag country={'spain'} title={'Español'} /> {'Español'}
      </Label>
    ),
    flag: (
      <div>
        <Flag country={'spain'} title={'Español'} />
      </div>
    ),
  },
  {
    value: 'sv',
    label: (
      <Label size={'s'}>
        <Flag country={'sweden'} title={'Svenska'} /> {'Svenska'}
      </Label>
    ),
    flag: (
      <div>
        <Flag country={'sweden'} title={'Svenska'} />
      </div>
    ),
  },
  {
    value: 'no',
    label: (
      <Label size={'s'}>
        <NorwegianFlag /> {'Norsk'}
      </Label>
    ),
    flag: (
      <div>
        <NorwegianFlag />
      </div>
    ),
  },
];

const getDefaultLanguage = () => {
  const storedLanguage = retrieveItem('userLanguage');

  if (storedLanguage && options.some(language => language.value === storedLanguage)) {
    return storedLanguage;
  }

  let language = i18n.language;
  // When i18n discovers english in the browser you get for example en-GB
  if (language && language.length > 2) {
    language = language.substring(0, 2);
  }
  return language || 'en';
};

export const useLanguage = () => {
  const [currentLanguage, setCurrentLanguage] = useState<string>(getDefaultLanguage());

  useEffect(() => {
    persistItem('userLanguage', currentLanguage);
    changeLanguage(currentLanguage);
  }, [currentLanguage]);

  return { setCurrentLanguage, currentLanguage };
};

export const LanguageDropdown = () => {
  const enabledFeatures = useConfiguration().data?.enabledFeatures ?? [];
  const isMultiLanguageEnabled = enabledFeatures?.includes(EnabledFeature.MULTI_LANGUAGE);
  const { currentLanguage, setCurrentLanguage } = useLanguage();

  if (!isMultiLanguageEnabled) {
    return null;
  }

  return (
    <div>
      <Heading>Select your language</Heading>
      <StyledDropdown
        options={options}
        label={''}
        onSelectionChange={language => setCurrentLanguage(language as string)}
        defaultValue={options.find(option => currentLanguage === option.value)}
        styles={{
          indicatorsContainer: () => ({
            display: 'none',
          }),
          control: () => ({
            border: '0 !important',
            textAlign: 'right',
            boxShadow: 'none',
          }),
          container: () => ({
            outline: '0 !important',
          }),
        }}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        formatOptionLabel={(data: LanguageOption) => {
          return <div>{data.label}</div>;
        }}
      />
    </div>
  );
};
