import { Channel, channelOptions } from 'routes/fees/FeesScreen';
import { TransactionStatus, TransactionType } from '../TransactionModel';
import { FlexDiv, FlexLeftCol, FlexRightCol, LabelWrapper, SelectCol } from './MerchantTransactionsFilter.styles';

import { zodResolver } from '@hookform/resolvers/zod';
import { Label } from '@loomispay/vault';
import { FormDatePicker } from 'components/DatePicker';
import { FormSelect } from 'components/Select';
import { FormTextInput } from 'components/TextInput';
import { t } from 'i18next';
import { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { toTitleCase } from 'utils/formatters/toTitleCase';
import { z } from 'zod';

export type FormInputSchema = z.infer<typeof formSchema>;

export enum SettlementStatus {
  PAID = 'PAID',
  PENDING = 'PENDING',
}

const settlementStatusOptions = [
  { value: SettlementStatus.PAID, label: 'Paid' },
  { value: SettlementStatus.PENDING, label: 'Pending' },
];

const dateRangeSchema = z.object({
  start: z.date().optional(),
  end: z.date().optional(),
});

const formSchema = z.object({
  search: z.string().max(255).optional(),
  amountFrom: z.preprocess(a => parseFloat(z.string().parse(a)), z.number()).optional(),
  amountTo: z.preprocess(a => parseFloat(z.string().parse(a)), z.number()).optional(),
  timestamp: dateRangeSchema,
  orderId: z.string().max(64).optional(),
  transactionId: z.string().max(64).optional(),
  transactionType: z
    .array(
      z.object({
        value: z.nativeEnum(TransactionType),
        label: z.any(),
      })
    )
    .min(0)
    .optional(),
  transactionStatus: z
    .array(
      z.object({
        value: z.nativeEnum(TransactionStatus),
        label: z.any(),
      })
    )
    .min(0)
    .optional(),
  channel: z
    .array(
      z.object({
        value: z.nativeEnum(Channel),
        label: z.any(),
      })
    )
    .min(0)
    .optional(),
  settlementStatus: z
    .array(
      z.object({
        value: z.nativeEnum(SettlementStatus),
        label: z.any(),
      })
    )
    .min(0)
    .optional(),
});

interface MerchantTransactionsFilterProps {
  onSubmit: (formData: FormInputSchema) => void;
  isFetching: boolean;
}

const MerchantTransactionsFilter: FunctionComponent<MerchantTransactionsFilterProps> = ({ onSubmit, isFetching }) => {
  const { control, handleSubmit, register, getValues } = useForm<FormInputSchema>({
    defaultValues: {
      search: undefined,
      amountFrom: undefined,
      amountTo: undefined,
      timestamp: { start: undefined, end: undefined },
      orderId: undefined,
      transactionId: undefined,
      channel: undefined,
      settlementStatus: undefined,
    },
    resolver: zodResolver(formSchema),
  });

  const handleChange = () => {
    onSubmit(getValues());
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} onChange={handleChange}>
      <FlexDiv>
        <FlexLeftCol>
          <FormTextInput
            label={t('merchant.transactions.filter.searchField')}
            name="search"
            control={control}
            type="text"
            placeholder={t('merchant.transactions.filter.searchPlaceholder')}
          />
        </FlexLeftCol>
        <FlexRightCol>
          <LabelWrapper>
            <Label size={'s'}>{t('merchant.transactions.filter.timestamp')}</Label>
          </LabelWrapper>
          <FormDatePicker
            control={control}
            {...register('timestamp', {
              onChange: () => {
                handleChange();
              },
            })}
          />
        </FlexRightCol>
        <SelectCol>
          <FormSelect
            isMulti
            options={channelOptions}
            label={t('merchant.transactions.filter.channel')}
            control={control}
            {...register('channel', {
              onChange: () => {
                handleChange();
              },
            })}
          />
        </SelectCol>
      </FlexDiv>
      <FlexDiv>
        <FlexLeftCol>
          <FormSelect
            isMulti
            options={Object.values(TransactionType).map(it => ({
              value: it,
              label: <div>{toTitleCase(it)}</div>,
            }))}
            label={t('merchant.transactions.filter.transactionType')}
            control={control}
            {...register('transactionType', {
              onChange: () => {
                handleChange();
              },
            })}
          />
        </FlexLeftCol>
        <FlexRightCol>
          <FormSelect
            isMulti
            options={Object.values(TransactionStatus).map(it => ({
              value: it,
              label: <div>{toTitleCase(it)}</div>,
            }))}
            label={t('merchant.transactions.filter.transactionStatus')}
            control={control}
            {...register('transactionStatus', {
              onChange: () => {
                handleChange();
              },
            })}
          />
        </FlexRightCol>
        <SelectCol>
          <FormSelect
            isMulti
            options={settlementStatusOptions}
            label={t('merchant.transactions.filter.settlementStatus')}
            control={control}
            {...register('settlementStatus', {
              onChange: () => {
                handleChange();
              },
            })}
          />
        </SelectCol>
      </FlexDiv>
      <FlexDiv>
        <FlexLeftCol>
          <FormTextInput
            label={t('merchant.transactions.filter.amountFrom')}
            placeholder="0"
            name="amountFrom"
            control={control}
            type="number"
          />
        </FlexLeftCol>
        <FlexRightCol>
          <FormTextInput
            label={t('merchant.transactions.filter.amountTo')}
            placeholder="0"
            name="amountTo"
            control={control}
            type="number"
          />
        </FlexRightCol>
      </FlexDiv>
    </form>
  );
};

export default MerchantTransactionsFilter;
