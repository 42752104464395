import styled from 'styled-components';

export const FlexDiv = styled.div`
  display: flex;
`;

export const FlexLeftCol = styled.div`
  width: 25%;
  padding-right: 1.8%;
  display: flex;
  flex-direction: column;
  padding-bottom: 1%;
`;

export const FlexRightCol = styled.div`
  width: 50%;
  padding-left: 1.8%;
  display: flex;
  padding-bottom: 1%;
  flex-direction: column;
`;

export const SubHeading = styled.div`
  padding-bottom: 3%;
`;

export const CalendarLabel = styled.label`
  margin-bottom: 8px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
`;

export const CalendarSpan = styled.span`
  font-family: 'Roobert';
  font-size: 14px;
  line-height: 20px;
  -webkit-letter-spacing: 0.002em;
  -moz-letter-spacing: 0.002em;
  -ms-letter-spacing: 0.002em;
  letter-spacing: 0.002em;
  font-weight: 600;
  color: #292927;
`;

export const CalendarDiv = styled.div`
  font-family: 'Roobert';
  font-size: 14px;
  line-height: 20px;
  -webkit-letter-spacing: 0.002em;
  -moz-letter-spacing: 0.002em;
  -ms-letter-spacing: 0.002em;
  letter-spacing: 0.002em;
  font-weight: 600;
  color: #292927;
`;

export const LabelWrapper = styled.div`
  margin-bottom: 8px;
`;
