import React from 'react';
import styled from 'styled-components';

import { ReactComponent as FlagNorway } from './Icons/norway.svg';

const Wrapper = styled.span`
  display: inline-block;
  vertical-align: middle;
  font-size: 0;
  height: 24px;
  width: 24px;
`;

export const NorwegianFlag = () => {
  return (
    <Wrapper>
      <FlagNorway />
    </Wrapper>
  );
};
