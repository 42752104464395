import { TextInput } from '@loomispay/vault';
import { Control, Controller } from 'react-hook-form';

export const FormTextInput = (props: {
  name: string;
  label?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  required?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  placeholder?: string;
  defaultValue?: string;
  max?: number;
  min?: number;
  type?: 'text' | 'email' | 'url' | 'number' | 'password' | 'date';
}) => {
  return (
    <Controller
      defaultValue={props.defaultValue}
      name={props.name}
      control={props.control}
      render={_props => (
        <TextInput
          id={props.name}
          label={props.label}
          name={_props.field.name}
          onChange={_props.field.onChange}
          max={props.max}
          min={props.min}
          disabled={props.disabled}
          value={_props.field.value}
          onBlur={_props.field.onBlur}
          required={props.required ? 'required' : undefined}
          errorText={_props.fieldState.error?.message}
          placeholder={props.placeholder}
          defaultValue={props.defaultValue}
          step={'any'}
          type={props.type ? props.type : 'text'}
        />
      )}
    />
  );
};
