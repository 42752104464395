import { Grid, GridItem, Heading, Spacing } from '@loomispay/vault';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ContactType, StatusCode } from '../../../api/types';
import { Permission, useUserPermissions } from '../../../permissions';
import { formatISODate } from '../../../utils/formatters/dateFormatters';
import { toTitleCase } from '../../../utils/formatters/toTitleCase';
import { EditBankDetails } from '../bank-details/EditBankDetails';
import { useMerchantIdFromUrl } from '../merchant-hooks';
import { ContactPersonSection, Spaced, TitledList, Wrapper } from './MerchantScreen.styles';
import { useMerchant } from '../merchantReporting-hooks';

const ContentWrapper = styled.div`
  overflow: hidden;
`;

export const MerchantDetails = () => {
  return (
    <ContentWrapper>
      <Grid gutter={0}>
        <GridItem l={12} m={8} s={4}>
          <Spacing bottom={'4'}>
            <ContactPerson />
          </Spacing>
          <Grid gutter={0}>
            <BankDetails />
            <Representatives />
            <LocationDetails />
            <Status />
            <BusinessDetails />
            <Contract />
            <InvoicingContact />
          </Grid>
        </GridItem>
      </Grid>
    </ContentWrapper>
  );
};

const Representatives = () => {
  const { t } = useTranslation();
  const { data } = useMerchant(useMerchantIdFromUrl());
  return (
    <GridItem l={6} m={4} s={4}>
      <Spacing right={'8'}>
        <TitledList
          data-testid={`sale-representative`}
          key={`sale-representative`}
          header={`Sale representative`}
          icon={'passport'}
        >
          <Wrapper>
            <Spaced title={t('merchant.details.full-name')} value={data?.sales?.contactPersonFullName}></Spaced>
            <Spaced title={t('merchant.details.email')} value={data?.sales?.contactPersonEmail}></Spaced>
          </Wrapper>
        </TitledList>
      </Spacing>
    </GridItem>
  );
};

const BankDetails = () => {
  const { permissions } = useUserPermissions();

  const { t } = useTranslation();
  const { data } = useMerchant(useMerchantIdFromUrl());

  const bankDetails = data?.merchant.bankAccount;

  const statusCode = data?.merchant.status?.code;
  const onboardingStep = data?.onboarding?.step;
  const isUpdatable =
    statusCode &&
    statusCode !== StatusCode.DEACTIVATED &&
    statusCode !== StatusCode.OFFBOARDED &&
    statusCode !== StatusCode.ANNULLED &&
    onboardingStep &&
    onboardingStep >= 40 &&
    data.merchant.bankAccount?.currency !== null;

  return (
    <GridItem l={6} m={4} s={4}>
      <Spacing right={'4'}>
        <TitledList data-testid={'bank-details'} header={'Bank details'} icon={'bank'}>
          {bankDetails && isUpdatable && permissions.includes(Permission.WRITE_BANK_DETAILS) && (
            <div
              style={{
                position: 'relative',
              }}
            >
              <EditBankDetails elasticMerchant={data} />
            </div>
          )}
          <Wrapper>
            <Spaced title={t('merchant.details.account-owner')} value={data?.merchant.bankAccount?.owner}></Spaced>
            <Spaced
              title={t('merchant.details.account-number')}
              value={data?.merchant.bankAccount?.accountNumber}
            ></Spaced>
            <Spaced title={t('merchant.details.clearing-number')} value={data?.merchant.bankAccount?.bankCode}></Spaced>
          </Wrapper>
        </TitledList>
      </Spacing>
    </GridItem>
  );
};

const ContactPerson = () => {
  const { t } = useTranslation();
  const { data } = useMerchant(useMerchantIdFromUrl());
  const contactPerson = data?.merchant.contacts?.filter(contact => {
    return contact.type === ContactType.DEFAULT;
  })[0];

  return (
    <ContactPersonSection>
      <Grid gutter={0}>
        <GridItem l={6} m={4} s={4}>
          <Heading size={'l'}>{t('merchant.details.contact-person')}</Heading>
        </GridItem>
        <GridItem l={6} m={4} s={4}>
          <Wrapper>
            <Spaced title={t('merchant.details.full-name')} value={contactPerson?.name}></Spaced>
            <Spaced title={t('merchant.details.email')} value={contactPerson?.email}></Spaced>
            <Spaced title={t('merchant.details.phone')} value={contactPerson?.phoneNumber}></Spaced>
          </Wrapper>
        </GridItem>
      </Grid>
    </ContactPersonSection>
  );
};

const LocationDetails = () => {
  const { t } = useTranslation();
  const { data } = useMerchant(useMerchantIdFromUrl());

  let countryName;
  switch (data?.merchant.address?.country) {
    case 'SE':
      countryName = 'Sweden';
      break;
    case 'DK':
      countryName = 'Denmark';
      break;
    case 'ES':
      countryName = 'Spain';
      break;
    case 'NO':
      countryName = 'Norway';
      break;
    default:
      countryName = '-';
  }

  return (
    <GridItem l={6} m={4} s={4}>
      <Spacing right={'4'}>
        <TitledList data-testid={'location-details'} header={'Location details'} icon={'location'}>
          <Wrapper>
            <Spaced title={t('merchant.details.location.address')} value={data?.merchant.address?.address}></Spaced>
            <Spaced title={t('merchant.details.location.zip-code')} value={data?.merchant.address?.zipCode}></Spaced>
            <Spaced title={t('merchant.details.location.city')} value={data?.merchant.address?.city}></Spaced>
            <Spaced title={t('merchant.details.location.country')} value={countryName}></Spaced>
          </Wrapper>
        </TitledList>
      </Spacing>
    </GridItem>
  );
};

const BusinessDetails = () => {
  const { t } = useTranslation();
  const { data } = useMerchant(useMerchantIdFromUrl());

  const formattedBusinessCategory = data?.merchant.businessCategory
    ? toTitleCase(data?.merchant.businessCategory)
    : null;
  const formattedBusinessSubCategory = data?.merchant.businessSubCategory
    ? toTitleCase(data?.merchant.businessSubCategory)
    : null;

  return (
    <GridItem l={6} m={4} s={4}>
      <Spacing right={'4'}>
        <TitledList data-testid={'business-details'} header={'Business details'} icon={'checkmark'}>
          <Wrapper>
            <Spaced title={t('merchant.details.business.category')} value={formattedBusinessCategory}></Spaced>
            <Spaced title={t('merchant.details.business.subcategory')} value={formattedBusinessSubCategory}></Spaced>
            <Spaced
              title={t('merchant.details.business.registrationIdentifier')}
              value={data?.merchant.registrationIdentifier}
            ></Spaced>
          </Wrapper>
        </TitledList>
      </Spacing>
    </GridItem>
  );
};

const InvoicingContact = () => {
  const { t } = useTranslation();
  const { data } = useMerchant(useMerchantIdFromUrl());
  const contact = data?.merchant.contacts?.filter(contact => {
    return contact.type === ContactType.INVOICE;
  })[0];

  return (
    <GridItem l={6} m={4} s={4}>
      <Spacing right={'4'}>
        <TitledList data-testid={'invoicing-contact'} header={'Invoicing contact'} icon={'receiptInvoice'}>
          <Wrapper>
            <Spaced title={t('merchant.details.invoicing.email')} value={contact?.email}></Spaced>
          </Wrapper>
        </TitledList>
      </Spacing>
    </GridItem>
  );
};

export const Status = () => {
  const { t } = useTranslation();
  const { data } = useMerchant(useMerchantIdFromUrl());

  return (
    <GridItem l={6} m={4} s={4}>
      <Spacing right={'4'}>
        <TitledList data-testid={'status'} header={'Status'} icon={'settings'}>
          <Wrapper>
            <Spaced
              title={t('merchant.details.status.offer-sent')}
              value={formatISODate(data?.onboarding?.offerSentAt ? data?.onboarding?.offerSentAt?.toString() : null)}
            ></Spaced>
            <Spaced title={t('merchant.details.status.code')} value={data?.merchant.status?.code}></Spaced>
            <Spaced
              title={t('merchant.details.status.timestamp')}
              value={formatISODate(
                data?.merchant.status?.timestamp ? data?.merchant.status?.timestamp?.toString() : null
              )}
            ></Spaced>
          </Wrapper>
        </TitledList>
      </Spacing>
    </GridItem>
  );
};

export const Contract = () => {
  const { t } = useTranslation();
  const { data } = useMerchant(useMerchantIdFromUrl());

  return (
    <GridItem l={6} m={4} s={4}>
      <Spacing right={'4'}>
        <TitledList data-testid={'contract'} header={'Contract'} icon={'contract'}>
          <Wrapper>
            <Spaced
              title={t('merchant.details.contract.start-date')}
              value={formatISODate(data?.contract?.startDate ? data?.contract?.startDate?.toString() : null)}
            ></Spaced>
            <Spaced
              title={t('merchant.details.contract.end-date')}
              value={formatISODate(data?.contract?.endDate ? data?.contract?.endDate?.toString() : null)}
            ></Spaced>
            <Spaced
              title={t('merchant.details.contract.free-months')}
              value={data?.contract?.freeTerminalSubscriptionMonths?.toString()}
            ></Spaced>
          </Wrapper>
        </TitledList>
      </Spacing>
    </GridItem>
  );
};
