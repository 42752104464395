import { VaultProvider } from '@loomispay/vault';
import { BrowserRouter, useHistory } from 'react-router-dom';
import App from './App';

export const AppProvider = () => {
  return (
    <BrowserRouter>
      <AppWithRouter />
    </BrowserRouter>
  );
};

const AppWithRouter = () => {
  const history = useHistory();
  return (
    <VaultProvider history={history}>
      <App />
    </VaultProvider>
  );
};
