import { CardCustomerType, CardScheme, CardType, Region } from 'api/types';

import { Table } from '@loomispay/vault';
import { fetchByUrl } from 'api';
import { Currency } from 'components/invoices/InvoicesTable';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { toTitleCase } from 'utils/formatters/toTitleCase';
import { EditDefaultRule } from './EditDefaultRule';
import { EditRule } from './EditRule';
import { EditAmexRule } from './EditAmexRule';
import { CountryCode } from './FeesScreen';
import { ReactComponent as Mastercard } from './Icons/Mastercard.svg';
import { ReactComponent as Visa } from './Icons/Visa.svg';
import { ReactComponent as Amex } from './Icons/Amex.svg';
import { ReactComponent as BankAxept } from './Icons/BankAxept.svg';

export interface DefaultTransactionFeeRule extends Record<string, unknown> {
  countryCode: CountryCode;
  cardPresent: boolean;
  percentage: {
    default: number;
    min: number;
    max: number;
  };
  fixedFeeAmount?: {
    default: number;
    min: number;
    max: number;
  };
  currency: Currency;
}

export interface TransactionFeeRule extends Record<string, unknown> {
  id: string;
  countryCode: CountryCode;
  region: Region;
  cardCustomerType: CardCustomerType;
  cardScheme: CardScheme;
  cardType: CardType;
  percentage: {
    default: number;
    min: number;
    max: number;
  };
  fixedFeeAmount?: {
    default: number;
    min: number;
    max: number;
  };
  currency: Currency;
}

export interface AmexTransactionFeeRule extends Record<string, unknown> {
  id: string;
  countryCode: CountryCode;
  currency: Currency;
  percentage: {
    min: number;
    max: number;
    default: number;
  };
}

type TableData = TransactionFeeRule | DefaultTransactionFeeRule | AmexTransactionFeeRule;

interface TransactionFeeRuleResponse {
  transactionFeeRules: TransactionFeeRule[];
  isError: boolean;
  refresh: () => void;
}

interface AmexTransactionFeeRuleResponse {
  amexTransactionFeeRule?: AmexTransactionFeeRule;
  isError: boolean;
  refreshAmex: () => void;
}

interface DefaultTransactionFeeRuleResponse {
  defaultTransactionFeeRule?: DefaultTransactionFeeRule;
  isError: boolean;
  refresh: () => void;
}

export const GetTransactionFeeRules = (country: string, cardPresent: boolean): TransactionFeeRuleResponse => {
  const { error, data, mutate } = useSWR<TransactionFeeRule[]>(
    [`/transaction-fee-rules?country=${country}&cardPresent=${cardPresent}`],
    url => fetchByUrl(url),
    { refreshInterval: 10000 }
  );

  return {
    transactionFeeRules: data || [],
    isError: error,
    refresh: mutate,
  };
};

export const GetDefaultTransactionFeeRule = (
  countryCode: CountryCode,
  cardPresent: boolean
): DefaultTransactionFeeRuleResponse => {
  const { error, data, mutate } = useSWR<DefaultTransactionFeeRule>(
    [`/default-transaction-fee-rules?country=${countryCode}&cardPresent=${cardPresent}`],
    url => fetchByUrl(url),
    { refreshInterval: 10000 }
  );

  return {
    defaultTransactionFeeRule: data,
    isError: error,
    refresh: mutate,
  };
};

export const GetAmexTransactionFeeRule = (countryCode: CountryCode): AmexTransactionFeeRuleResponse => {
  const { error, data, mutate } = useSWR<AmexTransactionFeeRule>(
    [`/amex-transaction-fee-rules?country=${countryCode}`],
    url => fetchByUrl(url),
    { refreshInterval: 10000 }
  );

  return {
    amexTransactionFeeRule: data,
    isError: error,
    refreshAmex: mutate,
  };
};

export const DataTable = (props: { country: CountryCode; cardPresent: boolean }) => {
  const { t } = useTranslation();
  const formatPercentageFee = (minFee: number, maxFee: number, defaultFee: number) => {
    return t('fees.table.percentageFee-label', {
      minFee: minFee,
      maxFee: maxFee,
      defaultFee: defaultFee,
    });
  };

  const formatFixedFee = (currency: Currency, minFee: number, maxFee: number, defaultFee: number) => {
    return t('fees.table.fixedFee-label', {
      currency: currency,
      minFee: minFee,
      maxFee: maxFee,
      defaultFee: defaultFee,
    });
  };

  const columns = [
    {
      Header: t('fees.table.card-scheme'),
      accessor: 'cardScheme',
      width: 140,
      Cell: (props: { value: string }) => {
        if (props.value != null)
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {props.value === 'MASTERCARD' ? (
                <Mastercard style={{ paddingRight: '8px' }} />
              ) : props.value === 'VISA' ? (
                <Visa style={{ paddingRight: '8px' }} />
              ) : props.value === 'AMEX' ? (
                <Amex style={{ paddingRight: '8px' }} />
              ) : props.value === 'BANK_AXEPT' ? (
                <BankAxept style={{ paddingRight: '8px' }} />
              ) : null}
              {toTitleCase(props.value)}
            </div>
          );
        else return null;
      },
    },
    {
      Header: t('fees.table.region'),
      accessor: 'region',
      width: 120,
      Cell: (props: { value: string }) => {
        return props.value != null ? <div>{toTitleCase(props.value)}</div> : null;
      },
    },

    {
      Header: t('fees.table.card-type'),
      accessor: 'cardType',
      width: 100,
      Cell: (props: { value: string }) => {
        return props.value != null ? <div>{toTitleCase(props.value)}</div> : null;
      },
    },
    {
      Header: t('fees.table.card-customer-type'),
      accessor: 'cardCustomerType',
      Cell: (props: { value: string }) => {
        return props.value != null ? <div>{toTitleCase(props.value)}</div> : null;
      },
    },
    {
      Header: t('fees.table.percentageFee'),
      accessor: 'percentageFee',
      width: 180,
    },
    {
      Header: t('fees.table.fixedFee'),
      accessor: 'fixedFee',
      width: 200,
    },
    {
      accessor: 'confButton',
      width: 50,
    },
  ];

  const resFeeRules = GetTransactionFeeRules(props.country, props.cardPresent);

  const resDefaultFeeRules = GetDefaultTransactionFeeRule(props.country, props.cardPresent);

  const resAmexFeeRule = GetAmexTransactionFeeRule(props.country);

  if (resFeeRules.isError || resDefaultFeeRules.isError) return <div>{t('fees.table.error-fetching-data')}</div>;

  const createDataTable = (
    feeRules: TransactionFeeRule[],
    defaultFeeRule?: DefaultTransactionFeeRule,
    amexFeeRule?: AmexTransactionFeeRule
  ): TableData[] => {
    const tableData = [];
    feeRules.forEach(it => {
      tableData.push({
        ...it,
        percentageFee: formatPercentageFee(it.percentage.min, it.percentage.max, it.percentage.default),
        fixedFee: formatFixedFee(
          it.currency,
          Number(it.fixedFeeAmount?.min),
          Number(it.fixedFeeAmount?.max),
          Number(it.fixedFeeAmount?.default)
        ),
        confButton: <EditRule editFeeRule={it} refresh={resFeeRules.refresh} cardPresent={props.cardPresent} />,
      });
    });

    if (defaultFeeRule) {
      tableData.push({
        ...defaultFeeRule,
        percentageFee: formatPercentageFee(
          defaultFeeRule.percentage.min,
          defaultFeeRule.percentage.max,
          defaultFeeRule.percentage.default
        ),
        fixedFee: formatFixedFee(
          defaultFeeRule.currency,
          Number(defaultFeeRule.fixedFeeAmount?.min),
          Number(defaultFeeRule.fixedFeeAmount?.max),
          Number(defaultFeeRule.fixedFeeAmount?.default)
        ),
        cardScheme: t('fees.table.default-fee'),
        confButton: (
          <EditDefaultRule rule={defaultFeeRule} cardPresent={props.cardPresent} refresh={resDefaultFeeRules.refresh} />
        ),
      });
    }

    if (props.cardPresent && amexFeeRule) {
      tableData.push({
        ...amexFeeRule,
        percentageFee: formatPercentageFee(
          amexFeeRule.percentage.min,
          amexFeeRule.percentage.max,
          amexFeeRule.percentage.default
        ),
        region: 'n/a',
        cardCustomerType: 'n/a',
        cardType: 'n/a',
        cardScheme: 'AMEX',
        cardPresent: true,
        confButton: <EditAmexRule rule={amexFeeRule} refresh={resAmexFeeRule.refreshAmex} />,
      });
    }
    return tableData;
  };

  const data: TableData[] = createDataTable(
    resFeeRules.transactionFeeRules,
    resDefaultFeeRules.defaultTransactionFeeRule,
    resAmexFeeRule.amexTransactionFeeRule
  );

  return (
    <div>
      <Table columns={columns} data={data} />
    </div>
  );
};
