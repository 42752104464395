import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Row } from '@tanstack/react-table';
import { Transaction, TransactionStatus, TransactionType } from './TransactionModel';
import { DeclineRequestButton } from './decline-button/DeclineRequestButton';

interface Props {
  row: Row<Transaction>;
}

export const MerchantTransactionExpanded: React.FunctionComponent<Props> = ({ row }: Props) => {
  const { t } = useTranslation();
  const isRefund = row.original.type === TransactionType.REFUND;
  const isOnHold = row.original.status === TransactionStatus.ON_HOLD;
  const buttonForOnHoldRefund = () => {
    if (isRefund && isOnHold) return <DeclineRequestButton transactionId={row.original.transactionId} />;
  };
  return (
    <ExpandedTransaction>
      <ExpandedTransactionRow>
        <ExpandedTransactionLabel>{t('merchant.transactions.transactionId')}</ExpandedTransactionLabel>
        <ExpandedTransactionValue>{row.original.transactionId}</ExpandedTransactionValue>
      </ExpandedTransactionRow>
      <ExpandedTransactionRow>
        <ExpandedTransactionLabel>{t('merchant.transactions.storeId')}</ExpandedTransactionLabel>
        <ExpandedTransactionValue>{row.original.storeId}</ExpandedTransactionValue>
      </ExpandedTransactionRow>
      <ExpandedTransactionRow>
        <ExpandedTransactionLabel>{t('merchant.transactions.terminalId')}</ExpandedTransactionLabel>
        <ExpandedTransactionValue>{row.original.terminalId}</ExpandedTransactionValue>
      </ExpandedTransactionRow>
      <ExpandedTransactionRow>
        <ExpandedTransactionLabel>{t('merchant.transactions.paymentMethod')}</ExpandedTransactionLabel>
        <ExpandedTransactionValue>{row.original.paymentMethod}</ExpandedTransactionValue>
      </ExpandedTransactionRow>
      <ExpandedTransactionRow>
        <ExpandedTransactionLabel>{t('merchant.transactions.cardType')}</ExpandedTransactionLabel>
        <ExpandedTransactionValue>{row.original.cardType}</ExpandedTransactionValue>
      </ExpandedTransactionRow>
      <ExpandedTransactionRow>
        <ExpandedTransactionLabel>{t('merchant.transactions.issuingBank')}</ExpandedTransactionLabel>
        <ExpandedTransactionValue>{row.original.issuingBank}</ExpandedTransactionValue>
      </ExpandedTransactionRow>
      <ExpandedTransactionRow>
        <ExpandedTransactionLabel>{t('merchant.transactions.issuingCountry')}</ExpandedTransactionLabel>
        <ExpandedTransactionValue>{row.original.issuingCountry}</ExpandedTransactionValue>
      </ExpandedTransactionRow>
      {buttonForOnHoldRefund()}
    </ExpandedTransaction>
  );
};

const ExpandedTransaction = styled.div`
  font-size: 14px;
  background-color: hsla(0, 0%, 0%, 0.1);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 15rem;
`;

const ExpandedTransactionRow = styled.div`
  display: flex;
  flex-direction: row;
  color: hsl(0, 0%, 35%);
`;

const ExpandedTransactionLabel = styled.p`
  width: 8rem;
`;

const ExpandedTransactionValue = styled.p`
  font-weight: 600;
`;
