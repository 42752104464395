import 'react-datepicker/dist/react-datepicker.css';

import { Button, Display, Label } from '@loomispay/vault';
import { FlexCol, FlexDiv, LabelWrapper } from './styles';

import { zodResolver } from '@hookform/resolvers/zod';
import { MonthYearPicker } from 'components/DatePicker';
import { FormSelect } from 'components/Select';
import saveAs from 'file-saver';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CountryCode } from 'routes/fees/AddNewRule';
import { getCountryFromCountryCode } from 'routes/fees/FeesScreen';
import { fetchFromBackend } from 'utils/fetch';
import { createToast } from 'vercel-toast';
import { z } from 'zod';

const formSchema = z.object({
  country: z.nativeEnum(CountryCode),
  reportDate: z.date(),
});

type FormInputSchema = z.infer<typeof formSchema>;

const FinanceReports = () => {
  const { t } = useTranslation();
  const [isFetching, setIsFetching] = useState(false);

  const { control, handleSubmit } = useForm<FormInputSchema>({
    defaultValues: {
      country: undefined,
      reportDate: undefined,
    },
    resolver: zodResolver(formSchema),
  });

  const onSubmit = async (data: FormInputSchema) => {
    const month = data.reportDate?.getMonth() + 1;
    const year = data.reportDate?.getFullYear();
    const url = `/reports/sales?month=${month}&year=${year}&country=${data.country}`;
    setIsFetching(true);
    const response = await fetchFromBackend(url);

    if (response.ok) {
      const blob = await response.blob();
      saveAs(blob, `Sales-report-${month}-${year}.csv`);
    } else {
      createToast(t('reports.sales.error'), {
        timeout: 6000,
        type: 'error',
      });
    }
    setIsFetching(false);
  };

  return (
    <div>
      <Display size="s">{t('reports.sales.title')}</Display>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FlexDiv>
          <FlexCol>
            <LabelWrapper>
              <Label size={'s'}>{t('reports.sales.month')}</Label>
            </LabelWrapper>
            <MonthYearPicker control={control} name="reportDate" />
          </FlexCol>
          <FlexCol>
            <FormSelect
              options={Object.values(CountryCode).map(it => ({
                value: it,
                label: <div>{getCountryFromCountryCode(it, t)}</div>,
              }))}
              label={t('reports.sales.country')}
              name="country"
              control={control}
            />
          </FlexCol>
        </FlexDiv>
        <div>
          <Button type={'submit'} label={t('reports.sales.download')} disabled={isFetching} />
        </div>
      </form>
    </div>
  );
};

export default FinanceReports;
