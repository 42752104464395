import { hasAnyPermission, Permission, useUserPermissions } from 'permissions';
import { useTranslation } from 'react-i18next';
import { MenuItem } from 'routes/NavigationBar';

export const useFeesMenuItems = (): MenuItem[] => {
  const { t } = useTranslation();
  const { permissions } = useUserPermissions();

  if (!hasAnyPermission(permissions, [Permission.READ_FEES, Permission.WRITE_FEES])) {
    return [];
  }

  return [
    {
      id: 'fees',
      href: undefined,
      title: t('navigation.fees'),
      icon: 'cash',
      subMenuItems: [
        {
          id: 'sweden',
          href: '/fees/SE',
          title: t('countries.sweden'),
        },
        {
          id: 'denmark',
          href: '/fees/DK',
          title: t('countries.denmark'),
        },
        {
          id: 'spain',
          href: '/fees/ES',
          title: t('countries.spain'),
        },
        {
          id: 'norway',
          href: '/fees/NO',
          title: t('countries.norway'),
        },
      ],
    },
  ];
};
