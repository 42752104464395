import { fetchByUrl } from 'api';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { StatusCode, StoresResponse } from '../../api/types';

export interface MerchantStatusResponse extends Record<string, unknown> {
  merchantId: string;
  status: StatusCode | null;
  lastTransactionOccurredAt: string | null;
  numberOfInvoices: number | null;
  canBeDeactivated: boolean;
  amex: AmexOnboardingStatus;
}

interface AmexOnboardingStatus {
  onboardingPossibility: boolean;
  feePercentage: number | null;
  allStoresOnboarded: boolean;
}

export const useMerchantStatus = () => {
  const { id } = useParams<{ id: string }>();

  return useSWR<MerchantStatusResponse>([`/merchants/${id}/status`], url => fetchByUrl(url), {
    refreshInterval: 10000,
  });
};

export const useMerchantStores = () => {
  const { id } = useParams<{ id: string }>();

  return useSWR<StoresResponse>([`/merchants/${id}/stores`], url => fetchByUrl(url), {
    refreshInterval: 10000,
  });
};

type RouteParameters = {
  id: string;
};

export const useMerchantIdFromUrl = () => useParams<RouteParameters>().id;
