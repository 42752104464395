import { Button, Heading, Label, Spacing, Text } from '@loomispay/vault';
import { Merchant } from 'components/Merchant';
import { Modal } from 'components/Modal';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { createToast } from 'vercel-toast';
import { fetchByUrl } from '../../api';
import { Permission, useUserPermissions } from '../../permissions';
import { useRequireAttentionMerchants } from './MerchantStatusScreen';

export const AcquirerOnboardingFailedModal = (props: { selectedMerchantId: string | null; closeModal: () => void }) => {
  return (
    <Modal isOpen={props.selectedMerchantId !== null} close={() => props.closeModal()} closeButton>
      {props.selectedMerchantId && <ModalContent merchantId={props.selectedMerchantId} />}
    </Modal>
  );
};

const FlexDiv = styled.div`
  display: flex;
`;

const Right = styled.div`
  margin-left: auto;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: 'flex-end';
  margin-top: 'auto';
  margin-left: 'auto';
`;

const Spacer = styled.div`
  padding-top: '2rem
`;

const ModalWrapper = styled.div`
  display: 'flex';
  height: '100%';
  flex-direction: 'column';
`;

const NoMargin = styled.div`
  margin: 0;
`;

const ModalContent = (props: { merchantId: string }) => {
  const { data } = useRequireAttentionMerchants();
  const { t } = useTranslation();

  const merchant = data?.requireAttentionMerchantDTOs?.find(m => m.merchantId === props.merchantId);

  return (
    <ModalWrapper>
      <NoMargin>
        <Heading size="m">
          {t('merchant.details.status.acquirer-onboarding.merchant')} '
          <Merchant merchantId={props.merchantId} clickable={false} />'{' '}
          {t('merchant.details.status.acquirer-onboarding.could-not-be-onboarded')}
        </Heading>
        <Text>{t('merchant.details.status.acquirer-onboarding.description')}</Text>
        <Spacer />
        <Heading size="xs" sansSerif>
          {t('merchant.details.status.acquirer-onboarding.alert-reason')}
        </Heading>
        <Text color="secondary">{merchant?.reason}</Text>
      </NoMargin>
      <ButtonWrapper>
        <IgnoreAcquirerOnboardingButton merchantId={props.merchantId} />
        <RetryAcquirerOnboardingButton merchantId={props.merchantId} />
      </ButtonWrapper>
    </ModalWrapper>
  );
};

const RetryAcquirerOnboardingButton = (props: { merchantId: string }) => {
  const { t } = useTranslation();
  const { permissions } = useUserPermissions();
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState<boolean>(false);

  const retryAcquirerOnboarding = async () => {
    const response = await fetchByUrl(`/merchants/status/${props.merchantId}/retry-acquirer-onboarding`, {
      method: 'POST',
    });
    if (response.ok) {
      createToast(t('merchant.details.status.acquirer-onboarding.initiated'), {
        timeout: 6000,
        type: 'success',
      });
    } else if (response.status > 500) {
      createToast(t('unexpected-error'), {
        timeout: 6000,
        type: 'error',
      });
    } else if (response.status >= 400) {
      createToast(t('bad-data-format'), {
        timeout: 6000,
        type: 'error',
      });
    }
    setConfirmationDialogOpen(false);
  };

  return (
    <>
      <Button
        label={t('merchant.status.table.status.stuck.action')}
        variant={'primary'}
        disabled={!permissions.includes(Permission.RETRY_MERCHANT_ACQUIRER_ONBOARDING)}
        onClick={() => {
          setConfirmationDialogOpen(true);
        }}
      />
      <ConfirmationDialog
        onConfirm={retryAcquirerOnboarding}
        onCancel={() => setConfirmationDialogOpen(false)}
        isOpen={confirmationDialogOpen}
        text={t('merchant.status.retry-acquirer-onboarding')}
      />
    </>
  );
};

const IgnoreAcquirerOnboardingButton = (props: { merchantId: string }) => {
  const { t } = useTranslation();
  const { permissions } = useUserPermissions();
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState<boolean>(false);

  const ignoreAcquirerOnboarding = async () => {
    const response = await fetchByUrl(`/merchants/status/${props.merchantId}/ignore-acquirer-onboarding`, {
      method: 'POST',
    });
    if (response.ok) {
      createToast(t('merchant.details.status.acquirer-onboarding.ignoring'), {
        timeout: 6000,
        type: 'success',
      });
    } else if (response.status > 500) {
      createToast(t('unexpected-error'), {
        timeout: 6000,
        type: 'error',
      });
    } else if (response.status >= 400) {
      createToast(t('bad-data-format'), {
        timeout: 6000,
        type: 'error',
      });
    }
    setConfirmationDialogOpen(false);
  };

  return (
    <>
      <Button
        label={t('merchant.status.table.status.stuck.ignore')}
        variant={'destructive'}
        disabled={!permissions.includes(Permission.IGNORE_MERCHANT_ACQUIRER_ONBOARDING)}
        onClick={() => {
          setConfirmationDialogOpen(true);
        }}
      />
      <ConfirmationDialog
        onConfirm={ignoreAcquirerOnboarding}
        onCancel={() => setConfirmationDialogOpen(false)}
        isOpen={confirmationDialogOpen}
        text={t('merchant.status.ignore-acquirer-onboarding')}
      />
    </>
  );
};

const ConfirmationDialog: React.FunctionComponent<{
  text: string;
  onConfirm: () => Promise<void>;
  onCancel: () => void;
  isOpen: boolean;
}> = ({ text, onConfirm, onCancel, isOpen }) => {
  const { t } = useTranslation();
  const [confirmPressed, setConfirmPressed] = useState(false);
  return (
    <Modal isOpen={isOpen} close={onCancel}>
      <Label>{text}</Label>
      <FlexDiv>
        <Right />
        <Button label={t('confirmation-dialog.cancel')} variant={'tertiary'} onClick={onCancel} />
        <Spacing right="4" />
        <Button
          label={t('confirmation-dialog.confirm')}
          variant={'primary'}
          disabled={confirmPressed}
          onClick={async () => {
            setConfirmPressed(true);
            await onConfirm();
            setConfirmPressed(false);
          }}
        />
      </FlexDiv>
    </Modal>
  );
};
