import { Button, Label, Spacing, Text, TextInput } from '@loomispay/vault';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Currency } from '../../../components/invoices/InvoicesTable';
import { z } from 'zod';
import { FormTextInput } from '../../../components/TextInput';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { createToast } from 'vercel-toast';
import { fetchFromBackend } from '../../../utils/fetch';
import ClipLoader from 'react-spinners/ClipLoader';
import styled from 'styled-components';
import { Form } from '../../fees/helper';
import { LabelWrapper } from '../../../components/invoices/styles';
import { DatePickerWrapper } from '../../file-processing/FileProcessing.styles';
import { SingleDatePicker } from '../../../components/DatePicker';

interface Invoice {
  serialNumber: string;
  invoiceStatus: string;
  openInvoiceAmount: number;
  merchantId: string;
  currency: Currency;
}

export const InputWrapper = styled.div`
  width: 15%;
  padding-right: 0.5%;
  padding-bottom: 10px;
`;

export const InvoiceWrapper = styled.div`
  width: 90%;
  padding-right: 0.5%;
  padding-bottom: 10px;
`;

export const ManualInvoicePaymentInputWrapper = styled.div`
  width: 25%;
  padding-right: 1.5%;
  padding-bottom: 10px;
  display: inline-table;
`;

export const ManualInvoicePayment = () => {
  const { t } = useTranslation();

  const [isInvoiceFetched, setIsInvoiceFetched] = useState<boolean>(false);
  const [isInvoiceFetching, setIsInvoiceFetching] = useState<boolean>(false);
  const [invoiceOpenAmount, setInvoiceOpenAmount] = useState<string>('');
  const [invoiceMerchantId, setInvoiceMerchantId] = useState<string>('');
  const [invoiceCurrency, setInvoiceCurrency] = useState<string>('');

  const minFinancialDate = new Date(2021, 1);
  const todayFinancialDate = new Date();
  todayFinancialDate.setHours(12);

  const validateSerialNumber = (serialNumber: string) => {
    const regex = new RegExp(/^([A-Za-z]{2}-[A-Za-z]{2}-[0-9]+)$/i);
    return regex.test(serialNumber);
  };

  const serialNumberValidator = z
    .string()
    .refine(validateSerialNumber, { message: t('reconciliation.error.invalid-serial-number') });

  const InvoiceFormInputSchema = z.object({
    serialNumber: serialNumberValidator,
  });

  const ManualInvoicePaymentInputSchema = z.object({
    invoiceStatus: z
      .string()
      .refine(value => value === 'DEFERRED', { message: t('reconciliation.error.invoice-status-deferred-required') }),
    paymentAmount: z.string().refine(value => Number(value) <= Number(invoiceOpenAmount), {
      message: t('reconciliation.error.payment-amount-exceeds-invoice-open-amount'),
    }),
    serialNumber: serialNumberValidator,
    financialDate: z.date(),
  });

  type InvoiceFormInputSchemaType = z.infer<typeof InvoiceFormInputSchema>;
  type ManualInvoicePaymentInputSchemaType = z.infer<typeof ManualInvoicePaymentInputSchema>;

  const { control: searchInvoiceControl, handleSubmit: handleFetchInvoice } = useForm<InvoiceFormInputSchemaType>({
    defaultValues: {
      serialNumber: '',
    },
    resolver: zodResolver(InvoiceFormInputSchema),
  });

  const { setValue, control, handleSubmit } = useForm<ManualInvoicePaymentInputSchemaType>({
    defaultValues: {
      paymentAmount: '',
      serialNumber: '',
      invoiceStatus: '',
      financialDate: todayFinancialDate,
    },
    resolver: zodResolver(ManualInvoicePaymentInputSchema),
  });

  const fetchInvoice = async (data: InvoiceFormInputSchemaType) => {
    setIsInvoiceFetching(true);
    const response = await fetchFromBackend(`/invoices/${data.serialNumber}`);
    setIsInvoiceFetching(false);

    if (response.ok) {
      setIsInvoiceFetched(true);
      const responseBody: Invoice = await response.json();

      setInvoiceOpenAmount(responseBody.openInvoiceAmount.toString());
      setInvoiceMerchantId(responseBody.merchantId);
      setInvoiceCurrency(responseBody.currency);

      setValue('invoiceStatus', responseBody.invoiceStatus);
      setValue('paymentAmount', responseBody.openInvoiceAmount.toString());
      setValue('serialNumber', responseBody.serialNumber);

      createToast(t('reconciliation.invoice-fetched'), {
        timeout: 6000,
        type: 'success',
      });
    } else if (response.status === 404) {
      createToast(t('reconciliation.error.invoice-not-found'), {
        timeout: 6000,
        type: 'error',
      });
    } else {
      createToast(t('unexpected-error'), {
        timeout: 6000,
        type: 'error',
      });
    }
  };

  function submitInvoicePayment(data: ManualInvoicePaymentInputSchemaType): Promise<Response> {
    return fetchFromBackend(`/invoices/${data.serialNumber}/payment`, {
      method: 'POST',
      body: JSON.stringify({
        amount: data.paymentAmount,
        financialDate: data.financialDate,
      }),
    });
  }

  const onSubmit = (data: ManualInvoicePaymentInputSchemaType) => {
    submitInvoicePayment(data).then(response => {
      if (response.ok) {
        createToast('Manual invoice payment requested', {
          timeout: 6000,
          type: 'success',
        });
      } else {
        createToast(t('unexpected-error'), {
          timeout: 6000,
          type: 'error',
        });
      }
    });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <InvoiceWrapper>
        <InputWrapper>
          <FormTextInput
            name="serialNumber"
            control={searchInvoiceControl}
            label={t('reconciliation.invoice-serial-number')}
            placeholder="XX-LS-10000000"
          />
        </InputWrapper>
        <Spacing top="2" />
        <Button disabled={isInvoiceFetching} onClick={handleFetchInvoice(fetchInvoice)}>
          {!isInvoiceFetching && t('reconciliation.fetch-invoice')}
          {isInvoiceFetching && <ClipLoader size={35} color={'white'} />}
        </Button>
        <Spacing top="2" />
        <Text size="l" weight="semiBold">
          {t('reconciliation.invoice-details')}
        </Text>
        <Spacing top="2" />
        <ManualInvoicePaymentInputWrapper>
          <FormTextInput
            name="invoiceStatus"
            control={control}
            label={t('reconciliation.invoice-status')}
            disabled={true}
          />
        </ManualInvoicePaymentInputWrapper>
        <ManualInvoicePaymentInputWrapper>
          <TextInput
            id="invoiceOpenAmount"
            name="invoiceOpenAmount"
            label={t('reconciliation.open-amount')}
            disabled={true}
            value={invoiceOpenAmount.toString()}
          />
        </ManualInvoicePaymentInputWrapper>
        <ManualInvoicePaymentInputWrapper>
          <TextInput
            id="merchantId"
            name="merchantId"
            label={t('reconciliation.merchant-id')}
            disabled={true}
            value={invoiceMerchantId}
          />
        </ManualInvoicePaymentInputWrapper>
        <ManualInvoicePaymentInputWrapper>
          <TextInput
            id="invoiceCurrency"
            name="invoiceCurrency"
            label={t('reconciliation.currency')}
            disabled={true}
            value={invoiceCurrency}
          />
        </ManualInvoicePaymentInputWrapper>
        <Spacing top="2" />
        <ManualInvoicePaymentInputWrapper>
          <FormTextInput
            name="paymentAmount"
            control={control}
            label={t('reconciliation.payment-amount')}
            disabled={!isInvoiceFetched}
          />
        </ManualInvoicePaymentInputWrapper>
        <Spacing top="2" />
        <ManualInvoicePaymentInputWrapper>
          <LabelWrapper>
            <Label size={'s'}>{t('reconciliation.financial-date')}</Label>
          </LabelWrapper>
          <DatePickerWrapper>
            <SingleDatePicker
              name={'financialDate'}
              control={control}
              minDate={minFinancialDate}
              maxDate={todayFinancialDate}
              defaultValue={todayFinancialDate}
              required={true}
            />
          </DatePickerWrapper>
        </ManualInvoicePaymentInputWrapper>
        <Spacing top="2" />
        <Button type={'submit'} disabled={!isInvoiceFetched}>
          {t('reconciliation.submit')}
        </Button>
      </InvoiceWrapper>
    </Form>
  );
};
