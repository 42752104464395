import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { persistItem, retrieveItem } from 'utils/persistentStorage';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const loadInitialState = <S>(location: any, name: string, initialState: S) => {
  if (location.search.includes(name)) {
    const stateFromUrl = new URLSearchParams(location.search).get(name);
    if (stateFromUrl) {
      try {
        return JSON.parse(stateFromUrl);
      } catch (e) {
        console.log('Unable to parse state from url name:' + name);
      }
    }
  }

  const storedState = retrieveItem('state-' + name);
  if (storedState) {
    try {
      return JSON.parse(storedState);
    } catch (e) {
      console.log('Unable to parse state from persisted storage name:' + name);
    }
  }

  return initialState;
};

export const usePersistentState = <S>(name: string, initialState: S): [S, Dispatch<SetStateAction<S>>] => {
  const location = useLocation();
  const [state, setState] = useState<S>(loadInitialState(location, name, initialState));
  const history = useHistory();

  useEffect(() => {
    persistItem('state-' + name, JSON.stringify(state));

    const params = new URLSearchParams(window.location.search);
    params.set(name, JSON.stringify(state));
    history.replace({ pathname: location.pathname, search: params.toString() });
  }, [state, history, location.pathname, name]);

  return [state, setState];
};
