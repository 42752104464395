import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { useConfiguration } from '../../../configuration';
import { useMerchantStatus } from '../merchant-hooks';
import { Permission } from '../../../permissions';
import { Button } from '@loomispay/vault';
import { SignerDetailsComponent } from './SignerDetailsComponent';

export const SubmitSignerDataButton = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { data } = useConfiguration();
  const merchantStatus = useMerchantStatus().data;

  const canNotSubmitSignerDetails =
    !data?.permissions.includes(Permission.ENABLE_AMEX) || merchantStatus?.amex.onboardingPossibility;
  return (
    <>
      <Button
        label={t('merchant.details.status.signer-details.button')}
        size={'s'}
        variant={'secondary'}
        disabled={canNotSubmitSignerDetails}
        onClick={() => {
          setIsOpen(true);
        }}
      />
      <SignerDetailsComponent isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};
