export const persistItem = (key: string, value: string): boolean => {
  if (localStorage) {
    localStorage.setItem(key, value);
    return true;
  } else {
    return false;
  }
};

export const retrieveItem = (key: string): string | null => {
  if (localStorage) {
    return localStorage.getItem(key);
  }
  return null;
};

export const removeItem = (key: string): boolean => {
  if (localStorage) {
    localStorage.removeItem(key);
    return true;
  }
  return false;
};

export const removeAllItems = (): boolean => {
  if (localStorage) {
    localStorage.clear();
    return true;
  }
  return false;
};
