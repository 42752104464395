import { Button, Heading, Select } from '@loomispay/vault';
import { SupportBackofficeSelect } from 'components/Select';
import { Permission, useUserPermissions } from 'permissions';
import { fetchFromBackend } from 'utils/fetch';
import {
  constructFormFixedFeeAmountsInputSchema,
  constructFormPercentagesInputSchema,
  countryToFlag,
} from './AddNewRule';
import { Channel, channelOptions, CountryCode, CountryName } from './FeesScreen';
import { CloseButton, FeesRow, FlexDiv, FlexLeftCol, FlexRightCol, Form } from './helper';

import { zodResolver } from '@hookform/resolvers/zod';
import { Modal } from 'components/Modal';
import { FormTextInput } from 'components/TextInput';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { DefaultTransactionFeeRule } from './DataTable';
import { Currency } from 'components/invoices/InvoicesTable';

export interface DefaultTransactionFeeRuleRequestBody {
  countryCode: CountryCode;
  cardPresent: boolean;
  percentage: {
    default: number;
    min: number;
    max: number;
  };
  fixedFeeAmount?: {
    default: number;
    min: number;
    max: number;
  };
  currency: Currency;
}

export const EditDefaultRule = (props: {
  rule: DefaultTransactionFeeRule;
  cardPresent: boolean;
  refresh: () => void;
}) => {
  const { permissions } = useUserPermissions();
  const [loadModal, setLoadModal] = useState<boolean>(false);

  return (
    <div>
      <Button
        size={'s'}
        variant={'tertiary'}
        disabled={!permissions.includes(Permission.WRITE_FEES)}
        onClick={() => {
          setLoadModal(true);
        }}
        icon={'edit'}
      />
      <Modal isOpen={loadModal} close={() => setLoadModal(false)}>
        <ModalContent
          rule={props.rule}
          cardPresent={props.cardPresent}
          refresh={props.refresh}
          setLoadModal={setLoadModal}
        />
      </Modal>
    </div>
  );
};

const ModalContent = (props: {
  rule: DefaultTransactionFeeRule;
  cardPresent: boolean;
  refresh: () => void;
  setLoadModal: (value: boolean) => void;
}) => {
  const { t } = useTranslation();

  const FormInputSchema = z.object({
    percentage: constructFormPercentagesInputSchema(t),
    fixedFee: constructFormFixedFeeAmountsInputSchema(t),
  });

  type FormInputSchemaType = z.infer<typeof FormInputSchema>;

  const onUpdate = async (data: FormInputSchemaType) => {
    const reqBody: DefaultTransactionFeeRuleRequestBody = {
      countryCode: props.rule.countryCode,
      cardPresent: props.rule.cardPresent,
      percentage: {
        default: Number(data.percentage.default),
        min: Number(data.percentage.min),
        max: Number(data.percentage.max),
      },
      fixedFeeAmount: {
        default: Number(data.fixedFee?.default ?? 0),
        min: Number(data.fixedFee?.min ?? 0),
        max: Number(data.fixedFee?.max ?? 0),
      },
      currency: props.rule.currency,
    };
    const response = await fetchFromBackend('/default-transaction-fee-rules/' + props.rule.countryCode, {
      method: 'PUT',
      body: JSON.stringify(reqBody),
    });
    if (response.ok) {
      props.setLoadModal(false);
      props.refresh();
    } else if (response.status >= 500) alert(t('fees.edit-default.unexpected-error'));
    else if (response.status >= 400) alert(t('fees.edit-default.updating-error'));
  };

  const { control, handleSubmit } = useForm<FormInputSchemaType>({
    defaultValues: {
      percentage: {
        default: props.rule.percentage.default.toString(),
        min: props.rule.percentage.min.toString(),
        max: props.rule.percentage.max.toString(),
      },
      fixedFee: {
        default: props.rule.fixedFeeAmount?.default?.toString(),
        min: props.rule.fixedFeeAmount?.min?.toString(),
        max: props.rule.fixedFeeAmount?.max?.toString(),
      },
    },
    resolver: zodResolver(FormInputSchema),
  });

  return (
    <>
      <CloseButton>
        <Button
          variant={'tertiary'}
          icon={'close'}
          onClick={() => {
            props.setLoadModal(false);
          }}
        />
      </CloseButton>
      <Heading size={'m'}>{t('fees.edit-default.fee-range')}</Heading>

      <Form onSubmit={handleSubmit(x => onUpdate(x))}>
        <FlexDiv>
          <FlexLeftCol>
            <SupportBackofficeSelect
              defaultValue={{
                label: (
                  <div style={{ display: 'flex' }}>
                    {countryToFlag[props.rule.countryCode]}{' '}
                    <div
                      style={{
                        paddingLeft: '8px',
                        alignSelf: 'center',
                      }}
                    >
                      {CountryName[props.rule.countryCode]}
                    </div>
                  </div>
                ),
              }}
              isDisabled
              label={t('fees.edit-default.market')}
            />
          </FlexLeftCol>
          <FlexRightCol>
            <Select
              label={t('fees.edit-default.channel')}
              isDisabled={true}
              name="cardPresent"
              defaultValue={channelOptions.find(e =>
                props.cardPresent ? e.value === Channel.CARD_PRESENT : e.value === Channel.CARD_NOT_PRESENT
              )}
              options={channelOptions}
            ></Select>
          </FlexRightCol>
        </FlexDiv>
        <FlexDiv>
          <FeesRow>
            <FormTextInput
              name="percentage.min"
              control={control}
              label={t('fees.edit-default.minimum')}
              required={true}
            />
            <FormTextInput
              name="percentage.max"
              control={control}
              label={t('fees.edit-default.maximum')}
              required={true}
            />
            <FormTextInput
              name="percentage.default"
              control={control}
              label={t('fees.edit-default.default')}
              required={true}
            />
          </FeesRow>
          <FeesRow>
            <FormTextInput
              name="fixedFee.min"
              control={control}
              label={t('fees.edit-rule.fixed-minimum')}
              required={!props.cardPresent}
            />
            <FormTextInput
              name="fixedFee.max"
              control={control}
              label={t('fees.edit-rule.fixed-maximum')}
              required={!props.cardPresent}
            />
            <FormTextInput
              name="fixedFee.default"
              control={control}
              label={t('fees.edit-rule.fixed-default')}
              required={!props.cardPresent}
            />
          </FeesRow>
        </FlexDiv>
        <div style={{ float: 'right' }}>
          <Button type={'submit'} label={t('fees.edit-default.save-changes')} size={'m'} />
        </div>
      </Form>
    </>
  );
};
