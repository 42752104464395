// eslint-disable-next-line
export const toUrl = (basePath: string, args: [...any[]]) => {
  return (
    basePath +
    '?' +
    args
      .filter(arg => arg[1] !== undefined && arg[1] !== null)
      .map(arg => arg.join('='))
      .join('&')
  );
};
