import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Heading, Icon, Spacing, Text } from '@loomispay/vault';
import { Merchant } from 'components/Merchant';
import { Modal } from 'components/Modal';
import { FormTextInput } from 'components/TextInput';
import { Permission, useUserPermissions } from 'permissions';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BeatLoader } from 'react-spinners';
import useSWR from 'swr';
import { createToast } from 'vercel-toast';
import { z } from 'zod';
import { fetchByUrl } from '../../../api';
import { useMerchantIdFromUrl } from '../merchant-hooks';
import { MerchantPayoutsTable } from './MerchantPayoutsTable';

interface PayoutStatusResponse {
  isPaused: boolean;
  reason: string | null;
  statusUpdatePending: boolean;
}

const usePayoutStatus = () => {
  const merchantId = useMerchantIdFromUrl();
  return useSWR<PayoutStatusResponse>([`/merchants/status/${merchantId}/payout-status`], url => fetchByUrl(url), {
    refreshInterval: 5000,
  });
};

const MerchantPayoutScreen = () => {
  const { data: merchantPayoutStatus } = usePayoutStatus();
  const [lastMerchantPayoutStatus, setLastMerchantPayoutStatus] = useState<boolean | null>(null);

  useEffect(() => {
    if (
      merchantPayoutStatus &&
      lastMerchantPayoutStatus !== null &&
      merchantPayoutStatus.isPaused !== lastMerchantPayoutStatus
    ) {
      createToast('Merchant payout status updated', { type: 'success', timeout: 5000 });
    }

    if (merchantPayoutStatus) {
      setLastMerchantPayoutStatus(merchantPayoutStatus.isPaused);
    }
  }, [merchantPayoutStatus, lastMerchantPayoutStatus]);

  return (
    <div>
      {merchantPayoutStatus && <MerchantPayoutStatus {...merchantPayoutStatus} />}
      <Spacing top="2" />
      <MerchantPayoutsTable />
    </div>
  );
};

const MerchantPayoutStatus = (props: { isPaused: boolean; reason: string | null; statusUpdatePending: boolean }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { permissions } = useUserPermissions();

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '1rem' }}>
        {!props.isPaused && (
          <Heading size="xs" color={'positive'} noGutter>
            <Icon size={'m'} name={'checkmark'} color={'positive'} />
            Merchant payouts enabled
          </Heading>
        )}
        {props.isPaused && (
          <Heading size="xs" color={'warning'} noGutter>
            <Icon size={'m'} name={'negative'} color={'warning'} />
            Merchant payouts paused
          </Heading>
        )}
        <div style={{ paddingLeft: '1rem' }}>
          {props.statusUpdatePending ? (
            <BeatLoader size={10} />
          ) : (
            <Button
              size={'s'}
              variant={'secondary'}
              disabled={!permissions.includes(Permission.WRITE_BANK_DETAILS)}
              onClick={() => {
                setModalOpen(true);
              }}
              icon={'edit'}
            >
              {props.isPaused ? 'Unpause merchant' : 'Pause merchant'}
            </Button>
          )}
        </div>
        <Modal isOpen={modalOpen} closeButton close={() => setModalOpen(false)}>
          <MerchantStatusUpdateModalContent
            newStatusIsPaused={!props.isPaused}
            onSubmit={() => {
              setModalOpen(false);
            }}
          />
        </Modal>
      </div>
      {props.reason && <Text size="s"> Reason: {props.reason}</Text>}
    </div>
  );
};

const formSchema = z.object({
  reason: z.string().min(1).max(200),
  isPaused: z.boolean(),
});

const MerchantStatusUpdateModalContent = (props: { newStatusIsPaused: boolean; onSubmit: () => void }) => {
  const merchantId = useMerchantIdFromUrl();
  type FormInputSchemaType = z.infer<typeof formSchema>;
  const { mutate } = usePayoutStatus();

  const { t } = useTranslation();
  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      reason: '',
      isPaused: props.newStatusIsPaused,
    },
    resolver: zodResolver(formSchema),
  });

  const onSubmit = async (form: FormInputSchemaType) => {
    const request = {
      isPaused: form.isPaused,
      reason: form.reason,
    };
    const repsonse = await fetchByUrl(`/merchants/status/${merchantId}/payout-status`, {
      method: 'POST',
      body: JSON.stringify(request),
    });

    if (repsonse.ok) {
      props.onSubmit();
      mutate();
      createToast(`Initiated request to ${watch('isPaused') ? 'pause' : 'unpause'} the merchant`, {
        timeout: 6000,
        type: 'dark',
      });
    } else {
      createToast('Unable to request a payout status change', {
        timeout: 6000,
        type: 'error',
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Heading size="l">
        {watch('isPaused') ? 'Pause' : 'Unpause'} '<Merchant merchantId={merchantId} clickable={false} />' for payouts
      </Heading>
      <FormTextInput name="reason" control={control} label={'Reason for the change'} />
      <Text>The change will take up to a minute to take effect</Text>
      <div style={{ float: 'right', marginTop: 'auto', paddingTop: '1rem' }}>
        <Button type={'submit'} label={t('fees.edit-rule.save-changes')} size={'m'} />
      </div>
    </form>
  );
};

export default MerchantPayoutScreen;
