import { Button, Heading } from '@loomispay/vault';
import { Modal } from 'components/Modal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createToast } from 'vercel-toast';
import { useMerchantIdFromUrl } from '../merchant-hooks';
import { fetchFromBackend } from '../../../utils/fetch';

export const DisableAmexForStore = (props: { storeId: string; storeName: string }) => {
  const { t } = useTranslation();
  const [loadModal, setLoadModal] = useState<boolean>(false);
  const [isButtonDisabled, setButtonDisabled] = useState<boolean>(false);

  return (
    <div>
      <Button
        label={t('merchant.disable-amex.button.to-disable')}
        variant={'primary'}
        onClick={() => {
          setLoadModal(true);
        }}
        disabled={isButtonDisabled}
      />
      <Modal closeButton isOpen={loadModal} close={() => setLoadModal(false)}>
        <ModalContentForDisablingAmex
          storeName={props.storeName}
          setLoadModal={setLoadModal}
          setButtonDisabled={setButtonDisabled}
          storeId={props.storeId}
        />
      </Modal>
    </div>
  );
};

const ModalContentForDisablingAmex = (props: {
  storeId: string;
  storeName: string;
  setLoadModal: (value: boolean) => void;
  setButtonDisabled: (value: boolean) => void;
}) => {
  const { t } = useTranslation();

  const merchantId = useMerchantIdFromUrl();

  const onPress = async () => {
    const response = await fetchFromBackend(`/merchants/${merchantId}/store/${props.storeId}/disable-amex`, {
      method: 'POST',
    });
    if (!response.ok) {
      createToast(t('merchant.disable-amex.failed.request'), {
        timeout: 6000,
        type: 'error',
      });
    } else {
      props.setLoadModal(false);
      props.setButtonDisabled(true);
      createToast(t('merchant.disable-amex.successful.request'), {
        timeout: 6000,
        type: 'success',
      });
    }
  };

  return (
    <div>
      <Heading>Disable store '{props.storeName}' for Amex</Heading>
      <div style={{ float: 'right' }}>
        <Button type={'submit'} label={'Submit'} onClick={onPress} />
      </div>
    </div>
  );
};
