import { Link } from '@loomispay/vault';
import { useMerchant } from '../routes/merchants/merchantReporting-hooks';

interface MerchantProps {
  merchantId: string;
  clickable: boolean;
}

export const Merchant = (props: MerchantProps) => {
  const merchant = useMerchant(props.merchantId);

  if (props.clickable) {
    return <Link to={'/merchant/' + props.merchantId}>{merchant?.data?.merchant.companyName ?? props.merchantId}</Link>;
  } else {
    return <span>{merchant?.data?.merchant.companyName ?? props.merchantId}</span>;
  }
};
