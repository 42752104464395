import { Select } from '@loomispay/vault';
import { SelectProps } from '@loomispay/vault/build/components/Select/types';
import { FunctionComponent } from 'react';
import { Control, Controller } from 'react-hook-form';
import { OptionsOrGroups } from 'react-select';

interface SupportBackofficeSelectProps extends SelectProps {
  onSelectionChange?: (newValue?: string) => void;
}

export const SupportBackofficeSelect: FunctionComponent<SupportBackofficeSelectProps> = props => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (e: any) => {
    if (e.value) {
      props?.onSelectionChange?.(e.value as string);
    } else {
      props?.onSelectionChange?.();
    }
  };

  return <Select isSearchable={false} {...props} onChange={handleChange} />;
};

export const FormSelect = (props: {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: OptionsOrGroups<string, any>;
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue?: any;
  isMulti?: boolean;
  required?: boolean;
  disabled?: boolean;
}) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={_props => (
        <Select
          isMulti={props.isMulti}
          isSearchable={false}
          label={props.label}
          name={_props.field.name}
          onBlur={_props.field.onBlur}
          isDisabled={props.disabled || false}
          options={props.options}
          error={_props.fieldState.error?.message}
          defaultValue={props.defaultValue || props.options.find(option => option.value === _props.field.value)}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onChange={(e: any) => {
            if (props.isMulti) {
              // _props.field.onChange?.(e.map((option: { value: string }) => option.value as string).join(','));
              _props.field.onChange?.(e);
            } else if (e.value) {
              _props.field.onChange?.(e.value as string);
            } else {
              _props.field.onChange?.();
            }
          }}
          required={props.required ? 'required' : undefined}
        />
      )}
    />
  );
};
