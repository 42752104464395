import { Badge, Label } from '@loomispay/vault';

export const Description = (props: { label: string; content: string }) => {
  return (
    <div title={props.content} style={{ marginBottom: '10px' }}>
      <Badge value={'?'} />
      <Label> {props.label} </Label>
    </div>
  );
};
