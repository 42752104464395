import { Text } from '@loomispay/vault';
import styled from 'styled-components';

type Variant = 'warning' | 'success' | 'error' | 'neutral';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getColor = (theme: any, variant: Variant) => {
  switch (variant) {
    case 'warning':
      return theme.color.background.warningBg;
    case 'success':
      return theme.color.background.positiveBg;
    case 'error':
      return theme.color.background.negativeBg;
    case 'neutral':
      return theme.color.background.infoBg;
    default:
      return theme.color.background.infoBg;
  }
};

const StatusIndicatorDiv = styled.div<{
  variant: Variant;
}>`
  background-color: ${({ theme, variant }) => getColor(theme, variant)};
  display: inline-block;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  border-radius: 2rem;
`;

const StatusIndicator = (props: { variant: Variant; text: string }) => {
  return (
    <StatusIndicatorDiv variant={props.variant}>
      <Text>{props.text}</Text>
    </StatusIndicatorDiv>
  );
};

export default StatusIndicator;
