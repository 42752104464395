import { Link, Table, Text } from '@loomispay/vault';
import { File, FileStatus } from './FileProcessing.service';

import { useTranslation } from 'react-i18next';
import { formatISODateTime } from 'utils/formatters/dateFormatters';
import { toTitleCase } from 'utils/formatters/toTitleCase';
import { DownloadButton } from './DownloadButton';
import { RetryButton } from './RetryButton';

export const FileDataTable = (props: { data: File[]; refresh: () => void }) => {
  const { t } = useTranslation();

  const columns = [
    { Header: t('file-processing.table.name'), accessor: 'name' },
    { Header: t('file-processing.table.last-change'), accessor: 'receivedAt' },
    { Header: t('file-processing.table.status'), accessor: 'status' },
    { Header: t('file-processing.table.kibana-url'), accessor: 'kibana' },
    { accessor: 'retry' },
    { accessor: 'download' },
  ];

  const formatData =
    props.data.map(it => ({
      ...it,
      receivedAt: formatISODateTime(it.receivedAt),
      status: formatFileStatus(it.status),
      kibana: (
        <Link href={it.kibanaUrl} target="_blank">
          <Text>ElasticSearch</Text>
        </Link>
      ),
      retry: <RetryButton file={it} refresh={props.refresh} />,
      download: <DownloadButton file={it} />,
    })) ?? [];

  function formatFileStatus(status: FileStatus) {
    if (status != null) {
      if ([FileStatus.FAILED, FileStatus.SKIPPED].includes(status)) {
        return <Text color={'negative'}>{toTitleCase(status)}</Text>;
      }
      return <Text>{toTitleCase(status)}</Text>;
    }
    return null;
  }

  return (
    <div>
      <Table columns={columns} data={formatData} loading={!props.data} />
    </div>
  );
};
