import { useConfiguration } from '../../../configuration';
import { Permission } from '../../../permissions';
import React, { useState } from 'react';
import { Button } from '@loomispay/vault';
import { AmexFeeComponent } from './AmexFeeComponent';

export const AmexFeeButton = () => {
  const canNotSetAmexFee = !useConfiguration().data?.permissions?.includes(Permission.MANAGE_MERCHANT);
  const [buttonDisabled, setButtonDisabled] = useState(canNotSetAmexFee);
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <Button
        label={'Amex transaction fee'}
        size={'s'}
        onClick={() => {
          setOpen(true);
        }}
        variant={'secondary'}
        disabled={buttonDisabled}
      />
      <AmexFeeComponent setButtonDisabled={setButtonDisabled} isOpen={isOpen} setOpen={setOpen} />
    </>
  );
};
