import styled from 'styled-components';

export const TabsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2em;
  padding-bottom: 10px;
`;

export const TabWrapper = styled.button<{ isActive: boolean; disabled?: boolean }>`
  /* reset button styles */
  border: none;
  background-color: transparent;

  padding: 0px 0px 1px 0px;
  border-bottom-style: solid;
  border-bottom-color: ${({ isActive }) => (isActive ? 'black' : 'transparent')};

  font-weight: 200;

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export const DatePickerWrapper = styled.div`
  width: 15%;
`;
