import { Button, Heading, Text } from '@loomispay/vault';
import { Modal } from 'components/Modal';
import { Permission, useUserPermissions } from 'permissions';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchFromBackend } from 'utils/fetch';
import { CloseButton, SubHeading } from './helper';

export const DeleteRule = (props: { id: string; refresh: () => void; openModals: (t: boolean) => void }) => {
  const { t } = useTranslation();
  const { permissions } = useUserPermissions();

  const [loadModal, setLoadModal] = useState<boolean>(false);

  const onDelete = async () => {
    const response = await fetchFromBackend('/transaction-fee-rules/' + props.id, {
      method: 'DELETE',
      mode: 'cors',
    });
    if (response.ok) {
      setLoadModal(false);
      props.openModals(false);
      props.refresh();
    } else alert('There was an unnexpected error while inserting the new transaction fee rule. Please try again later');
  };

  return (
    <div>
      <Button
        size={'m'}
        variant={'destructive'}
        disabled={!permissions.includes(Permission.WRITE_FEES)}
        label={'Delete'}
        onClick={() => {
          setLoadModal(true);
        }}
      />
      <Modal isOpen={loadModal} close={() => setLoadModal(false)}>
        <CloseButton>
          <Button
            icon={'close'}
            variant={'tertiary'}
            onClick={() => {
              setLoadModal(false);
            }}
          />
        </CloseButton>
        <Heading size={'m'}>{t('fees.delete-rule.delete-fee-range')}</Heading>
        <SubHeading>
          <Text>{t('fees.delete-rule.confirm-delete-range')}</Text>
        </SubHeading>
        <div style={{ float: 'right', display: 'flex' }}>
          <div style={{ marginRight: '16px', paddingRight: '16px' }}>
            <Button label={'Cancel'} variant={'secondary'} onClick={() => setLoadModal(false)} />
          </div>
          <Button
            label={'Yes, delete'}
            variant={'primary'}
            onClick={() => {
              onDelete();
            }}
          />
        </div>
      </Modal>
    </div>
  );
};
