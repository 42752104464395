import { Text } from '@loomispay/vault';
import { Modal } from 'components/Modal';
import { EnabledFeature, useConfiguration } from 'configuration';
import { useState } from 'react';
import styled from 'styled-components';

const PageHeader = styled.div`
  clear: both;
  color: white;
  display: flex;
  cursor: pointer;
  background: #055ffa;
  width: 100%;
  padding: 0.5rem;
  padding-left: 2rem;
`;

const DevHeader = () => {
  const { data } = useConfiguration();
  const [modalOpen, setModalOpen] = useState(false);

  if (!data?.enabledFeatures.includes(EnabledFeature.DEBUG_MODE)) {
    return null;
  }

  return (
    <div>
      <PageHeader onClick={() => setModalOpen(true)}>
        <Text color="invertedPrimary" weight="semiBold">
          SUPPORT BACKOFFICE <span style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>-</span>{' '}
          {(process.env.REACT_APP_ENVIRONMENT || 'unknown').toUpperCase()}
        </Text>
      </PageHeader>
      <Modal isOpen={modalOpen} close={() => setModalOpen(false)} closeButton>
        <div>
          <Text>Permissions</Text>
          <ul>
            {data?.permissions?.map(permission => (
              <li key={permission}>
                <Text>{permission}</Text>
              </li>
            ))}
          </ul>
        </div>
      </Modal>
    </div>
  );
};

export default DevHeader;
