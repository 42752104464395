import { links } from 'config';
import { logOut } from '../App';

export const fetchFromBackend = async (path: string, init?: RequestInit): Promise<Response> => {
  const url = links.backendUrl + path;

  const response = await fetch(url, {
    ...init,
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      ...init?.headers,
    },
  });

  if (response.status === 401) {
    logOut();
  }

  return response;
};

export function fetchData<T>(url: string, errorMessage: string, callback: (data: T) => void) {
  return fetchFromBackend(url).then(async response => {
    if (response.ok) {
      const jsonBody: T = await response.json();
      callback(jsonBody);
    } else {
      throw Error(errorMessage);
    }
  });
}
