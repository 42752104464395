import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../../components/Modal';
import { Button, Label, Spacing } from '@loomispay/vault';

export const ConfirmationDialog: React.FunctionComponent<{
  text: string;
  onConfirm: () => Promise<void>;
  onCancel: () => void;
  isOpen: boolean;
}> = ({ text, onConfirm, onCancel, isOpen }) => {
  const { t } = useTranslation();
  const [confirmPressed, setConfirmPressed] = useState(false);
  return (
    <Modal isOpen={isOpen} close={onCancel}>
      <Label>{text}</Label>
      <div style={{ display: 'flex' }}>
        <div style={{ marginLeft: 'auto' }}></div>
        <Button label={t('confirmation-dialog.cancel')} variant={'tertiary'} onClick={onCancel} />
        <Spacing right="4" />
        <Button
          label={t('confirmation-dialog.confirm')}
          variant={'destructive'}
          disabled={confirmPressed}
          onClick={async () => {
            setConfirmPressed(true);
            await onConfirm();
            setConfirmPressed(false);
          }}
        />
      </div>
    </Modal>
  );
};
