export const formatDateRange = (startDate: Date | null | undefined, endDate: Date | null | undefined) => {
  let formattedRange = null;

  if (startDate && endDate) {
    formattedRange = '';
    const startDay = new Intl.DateTimeFormat('en-GB', { day: '2-digit' }).format(startDate);
    const endDay = new Intl.DateTimeFormat('en-GB', { day: '2-digit' }).format(endDate);
    const startMonth = new Intl.DateTimeFormat('en-GB', { month: 'short' }).format(startDate);
    const endMonth = new Intl.DateTimeFormat('en-GB', { month: 'short' }).format(endDate);
    const startYear = new Intl.DateTimeFormat('en-GB', { year: 'numeric' }).format(startDate);
    const endYear = new Intl.DateTimeFormat('en-GB', { year: 'numeric' }).format(endDate);

    if (startDate && endDate && startDate.getFullYear() !== endDate.getFullYear()) {
      formattedRange += startDay + ' ' + startMonth + ' ' + startYear + '-' + endDay + ' ' + endMonth + ' ' + endYear;
    } else if (startDate && endDate && startDate.getMonth() !== endDate.getMonth()) {
      formattedRange += startDay + ' ' + startMonth + '-' + endDay + ' ' + endMonth + ', ' + endYear;
    } else if (startDate && endDate && startDate.getDay() !== endDate.getDay()) {
      formattedRange += startDay + '-' + endDay + ', ' + endMonth + ' ' + endYear;
    } else {
      formattedRange += endDay + ' ' + endMonth + ' ' + endYear;
    }
    return formattedRange;
  }
  return formattedRange;
};
