import { Button, Heading, Select } from '@loomispay/vault';
import { Permission, useUserPermissions } from 'permissions';
import { fetchFromBackend } from 'utils/fetch';
import { countryToFlag } from './AddNewRule';
import { CountryCode, CountryName } from './FeesScreen';
import { CloseButton, FeesRow, FlexDiv, FlexLeftCol, Form } from './helper';

import { zodResolver } from '@hookform/resolvers/zod';
import { Modal } from 'components/Modal';
import { FormTextInput } from 'components/TextInput';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z, ZodIssueCode } from 'zod';

export const AddNewAmexRule = (props: { refresh: () => void; countryCode: CountryCode; cardPresent: boolean }) => {
  const { t } = useTranslation();
  const { permissions } = useUserPermissions();

  const [loadModal, setLoadModal] = useState<boolean>(false);

  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <Button
        iconPosition={'left'}
        icon={'plus'}
        size={'m'}
        label={t('fees.new-amex-rule.add-new')}
        disabled={!permissions.includes(Permission.WRITE_FEES) || !props.cardPresent}
        onClick={() => {
          setLoadModal(true);
        }}
      />

      <Modal isOpen={loadModal} close={() => setLoadModal(false)}>
        <ModalContent refresh={props.refresh} setLoadModal={setLoadModal} countryCode={props.countryCode} />
      </Modal>
    </div>
  );
};

interface AmexTransactionFeeRuleRequest extends Record<string, unknown> {
  countryCode: CountryCode;
  percentage: {
    min: number;
    max: number;
    default: number;
  };
}

const constructAmexFormPercentagesInputSchema = (t: (message: string) => string) => {
  return z
    .object({
      min: z.string().min(1),
      max: z.string().min(1),
      default: z.string().min(1),
    })
    .superRefine((val, ctx) => {
      if (Number(val.min) > Number(val.max)) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          message: t('fees.validation.invalid-range-min'),
          path: ['min'],
        });
      }
      if (Number(val.max) < Number(val.default) || Number(val.min) > Number(val.default)) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          message: t('fees.validation.invalid-range-default'),
          path: ['default'],
        });
      }
      if (Number(val.max) > 100) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          message: t('fees.validation.invalid-range-exceed'),
          path: ['max'],
        });
      }
      if (Number(val.min) < 0) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          message: t('fees.validation.invalid-range-min-zero'),
          path: ['min'],
        });
      }
    });
};

const ModalContent = (props: {
  refresh: () => void;
  setLoadModal: (value: boolean) => void;
  countryCode: CountryCode;
}) => {
  const { t } = useTranslation();

  const FormInputSchema = z.object({
    percentage: constructAmexFormPercentagesInputSchema(t),
  });

  type FormInputSchemaType = z.infer<typeof FormInputSchema>;

  const { control, handleSubmit } = useForm<FormInputSchemaType>({
    resolver: zodResolver(FormInputSchema),
  });

  const countryOptions = Object.values(CountryCode).map(it => ({
    value: it,
    label: (
      <div style={{ display: 'flex' }}>
        {countryToFlag[it]}
        <div style={{ paddingLeft: '8px', alignSelf: 'center' }}>{CountryName[it]}</div>
      </div>
    ),
  }));

  const onSubmit = async (data: FormInputSchemaType) => {
    const reqBody: AmexTransactionFeeRuleRequest = {
      countryCode: props.countryCode,
      percentage: {
        min: Number(data.percentage.min),
        max: Number(data.percentage.max),
        default: Number(data.percentage.default),
      },
    };

    const response = await fetchFromBackend('/amex-transaction-fee-rules', {
      method: 'POST',
      body: JSON.stringify(reqBody),
    });
    if (response.ok) {
      props.setLoadModal(false);
      props.refresh();
    } else if (response.status >= 500) alert(t('fees.new-amex-rule.unexpected-error'));
    else if (response.status >= 400) alert(t('fees.new-amex-rule.updating-error'));
  };

  return (
    <>
      <CloseButton>
        <Button
          variant={'tertiary'}
          icon={'close'}
          onClick={() => {
            props.setLoadModal(false);
          }}
        />
      </CloseButton>
      <Heading size={'m'}>{t('fees.new-amex-rule.fee-range')}</Heading>

      <Form onSubmit={handleSubmit(x => onSubmit(x))}>
        <FlexDiv>
          <FlexLeftCol>
            <Select
              label={t('fees.new-rule.market')}
              isDisabled={true}
              name={'countryCode'}
              defaultValue={countryOptions.find(e => e.value === props.countryCode)}
              options={countryOptions}
            ></Select>
          </FlexLeftCol>
        </FlexDiv>
        <FlexDiv>
          <FeesRow>
            <FormTextInput name="percentage.min" control={control} label={t('fees.new-rule.minimum')} required={true} />
            <FormTextInput name="percentage.max" control={control} label={t('fees.new-rule.maximum')} required={true} />
            <FormTextInput
              name="percentage.default"
              control={control}
              label={t('fees.new-rule.default')}
              required={true}
            />
          </FeesRow>
        </FlexDiv>
        <div style={{ float: 'right' }}>
          <Button type={'submit'} label={t('fees.new-amex-rule.save-changes')} size={'m'} />
        </div>
      </Form>
    </>
  );
};
