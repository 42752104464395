import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  OnChangeFn,
  SortingState,
  Updater,
  useReactTable,
} from '@tanstack/react-table';
import { Fragment, useEffect, useState } from 'react';
import MerchantTransactionsFilter, {
  FormInputSchema,
  SettlementStatus,
} from './merchant-transactions-filter/MerchantTransactionsFilter';
import { StyledTable, Td, Th } from './MerchantTransactionsTab.styles';
import { getSortColumnString, getTransactionColumns } from './MerchantTransactionsTabHelper';
import {
  ListTransactionSortDirection,
  ListTransactionsRequest,
  ListTransactionsResponse,
  Transaction,
} from './TransactionModel';

import Collapsable from 'components/Collapsable';
import InfiniteScroll from 'components/InfiniteScroll';
import { useTranslation } from 'react-i18next';
import { Channel } from 'routes/fees/FeesScreen';
import { fetchFromBackend } from 'utils/fetch';
import { createToast } from 'vercel-toast';
import { useMerchantIdFromUrl } from '../merchant-hooks';
import { MerchantTransactionExpanded } from './MerchantTransactionExpanded';

const DEFAULT_PAGE_SIZE = 20;

export const MerchantTransactionsTab = () => {
  const { t } = useTranslation();

  const merchantId = useMerchantIdFromUrl();

  const [isFetching, setIsFetching] = useState(false);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [totalCount, setTotalCount] = useState(0);
  const [sorting, setSorting] = useState<SortingState>([{ id: 'timestamp', desc: true }]);

  const [formData, setFormData] = useState<FormInputSchema>();

  const fetchTransactions = async () => {
    setIsFetching(true);
    const currentTransactions = currentPage === 1 ? [] : transactions;

    const reqData: ListTransactionsRequest = {
      filter: {
        search: formData?.search,
        transaction: {
          amount: {
            gte: formData?.amountFrom ? formData?.amountFrom * 100 : undefined,
            lte: formData?.amountTo ? formData?.amountTo * 100 : undefined,
          },
          timestamp: {
            gte: formData?.timestamp.start,
            lt: formData?.timestamp.end,
          },
          id: formData?.transactionId ? formData.transactionId : undefined,
          type: formData?.transactionType ? formData.transactionType.map(option => option.value) : [],
          status: formData?.transactionStatus ? formData?.transactionStatus.map(option => option.value) : [],
          cardPresent:
            formData?.channel?.length === 1 ? formData?.channel[0].value === Channel.CARD_PRESENT : undefined,
        },
        settlement: {
          paidOut:
            formData?.settlementStatus?.length === 1
              ? formData?.settlementStatus[0].value === SettlementStatus.PAID
              : undefined,
        },
      },
      sorting: sorting.map(e => {
        return {
          on: getSortColumnString(e.id),
          dir: e.desc ? ListTransactionSortDirection.DESC : ListTransactionSortDirection.ASC,
        };
      }),
      pagination: { pageNumber: currentPage, pageSize: pageSize },
    };
    const response = await fetchFromBackend(`/merchants/${merchantId}/transactions`, {
      method: 'POST',
      body: JSON.stringify(reqData),
    });
    if (!response.ok) {
      createToast(t('merchant.transactions.error'), {
        timeout: 6000,
        type: 'error',
      });
    } else {
      const responseBody: ListTransactionsResponse = await response.json();
      setTransactions(currentTransactions.concat(responseBody.transactions));
      setPageSize(responseBody.pagination.pageSize);
      setTotalCount(responseBody.pagination.totalCount);
    }
    setIsFetching(false);
  };

  useEffect(() => {
    fetchTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, formData, sorting]);

  const handleSort: OnChangeFn<SortingState> = (sort: Updater<SortingState>) => {
    setSorting(sort);
    setCurrentPage(1);
  };

  const table = useReactTable({
    data: transactions,
    columns: getTransactionColumns(t),
    state: { sorting },
    onSortingChange: handleSort,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  const hasMore = transactions.length < totalCount;

  const loadMore = (nextPage: number) => {
    if (hasMore) {
      setCurrentPage(nextPage);
    }
  };

  const onSubmit = async (data: FormInputSchema) => {
    setFormData(data);
    setCurrentPage(1);
  };

  return (
    <>
      <Collapsable title="Filter & sorting" startExpanded={true}>
        <MerchantTransactionsFilter onSubmit={onSubmit} isFetching={isFetching} />
      </Collapsable>
      <InfiniteScroll
        loading={isFetching}
        hasNextPage={hasMore}
        nextPage={currentPage + 1}
        pageSize={pageSize}
        loadMore={loadMore}
      >
        <StyledTable style={{ width: table.getCenterTotalSize() }}>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <Th key={header.id} style={{ textAlign: 'left' }}>
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                        style={{ cursor: header.column.getCanSort() ? 'pointer' : 'default' }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: '▲',
                          desc: '▼',
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    )}
                  </Th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => {
              return (
                <Fragment key={row.id}>
                  <tr key={row.id} onClick={() => row.toggleExpanded()}>
                    {row.getVisibleCells().map(cell => (
                      <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
                    ))}
                  </tr>
                  {row.getIsExpanded() && (
                    <tr>
                      <td colSpan={row.getVisibleCells().length}>
                        <MerchantTransactionExpanded row={row}></MerchantTransactionExpanded>
                      </td>
                    </tr>
                  )}
                </Fragment>
              );
            })}
          </tbody>
        </StyledTable>
      </InfiniteScroll>
    </>
  );
};
