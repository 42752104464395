import styled from 'styled-components';

export const FlexDiv = styled.div`
  width: 75%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

export const FlexLeftCol = styled.div`
  width: 50%;
  padding-right: 1.8%;
  display: flex;
  flex-direction: column;
`;

export const FlexRightCol = styled.div`
  width: 50%;
  padding-left: 1.8%;
  display: flex;
  flex-direction: column;
`;

export const StyledForm = styled.form`
  width: 100%;
`;

export const ToggleContainer = styled.div`
  width: fit-content;
  margin-bottom: 20px;
`;
