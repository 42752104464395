import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Heading, Text, Spacing } from '@loomispay/vault';
import { fetchFromBackend } from 'utils/fetch';
import { useMerchantIdFromUrl } from '../../merchant-hooks';

interface Props {
  transactionId: string;
  setDeclineRequested: Dispatch<SetStateAction<boolean>>;
  setModalOpened: Dispatch<SetStateAction<boolean>>;
}

export const DeclineRequestModalContent: React.FunctionComponent<Props> = ({
  transactionId,
  setDeclineRequested,
  setModalOpened,
}: Props) => {
  const { t } = useTranslation();
  const merchantId = useMerchantIdFromUrl();

  const onConfirm = async () => {
    const response = await fetchFromBackend(`/merchants/${merchantId}/transactions/${transactionId}/decline`, {
      method: 'POST',
      mode: 'cors',
    });
    if (response.ok) {
      setDeclineRequested(true);
    } else {
      alert('There was an unnexpected error while requesting transaction decline. Please try again later');
    }
    setModalOpened(false);
  };

  return (
    <>
      <Heading size="m" color={'warning'}>
        {t('merchant.transactions.declineRequestTitle')}
      </Heading>
      <Text>{t('merchant.transactions.declineRequestDescription')}</Text>
      <Spacing top="4" />
      <div style={{ display: 'flex' }}>
        <Button label={t('confirmation-dialog.cancel')} variant={'secondary'} onClick={() => setModalOpened(false)} />
        <Spacing right="4" />
        <Button label={t('merchant.transactions.declineRequestConfirmation')} onClick={onConfirm} />
      </div>
    </>
  );
};
