import { cloneElement, ReactElement } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';

type Props = {
  loadMore: (page: number, size: number) => void;
  children: ReactElement;
  nextPage: number;
  hasNextPage: boolean;
  pageSize: number;
  loading: boolean;
};

/**
 * Infinite Scroll Component.
 * Uses react-infinite-scroll-hook under the hood to call `loadMore` when the
 * pagination tells it has a next page to load, it's currently not loading and
 * the loading ref is visible on screen. The loading ref is placed below the
 * `children` to this component and will only be invoked once this ref is
 * visible on screen.
 *
 * @param {(page: number, size: number) => void} loadMore - The callback being
 * invoked when the InfiniteScroll will load more data.
 * @param {ReactElement} children - React child components, possibly a {Table}
 * from `@loomispay/vault`.
 * @param {number} nextPage - The next page, will be passed into `loadMore`.
 * @param {boolean} hasNextPage - Boolean to see if there's any next page to
 * load.
 * @param {number} pageSize - Size of the pages to load, will be passed into
 * `loadMore`.
 * @param {boolean} loading - Boolean to indicate wether the data is loading or
 * not.
 */
const InfiniteScroll = ({ children, loadMore, loading, nextPage, hasNextPage, pageSize }: Props) => {
  const onLoadMore = () => {
    loadMore(nextPage, pageSize);
  };

  const [loadingRef] = useInfiniteScroll({
    onLoadMore,
    loading,
    hasNextPage,
    delayInMs: 200,
  });

  return (
    <>
      {cloneElement(children, { ...children.props })}
      {hasNextPage && !loading && <div ref={loadingRef} />}
    </>
  );
};

export default InfiniteScroll;
