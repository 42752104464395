import styled from 'styled-components';

export const Form = styled.form`
  flex-direction: column;
`;

export const FlexDiv = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const FlexLeftCol = styled.div`
  width: 50%;
  padding-right: 1.8%;
  display: flex;
  flex-direction: column;
  padding-bottom: 5%;
`;

export const FlexRightCol = styled.div`
  width: 50%;
  padding-left: 1.8%;
  display: flex;
  padding-bottom: 5%;
  flex-direction: column;
`;

export const CloseButton = styled.div`
  position: relative;
  top: 0;
  float: right;
`;

export const SubHeading = styled.div`
  padding-bottom: 3%;
`;

export const FlexTextCol = styled.div`
  display: flex;
  width: 50%;
  padding-right: 3%;
  padding-bottom: 5%;
`;

export const FeesRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  margin-bottom: 1.5rem;
  > div {
    max-width: 31%;
  }
`;

export const FeesHeader = styled.p`
  font-family: 'Roobert';
  font-size: 16px;
  font-weight: 500;
`;
