import { Heading, Table } from '@loomispay/vault';
import { fetchByUrl } from 'api';
import { Badge } from 'components/Badge';
import { ContentWrapper } from 'components/ContentWrapper';
import { Merchant } from 'components/Merchant';
import StatusIndicator from 'components/StatusIndicator';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';
import { formatISODateTime } from 'utils/formatters/dateFormatters';
import { AcquirerOnboardingFailedModal } from './AcquirerOnboardingFailedModal';

interface RequireAttentionMerchantsDTO extends Record<string, unknown> {
  merchantId: string;
  status: AttentionStatus;
  reason?: string;
  updatedAt: string;
  rejectedPayments: number;
}

enum AttentionStatus {
  PAYOUTS_PAUSED = 'PAYOUTS_PAUSED',
  ACQUIRER_ONBOARDING_FAILED = 'ACQUIRER_ONBOARDING_FAILED',
}

interface MerchantStatusesResponse {
  requireAttentionMerchantDTOs?: RequireAttentionMerchantsDTO[];
  isError: boolean;
  refresh: () => void;
}

export const useRequireAttentionMerchants = () => {
  return useSWR<MerchantStatusesResponse>(['/merchants/status/require-attention'], url => fetchByUrl(url), {
    refreshInterval: 10000,
  });
};

export const MerchantStatusScreen = () => {
  const { data, error } = useRequireAttentionMerchants();
  const { t } = useTranslation();
  const tableData: RequireAttentionMerchantsDTO[] = data?.requireAttentionMerchantDTOs ?? [];

  const statusToText = {
    [AttentionStatus.PAYOUTS_PAUSED]: t('merchant.status.table.status.paused'),
    [AttentionStatus.ACQUIRER_ONBOARDING_FAILED]: t('merchant.status.table.status.stuck'),
  };

  const [selectedMerchantId, setSelectedMerchantId] = useState<string | null>(null);
  const history = useHistory();

  const columns = [
    {
      Header: t('payout-status.table.merchant'),
      accessor: 'merchantId',
      width: 70,
      Cell: (props: { value: string }) => <Merchant merchantId={props.value} clickable={false} />,
    },
    {
      Header: t('merchant.status.table.status'),
      accessor: 'status',
      width: 80,
      Cell: (props: { value: AttentionStatus }) => {
        return (
          <StatusIndicator
            variant={props.value === AttentionStatus.PAYOUTS_PAUSED ? 'neutral' : 'warning'}
            text={statusToText[props.value]}
          />
        );
      },
    },
    { Header: t('payout-status.table.reason'), accessor: 'reason' },
    {
      Header: t('payout-status.table.since'),
      width: 50,
      accessor: 'updatedAt',
      Cell: (props: { value: string }) => {
        return formatISODateTime(props.value);
      },
    },
  ];

  if (error) {
    return <div>{t('payout-status.error-unknown')}</div>;
  }

  return (
    <ContentWrapper>
      <Heading size="m" color={'primary'}>
        {t('merchant.status.need-attention')}
        <Badge
          color={tableData.length === 0 ? 'positive' : 'negative'}
          background={tableData.length === 0 ? 'positiveBg' : 'negativeBg'}
        >
          {tableData.length}
        </Badge>
      </Heading>
      {tableData.length > 0 && (
        <Table
          columns={columns}
          data={tableData}
          getRowProps={row => ({
            onClick: () => {
              if (row.values.status === AttentionStatus.ACQUIRER_ONBOARDING_FAILED) {
                setSelectedMerchantId(row.values.merchantId);
              } else if (row.values.status === AttentionStatus.PAYOUTS_PAUSED)
                history.push({
                  pathname: `/merchant/${row.values.merchantId}`,
                });
            },
          })}
        />
      )}
      <AcquirerOnboardingFailedModal
        selectedMerchantId={selectedMerchantId}
        closeModal={() => setSelectedMerchantId(null)}
      />
    </ContentWrapper>
  );
};
