import { Form } from '../../../routes/fees/helper';
import { Button, Grid, GridItem, Spacing, Text } from '@loomispay/vault';
import { SingleDatePicker } from '../../DatePicker';
import ClipLoader from 'react-spinners/ClipLoader';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Control, useForm } from 'react-hook-form';
import { LoomisLegalEntity, Store } from '../../../api/types';
import styled from 'styled-components';
import { FormTextInput } from '../../TextInput';
import {
  CreditInvoiceLine,
  CreditInvoiceRequest,
  CreditInvoiceSubLine,
  InvoiceLineType,
  InvoiceSubLineType,
} from './CreditInvoiceComponent';
import { Currency, Invoice } from '../InvoicesTable';

type RawSubLine = {
  [key: string]: {
    totalPrice: string | undefined;
  };
};

type RawLine = {
  quantity: string | undefined;
  unitPrice: string | undefined;
  extraDescription: string | undefined;
  subLines: RawSubLine;
  vatPercentage: string | undefined;
  vatAmount: string | undefined;
};

type RawLines = {
  [key: string]: { [key: string]: RawLine } | { [key: string]: { [key: string]: RawLine } };
};

type RawForm = {
  creditInvoiceDate: Date;
  legalEntityLines: RawLines;
  storeLines: RawLines;
};

export const CreditInvoiceTableForm = (props: {
  post: (req: CreditInvoiceRequest, cb: () => void) => void;
  originalInvoice: Invoice;
  stores: Store[];
  terminalModels: string[];
}) => {
  const { t } = useTranslation();
  const today = new Date();
  const { control, handleSubmit } = useForm<RawForm>({
    defaultValues: {
      creditInvoiceDate: today,
    },
  });
  const [submitting, setSubmitting] = useState(false);

  const countryCodeToLegalEntity = {
    [Currency.DKK]: LoomisLegalEntity.LOOMIS_DENMARK,
    [Currency.SEK]: LoomisLegalEntity.LOOMIS_SWEDEN,
    [Currency.EUR]: LoomisLegalEntity.LOOMIS_SPAIN,
  };

  const mapRawFormToRequest = (form: RawForm) => {
    const mapCreditInvoiceLine = (entry: [string, RawLine | { [key: string]: RawLine }]): CreditInvoiceLine => {
      return {
        type: Object.values(InvoiceLineType).find(v => v === entry[0]),
        quantity: Number(entry[1].quantity),
        unitPrice: Number(entry[1].unitPrice),
        vatPercentage: Number(entry[1].vatPercentage),
        vatAmount: Number(entry[1].vatAmount),
        currency: props.originalInvoice.currency,
        extraDescription: entry[1].extraDescription !== undefined ? entry[1].extraDescription.toString() : null,
        subLines: mapSubLines(entry[1].subLines),
      };
    };

    const mapSubLines = (subLines: RawSubLine | RawLine): CreditInvoiceSubLine[] => {
      return subLines !== undefined
        ? Object.entries(subLines)
            .filter(e => doesNotContainUndefinedValues(e[1]))
            .map(subEntry => {
              const subLine: CreditInvoiceSubLine = {
                type: Object.values(InvoiceSubLineType).find(v => v === subEntry[0]),
                totalPrice: Number(subEntry[1].totalPrice),
              };
              return subLine;
            })
        : [];
    };

    function mapCreditInvoiceLines<K>(data: RawLines, keyResolver: (entry: string) => K): Map<K, CreditInvoiceLine[]> {
      return new Map<K, CreditInvoiceLine[]>(
        Object.entries(data).map<[K, CreditInvoiceLine[]]>((entry): [K, CreditInvoiceLine[]] => {
          const lines = Object.entries(entry[1])
            .flatMap((e: [string, RawLine | { [key: string]: RawLine }]) => {
              return Object.values(e[1]).some(v => typeof v !== 'object')
                ? [e]
                : Object.entries(e[1]).map<[string, RawLine]>((entry: [string, RawLine]) => {
                    entry[1].extraDescription = e[0];
                    return entry;
                  });
            })
            .filter(e => doesNotContainUndefinedValues(e[1]))
            .map(nestedEntry => {
              return mapCreditInvoiceLine(nestedEntry);
            });
          return [keyResolver(entry[0]), lines];
        })
      );
    }

    const doesNotContainUndefinedValues = (obj: RawLine | { [key: string]: RawLine }): boolean => {
      return !Object.values(obj).some(v => v === undefined);
    };

    const mapLegalEntityLines = (data: RawLines): Map<LoomisLegalEntity | undefined, CreditInvoiceLine[]> => {
      return mapCreditInvoiceLines(data, (key: string) => {
        return Object.values(LoomisLegalEntity).find(v => v === key);
      });
    };

    const mapStoreLines = (data: RawLines): Map<string, CreditInvoiceLine[]> => {
      return mapCreditInvoiceLines(data, key => key);
    };

    const request: CreditInvoiceRequest = {
      creditInvoiceDate: form.creditInvoiceDate.toISOString().substring(0, 10),
      originalInvoiceId: props.originalInvoice.id,
      legalEntityLines: mapLegalEntityLines(form.legalEntityLines),
      storeLines: mapStoreLines(form.storeLines),
    };
    return request;
  };

  const onSubmit = (data: RawForm) => {
    const request = mapRawFormToRequest(data);
    setSubmitting(true);
    props.post(request, () => {
      setSubmitting(false);
    });
  };

  return (
    <Form onSubmit={handleSubmit(d => onSubmit(d))}>
      <Grid gutter={4}>
        <GridItem l={5} m={1} s={1} alignItems="center">
          <Text weight={'semiBold'}>{t('credit.invoices.modal.date')}</Text>
        </GridItem>
        <GridItem l={1} m={1} s={1} alignItems="center">
          <SingleDatePicker
            name={'creditInvoiceDate'}
            control={control}
            minDate={new Date(1990, 1)}
            maxDate={new Date()}
            defaultValue={today}
            required={true}
          />
        </GridItem>

        <GridItem l={12} m={8} s={8} alignItems="center">
          <Spacing top={'4'} />
        </GridItem>

        <TableHeader />

        {
          // we want to skip this section for Norway (no hardware fees, no LOOMIS_NORWAY entity)
          props.originalInvoice.currency !== Currency.NOK && (
            <LoomisCountrySection
              terminalModels={props.terminalModels}
              legalEntity={countryCodeToLegalEntity[props.originalInvoice.currency]}
              control={control}
            />
          )
        }

        <LoomisDigitalSolutionSection control={control} terminalModels={props.terminalModels} />

        {props.stores.map(store => {
          return <StoreSection store={store} terminalModels={props.terminalModels} control={control} />;
        })}
      </Grid>
      <div style={{ float: 'right' }}>
        <Spacing top="2" />
        {!submitting && <Button type={'submit'} label={'Create'} />}
        {submitting && <ClipLoader size={35} />}
        <Spacing top="4" />
      </div>
    </Form>
  );
};

const TableHeader = () => {
  const { t } = useTranslation();
  return (
    <>
      <GridItem l={1} m={1} s={1} alignItems="center" lOffset={5}>
        <Text weight={'semiBold'}>{t('credit.invoices.modal.table.header.quantity')}</Text>
      </GridItem>
      <GridItem l={2} m={1} s={1} alignItems="center">
        <Text weight={'semiBold'}>{t('credit.invoices.modal.table.header.amount')}</Text>
      </GridItem>
      <GridItem l={1} m={1} s={1} alignItems="center">
        <Text weight={'semiBold'}>{t('credit.invoices.modal.table.header.vat')}</Text>
      </GridItem>
      <GridItem l={1} m={1} s={1} alignItems="center">
        <Text weight={'semiBold'}>{t('credit.invoices.modal.table.header.vat.amount')}</Text>
      </GridItem>
      <GridItem l={2} m={1} s={1} alignItems="center">
        <Text weight={'semiBold'}>{t('credit.invoices.modal.table.header.total')}</Text>
      </GridItem>
    </>
  );
};

function getTerminalRows(
  terminalModels: string[],
  linesType: string,
  entity: string,
  lineType: string,
  control: Control<RawForm>,
  showTerminalModel = true
) {
  return terminalModels.map(tm => {
    return (
      <>
        <GridItem l={1} m={2} s={2} alignItems="center" lOffset={4}>
          <Text>{showTerminalModel ? tm : ''}</Text>
        </GridItem>
        <InputRow linesType={linesType} entity={entity} type={`${tm}.${lineType}`} control={control} />
      </>
    );
  });
}

const LoomisCountrySection = (props: {
  terminalModels: string[];
  legalEntity: LoomisLegalEntity;
  control: Control<RawForm>;
}) => {
  return (
    <>
      <GridItem l={12} m={8} s={8} alignItems="center">
        <Text weight={'semiBold'}>{props.legalEntity.replace('_', ' ')}</Text>
      </GridItem>

      <GridItem l={12} m={8} s={8} alignItems="center">
        <Spacing top={'2'} />
      </GridItem>

      <HardwareFeeLine />

      {getTerminalRows(
        props.terminalModels,
        'legalEntityLines',
        props.legalEntity,
        InvoiceLineType.TERMINAL_SUBSCRIPTION_HARDWARE,
        props.control
      )}
    </>
  );
};

const LoomisDigitalSolutionSection = (props: { terminalModels: string[]; control: Control<RawForm> }) => {
  return (
    <>
      <GridItem l={12} m={8} s={8} alignItems="center">
        <Spacing top={'4'} />
      </GridItem>

      <GridItem l={10} m={8} s={8} alignItems="center">
        <Text weight={'semiBold'}>{LoomisLegalEntity.LOOMIS_DIGITAL_SOLUTIONS.replaceAll('_', ' ')}</Text>
      </GridItem>

      <SoftwareFeeLine />

      {getTerminalRows(
        props.terminalModels,
        'legalEntityLines',
        LoomisLegalEntity.LOOMIS_DIGITAL_SOLUTIONS,
        InvoiceLineType.TERMINAL_SUBSCRIPTION_SOFTWARE,
        props.control,
        false
      )}

      <TransactionFeesRows
        linesType={'legalEntityLines'}
        entity={LoomisLegalEntity.LOOMIS_DIGITAL_SOLUTIONS}
        control={props.control}
      />
    </>
  );
};

const StoreSection = (props: { store: Store; terminalModels: string[]; control: Control<RawForm> }) => {
  return (
    <>
      <GridItem l={12} m={8} s={8} alignItems="center">
        <Spacing top={'4'} />
      </GridItem>
      <GridItem l={4} m={2} s={2} alignItems="center">
        <Text weight={'semiBold'}>{props.store.storeName}</Text>
      </GridItem>
      <GridItem l={5} m={2} s={2} alignItems="center">
        <Text weight={'semiBold'}>
          {props.store.address}, {props.store.city}
        </Text>
      </GridItem>

      <TransactionFeesRows linesType={'storeLines'} entity={props.store.storeId} control={props.control} />

      <SoftwareFeeLine />
      {getTerminalRows(
        props.terminalModels,
        'storeLines',
        props.store.storeId,
        InvoiceLineType.TERMINAL_SUBSCRIPTION_SOFTWARE,
        props.control,
        false
      )}

      <HardwareFeeLine />

      {getTerminalRows(
        props.terminalModels,
        'storeLines',
        props.store.storeId,
        InvoiceLineType.TERMINAL_SUBSCRIPTION_HARDWARE,
        props.control
      )}
    </>
  );
};

const SoftwareFeeLine = () => {
  const { t } = useTranslation();
  return (
    <GridItem l={10} m={2} s={2} alignItems="center">
      <Text>{t('credit.invoices.modal.pos.software.fee')}</Text>
    </GridItem>
  );
};

const HardwareFeeLine = () => {
  const { t } = useTranslation();
  return (
    <GridItem l={10} m={2} s={2} alignItems="center">
      <Text>{t('credit.invoices.modal.pos.hardware.fee')}</Text>
    </GridItem>
  );
};

const TransactionFeesRows = (props: { linesType: string; entity: string; control: Control<RawForm> }) => {
  const { t } = useTranslation();
  return (
    <>
      <GridItem l={5} m={2} s={2} alignItems="center">
        <Text>{t('credit.invoices.modal.in.store')}</Text>
      </GridItem>
      <InputRow
        linesType={props.linesType}
        entity={props.entity}
        type={InvoiceLineType.CARD_PRESENT_TRANSACTION}
        control={props.control}
      />

      <GridItem l={3} m={1} s={1} alignItems="center" lOffset={2}>
        <Text>{t('credit.invoices.modal.transaction.fee')} (%)</Text>
      </GridItem>

      <GridItem l={2} m={1} s={1} alignItems="center" lOffset={5}>
        <NumericInput
          linesType={props.linesType}
          entity={props.entity}
          type={InvoiceLineType.CARD_PRESENT_TRANSACTION}
          subType={InvoiceSubLineType.PERCENTAGE_FEE}
          prop={'totalPrice'}
          max={-0.01}
          control={props.control}
        />
      </GridItem>

      <GridItem l={3} m={1} s={1} alignItems="center" lOffset={2}>
        <Text>{t('credit.invoices.modal.transaction.fee.fixed')}</Text>
      </GridItem>

      <GridItem l={2} m={1} s={1} alignItems="center" lOffset={5}>
        <NumericInput
          linesType={props.linesType}
          entity={props.entity}
          type={InvoiceLineType.CARD_PRESENT_TRANSACTION}
          subType={InvoiceSubLineType.FIXED_FEE}
          prop={'totalPrice'}
          max={-0.01}
          control={props.control}
        />
      </GridItem>

      <GridItem l={5} m={2} s={2} alignItems="center">
        <Text>{t('credit.invoices.modal.online')}</Text>
      </GridItem>
      <InputRow
        linesType={props.linesType}
        entity={props.entity}
        type={InvoiceLineType.CARD_NOT_PRESENT_TRANSACTION}
        control={props.control}
      />

      <GridItem l={3} m={1} s={1} alignItems="center" lOffset={2}>
        <Text>{t('credit.invoices.modal.transaction.fee')} (%)</Text>
      </GridItem>

      <GridItem l={2} m={1} s={1} alignItems="center" lOffset={5}>
        <NumericInput
          linesType={props.linesType}
          entity={props.entity}
          type={InvoiceLineType.CARD_NOT_PRESENT_TRANSACTION}
          subType={InvoiceSubLineType.PERCENTAGE_FEE}
          prop={'totalPrice'}
          max={-0.01}
          control={props.control}
        />
      </GridItem>

      <GridItem l={3} m={8} s={8} alignItems="center" lOffset={2}>
        <Text>{t('credit.invoices.modal.transaction.fee.fixed')}</Text>
      </GridItem>

      <GridItem l={2} m={1} s={1} alignItems="center" lOffset={5}>
        <NumericInput
          linesType={props.linesType}
          entity={props.entity}
          type={InvoiceLineType.CARD_NOT_PRESENT_TRANSACTION}
          subType={InvoiceSubLineType.FIXED_FEE}
          prop={'totalPrice'}
          max={-0.01}
          control={props.control}
        />
      </GridItem>
    </>
  );
};

const StyledCell = styled.div`
  padding-bottom: 0.25rem;
  padding-top: 0.25rem;
`;

const NumericInput = (props: {
  linesType: string;
  entity: string;
  type: string;
  subType?: string;
  prop: string;
  max?: number;
  min?: number;
  control: Control<RawForm>;
}) => {
  return (
    <StyledCell>
      <FormTextInput
        type={'number'}
        max={props.max}
        min={props.min}
        name={`${props.linesType}.${props.entity}.${props.type}${
          props.subType !== undefined ? '.subLines.' + props.subType : ''
        }.${props.prop}`}
        control={props.control}
      />
    </StyledCell>
  );
};

const InputRow = (props: {
  linesType: string;
  entity: string;
  type: string;
  subType?: string;
  control: Control<RawForm>;
}) => {
  return (
    <>
      <GridItem l={1} m={1} s={1} alignItems="center">
        <NumericInput
          linesType={props.linesType}
          entity={props.entity}
          type={props.type}
          subType={props.subType}
          prop={'quantity'}
          max={-1}
          control={props.control}
        />
      </GridItem>
      <GridItem l={2} m={1} s={1} alignItems="center">
        <NumericInput
          linesType={props.linesType}
          entity={props.entity}
          type={props.type}
          subType={props.subType}
          prop={'unitPrice'}
          min={0.01}
          control={props.control}
        />
      </GridItem>
      <GridItem l={1} m={1} s={1} alignItems="center">
        <NumericInput
          linesType={props.linesType}
          entity={props.entity}
          type={props.type}
          subType={props.subType}
          prop={'vatPercentage'}
          min={0}
          control={props.control}
        />
      </GridItem>
      <GridItem l={1} m={1} s={1} alignItems="center">
        <NumericInput
          linesType={props.linesType}
          entity={props.entity}
          type={props.type}
          subType={props.subType}
          prop={'vatAmount'}
          max={0.0}
          control={props.control}
        />
      </GridItem>
    </>
  );
};
